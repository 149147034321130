import {MC_Backend} from "../../../MC_Backend";
import ThreeStepAsyncOpDialog from "../ThreeStepAsyncOpDialog/ThreeStepAsyncOpDialog";
import {MC_Organization} from "../../../iot/MC_Organization";
import {OrganizationsContext} from "../../../../App";
import PaginatedSelectItemList from "../../item-list/select-list/PaginatedSelectItemList";
import OrganizationItem from "../../item-list/OrganizationItem";
import {MC_Device} from "../../../iot/MC_Device";
import {ThreeStepAddDialogBaseProps} from "./ThreeStepAddDialogBaseProps";

export interface AddOrgToDeviceDialogProps extends ThreeStepAddDialogBaseProps<MC_Organization> {
    device: MC_Device | null;
}
function AddOrgToDeviceDialog(props: AddOrgToDeviceDialogProps): any {
    const device: MC_Device | null = props.device;
    if (device == null) {
        return null;
    }
    // Helper fns
    const getSelectionComponent = (selectFn: (o: MC_Organization) => void) => {
        return (
            <OrganizationsContext.Consumer>
                {(orgsCtx) => {
                    let loadFn = () => MC_Backend.getInstance().loadOrganizations().finally();
                    let renderItem = (idx: number, o: MC_Organization | null) => {
                        // Org can only be selected if it is not already connected
                        const orgSelectFn = (o != null && !device.organizationIDs.includes(o.id)) ? selectFn : undefined;
                        const key = (o != null) ? o.id : "null" + idx;
                        return <OrganizationItem key={key} org={o} selectAction={orgSelectFn} nameLink={false}/>
                    };
                    const sortByName = (a: MC_Organization, b: MC_Organization) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    const sortFn = (org1: MC_Organization, org2: MC_Organization) => {
                        const deviceHasOrg1: boolean = device.organizationIDs.includes(org1.id);
                        const deviceHasOrg2: boolean = device.organizationIDs.includes(org2.id);
                        // If these orgs do not have the same relationship with device, compare them
                        if (deviceHasOrg1 !== deviceHasOrg2) {
                            // Sort relevant orgs first (relevant meaning it is not already connected)
                            return (deviceHasOrg1) ? 1 : -1;
                        }
                        // Default to sort by name
                        return sortByName(org1, org2);
                    };
                    return <PaginatedSelectItemList
                        allowSearch={true}
                        pageSize={4}
                        fillPageWithNullItems={true}
                        loadDataFn={loadFn}
                        emptyMessage={"No organizations found."}
                        dataArray={orgsCtx.organizations}
                        loading={orgsCtx.loading}
                        errMsg={orgsCtx.errMsg}
                        sortFn={sortFn}
                        renderItem={renderItem}
                    />
                }}
            </OrganizationsContext.Consumer>
        );
    }
    const getConfirmDescFn = (o: MC_Organization) => {
        return "You are about to add '" + device.name + "' to organization: '" + o.name + "'.";
    }
    const addOrgFn = (o: MC_Organization): Promise<any> => {
        return MC_Backend.getInstance().addDeviceToOrg(device, o);
    }
    const getSuccessDesc = (o: MC_Organization) => {
        return "Device '" + device.name + "' added to organization '" + o.name + "'.";
    };
    // UI
    return (
        <ThreeStepAsyncOpDialog<MC_Organization>
            open={props.open} closeDialogFn={props.closeDialogFn}
            title={"Add Organization"}
            getSelectionComponent={getSelectionComponent}
            getConfirmDesc={getConfirmDescFn}
            confirmText={"Add Org"}
            performActionFn={addOrgFn}
            getSuccessDesc={getSuccessDesc}
            doneSuccessFn={() => {}}
            doneFailureFn={() => {}}
        />
    );
}

export default AddOrgToDeviceDialog;
