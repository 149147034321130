import {Paper} from "@mui/material";
import React, {PropsWithChildren} from "react";

export interface ViewAnythingInterface {
    title: string;
    desc?: string;
    descJSX?: JSX.Element;
}
function ViewAnything(props: PropsWithChildren<ViewAnythingInterface>) {
    return (
        <Paper className={"content-center"} elevation={3}>
            <span className={"card-title"}>{props.title}</span>
            {/* Optional desc */}
            {
                (props.desc != null) &&
                <p className={"content-start-note"}>{props.desc}</p>
            }
            {/* Custom desc element */}
            {
                (props.descJSX != null) && props.descJSX
            }
            {/* Content */}
            {props.children}
        </Paper>
    );
}

export default ViewAnything;