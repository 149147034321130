import {MC_Constants} from "../MC_Constants";

export class MC_Issue {

    public static FIELD_ID: string = "id"; // Generated ID
    public static FIELD_CREATED: string = "created"; // When issue was created
    public static FIELD_MODIFIED: string = "modified"; // When issue was modified
    public static FIELD_OPEN: string = "open"; // Current issue status (open/closed)
    public static FIELD_HELP_UIDS: string = "help_uids"; // User IDs working on the issue
    public static FIELD_UID: string = "uid"; // User ID who created issue
    public static FIELD_SUBJECT: string = "subject"; // Issue category
    public static FIELD_MESSAGE: string = "message"; // Customer comments about issue
    public static FIELD_DEVICE_ID: string = "device_id"; // Device involed with the issue

    constructor(
        public id: string,
        public created: Date,
        public modified: Date,
        public open: boolean,
        public help_uids: string[],
        public uid: string,
        public subject: string,
        public message: string,
        public device_id: string
    ) {}

    public static fromData(data: any): MC_Issue {
        let created = MC_Constants.parseDate(data[this.FIELD_CREATED]);
        if (created == null) {
            created = new Date(0);
        }
        let modified = MC_Constants.parseDate(data[this.FIELD_MODIFIED]);
        if (modified == null) {
            modified = created;
        }
        return new MC_Issue(
            data[MC_Issue.FIELD_ID] as string,
            created,
            modified,
            data[this.FIELD_OPEN] as boolean,
            data[this.FIELD_HELP_UIDS] as string[],
            data[this.FIELD_UID] as string,
            data[this.FIELD_SUBJECT] as string,
            data[this.FIELD_MESSAGE] as string,
            data[this.FIELD_DEVICE_ID] as string
        );
    }

    public static processIssues(issuesArray: any[]): MC_Issue[] {
        let foundIssues: MC_Issue[] = [];
        if (issuesArray != null) {
            for (let i = 0; i < issuesArray.length; i++) {
                try {
                    const issue = MC_Issue.fromData(issuesArray[i]);
                    foundIssues.push(issue);
                } catch (err) {
                    console.error("Issue process err: " + err);
                }
            }
        }
        return foundIssues;
    }

}
