import {MC_Action_Log} from "./MC_Action_Log";
import {
    AddCard,
    AddToHomeScreen,
    AdminPanelSettings,
    AttachMoney,
    BarChart,
    Business,
    CardGiftcard,
    CreditCard,
    CreditCardOff, CurrencyExchange, DeleteForever,
    Domain,
    DomainAdd,
    DomainDisabled,
    EditNotifications,
    Info,
    LocalAtm,
    MarkEmailRead,
    MobileFriendly,
    NotificationAdd,
    Notifications,
    NotificationsActive,
    NotificationsOff,
    OnDeviceTraining, Paid, PauseCircle,
    Payment,
    Person,
    PersonAdd,
    PersonRemove,
    PhonelinkErase,
    Poll, Replay,
    TabletAndroid,
    Timeline
} from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";

// Convenient types
export type SizeStr = "small" | "medium" | "large";
export type ColorStr =
    "success" // Green
    | "info" // Blue
    | "secondary" // Gray
    | "warning" // Orange
    | "error" // Red
;

// Helpful categories
export class MC_Action_Log_Categories {

    // Admin-related Platform logs
    public static TYPES_ADMIN: string[] = [
        MC_Action_Log.TYPE_ADMIN_CREATE_CLIENT_SUMMARY,
        MC_Action_Log.TYPE_ADMIN_CREATE_SENSOR_SUMMARY,
        MC_Action_Log.TYPE_ADMIN_CREATE_ALERT_SUMMARY,
        MC_Action_Log.TYPE_ADMIN_REPORT_SENT
    ];
    private static getAdminIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_ADMIN_CREATE_CLIENT_SUMMARY
            || type === MC_Action_Log.TYPE_ADMIN_CREATE_SENSOR_SUMMARY
            || type === MC_Action_Log.TYPE_ADMIN_CREATE_ALERT_SUMMARY) {
            return (<AdminPanelSettings fontSize={size} color={"secondary"}/>);
        } else if (type === MC_Action_Log.TYPE_ADMIN_REPORT_SENT) {
            return (<MarkEmailRead fontSize={size} color={"info"}/>);
        }
        return null;
    }

    // User logs
    public static TYPES_USER: string[] = [
        MC_Action_Log.TYPE_USER_CREATED_IN_ORG,
        MC_Action_Log.TYPE_USER_UPDATED,
        MC_Action_Log.TYPE_USER_DELETED,
        MC_Action_Log.TYPE_USER_ADDED_TO_ORG,
        MC_Action_Log.TYPE_USER_REMOVED_FROM_ORG
    ];
    private static getUserIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_USER_CREATED_IN_ORG) {
            return (<PersonAdd fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_USER_UPDATED) {
            return (<Person fontSize={size} color={"secondary"}/>);
        } else if (type === MC_Action_Log.TYPE_USER_DELETED) {
            return (<PersonRemove fontSize={size} color={"error"}/>);
        } else if (type === MC_Action_Log.TYPE_USER_ADDED_TO_ORG) {
            return (<PersonAdd fontSize={size} color={"info"}/>);
        } else if (type === MC_Action_Log.TYPE_USER_REMOVED_FROM_ORG) {
            return (<PersonRemove fontSize={size} color={"warning"}/>);
        }
        return null;
    }

    // Device logs
    public static TYPES_DEVICE: string[] = [
        MC_Action_Log.TYPE_DEVICE_CREATED,
        MC_Action_Log.TYPE_DEVICE_UPDATED,
        MC_Action_Log.TYPE_DEVICE_DELETED,
        MC_Action_Log.TYPE_DEVICE_ADDED_TO_ORG,
        MC_Action_Log.TYPE_DEVICE_REMOVED_FROM_ORG,
        MC_Action_Log.TYPE_DEVICE_ACTION_TRIGGERED
    ];
    private static getDeviceIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_DEVICE_CREATED) {
            return (<TabletAndroid fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_DEVICE_UPDATED) {
            return (<TabletAndroid fontSize={size} color={"secondary"}/>);
        } else if (type === MC_Action_Log.TYPE_DEVICE_DELETED) {
            return (<TabletAndroid fontSize={size} color={"error"}/>);
        } else if (type === MC_Action_Log.TYPE_DEVICE_ADDED_TO_ORG) {
            return (<AddToHomeScreen fontSize={size} color={"info"}/>);
        } else if (type === MC_Action_Log.TYPE_DEVICE_REMOVED_FROM_ORG) {
            return (<PhonelinkErase fontSize={size} color={"warning"}/>);
        } else if (type === MC_Action_Log.TYPE_DEVICE_ACTION_TRIGGERED) {
            return (<OnDeviceTraining fontSize={size} color={"info"}/>);
        }
        return null;
    }

    // Alert logs
    public static TYPES_ALERT: string[] = [
        MC_Action_Log.TYPE_ALERT_CREATED,
        MC_Action_Log.TYPE_ALERT_UPDATED,
        MC_Action_Log.TYPE_ALERT_DELETED,
        MC_Action_Log.TYPE_ALERT_TRIGGERED
    ];
    private static getAlertIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_ALERT_CREATED) {
            return (<NotificationAdd fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_ALERT_UPDATED) {
            return (<EditNotifications fontSize={size} color={"secondary"}/>);
        } else if (type === MC_Action_Log.TYPE_ALERT_DELETED) {
            return (<NotificationsOff fontSize={size} color={"error"}/>);
        } else if (type === MC_Action_Log.TYPE_ALERT_TRIGGERED) {
            return (<NotificationsActive fontSize={size} color={"info"}/>);
        }
        return null;
    }

    // Org logs
    public static TYPES_ORG: string[] = [
        MC_Action_Log.TYPE_ORG_CREATED,
        MC_Action_Log.TYPE_ORG_UPDATED,
        MC_Action_Log.TYPE_ORG_DELETED,
        MC_Action_Log.TYPE_ORG_CLAIMED_DEVICE
    ];
    private static getOrgIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_ORG_CREATED) {
            return (<DomainAdd fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_ORG_UPDATED) {
            return (<Domain fontSize={size} color={"secondary"}/>);
        } else if (type === MC_Action_Log.TYPE_ORG_DELETED) {
            return (<DomainDisabled fontSize={size} color={"error"}/>);
        } else if (type === MC_Action_Log.TYPE_ORG_CLAIMED_DEVICE) {
            return (<MobileFriendly fontSize={size} color={"success"}/>);
        }
        return null;
    }

    // Event logs
    public static TYPES_EVENT: string[] = [
        MC_Action_Log.TYPE_EVENT_CREATED,
        MC_Action_Log.TYPE_EVENT_UPDATED,
        MC_Action_Log.TYPE_EVENT_DELETED
    ];
    private static getEventIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_EVENT_CREATED) {
            return (<Poll fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_EVENT_UPDATED) {
            return (<Poll fontSize={size} color={"secondary"}/>);
        } else if (type === MC_Action_Log.TYPE_EVENT_DELETED) {
            return (<Poll fontSize={size} color={"error"}/>);
        }
        return null;
    }

    // Issue logs
    public static TYPES_ISSUES: string[] = [
        MC_Action_Log.TYPE_ISSUE_CREATED,
        MC_Action_Log.TYPE_ISSUE_UPDATED,
        MC_Action_Log.TYPE_ISSUE_ADMIN_ADDED,
        MC_Action_Log.TYPE_ISSUE_ADMIN_REMOVED,
        MC_Action_Log.TYPE_ISSUE_REOPENED,
        MC_Action_Log.TYPE_ISSUE_CLOSED,
        MC_Action_Log.TYPE_ISSUE_DELETED
    ];
    private static getIssueIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_ISSUE_CREATED) {
            return (<HelpIcon fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_ISSUE_UPDATED) {
            return (<HelpIcon fontSize={size} color={"secondary"}/>);
        } else if (type === MC_Action_Log.TYPE_ISSUE_ADMIN_ADDED) {
            return (<HelpIcon fontSize={size} color={"info"}/>);
        } else if (type === MC_Action_Log.TYPE_ISSUE_ADMIN_REMOVED) {
            return (<HelpIcon fontSize={size} color={"warning"}/>);
        } else if (type === MC_Action_Log.TYPE_ISSUE_REOPENED) {
            return (<HelpIcon fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_ISSUE_CLOSED) {
            return (<HelpIcon fontSize={size} color={"error"}/>);
        } else if (type === MC_Action_Log.TYPE_ISSUE_DELETED) {
            return (<HelpIcon fontSize={size} color={"error"}/>);
        }
        return null;
    }

    // JAS Fee logs
    public static TYPES_JAS_FEES: string[] = [
        MC_Action_Log.TYPE_JAS_FEE_COLLECTED,
        MC_Action_Log.TYPE_JAS_FEE_REFUNDED,
        MC_Action_Log.TYPE_JAS_FEE_REFUND_UPDATED
    ];
    private static getJASFeeIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_JAS_FEE_COLLECTED) {
            return (<LocalAtm fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_JAS_FEE_REFUNDED) {
            return (<LocalAtm fontSize={size} color={"error"}/>);
        } else if (type === MC_Action_Log.TYPE_JAS_FEE_REFUND_UPDATED) {
            return (<LocalAtm fontSize={size} color={"secondary"}/>);
        }
        return null;
    }

    // Prepaid credit logs
    public static TYPES_PREPAID_CREDIT: string[] = [
        MC_Action_Log.TYPE_DEVICE_PREPAID_YEAR_CLAIMED
    ];
    private static getPrepaidCreditIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_DEVICE_PREPAID_YEAR_CLAIMED) {
            return (<CardGiftcard fontSize={size} color={"success"}/>);
        }
        return null;
    }

    // Payment method logs
    public static TYPES_PAYMENT_METHOD: string[] = [
        MC_Action_Log.TYPE_PAYMENT_METHOD_CREATED,
        MC_Action_Log.TYPE_PAYMENT_METHOD_UPDATED,
        MC_Action_Log.TYPE_PAYMENT_METHOD_REMOVED
    ];
    private static getPaymentMethodIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_PAYMENT_METHOD_CREATED) {
            return (<AddCard fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_PAYMENT_METHOD_UPDATED) {
            return (<CreditCard fontSize={size} color={"secondary"}/>);
        } else if (type === MC_Action_Log.TYPE_PAYMENT_METHOD_REMOVED) {
            return (<CreditCardOff fontSize={size} color={"error"}/>);
        }
        return null;
    }

    // Subscription logs
    public static TYPES_SUBSCRIPTION: string[] = [
        MC_Action_Log.TYPE_SUB_CREATED,
        MC_Action_Log.TYPE_SUB_UPDATED,
        MC_Action_Log.TYPE_SUB_PAUSED,
        MC_Action_Log.TYPE_SUB_RESUMED,
        MC_Action_Log.TYPE_SUB_PAYMENT_FAILURE,
        MC_Action_Log.TYPE_SUB_PAYMENT_SUCCESS,
        MC_Action_Log.TYPE_SUB_CANCELED,
        MC_Action_Log.TYPE_SUB_DELETED
    ];
    private static getSubscriptionIcon(type: string, size: SizeStr): any | null {
        if (type === MC_Action_Log.TYPE_SUB_CREATED) {
            return (<CurrencyExchange fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_SUB_UPDATED) {
            return (<CurrencyExchange fontSize={size} color={"secondary"}/>);
        } else if (type === MC_Action_Log.TYPE_SUB_PAUSED) {
            return (<PauseCircle fontSize={size} color={"warning"}/>);
        } else if (type === MC_Action_Log.TYPE_SUB_RESUMED) {
            return (<Replay fontSize={size} color={"info"}/>);
        } else if (type === MC_Action_Log.TYPE_SUB_PAYMENT_FAILURE) {
            return (<Paid fontSize={size} color={"error"}/>);
        } else if (type === MC_Action_Log.TYPE_SUB_PAYMENT_SUCCESS) {
            return (<Paid fontSize={size} color={"success"}/>);
        } else if (type === MC_Action_Log.TYPE_SUB_CANCELED) {
            return (<CurrencyExchange fontSize={size} color={"error"}/>);
        } else if (type === MC_Action_Log.TYPE_SUB_DELETED) {
            return (<CurrencyExchange fontSize={size} color={"error"}/>);
        }
        return null;
    }

    // Get a colored & sized icon for this action log
    public static getIcon(type: string, size: SizeStr): any {
        let foundIcon: any | null = null;
        if (MC_Action_Log_Categories.TYPES_ADMIN.includes(type)) {
            foundIcon = this.getAdminIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_USER.includes(type)) {
            foundIcon = this.getUserIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_DEVICE.includes(type)) {
            foundIcon = this.getDeviceIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_ALERT.includes(type)) {
            foundIcon = this.getAlertIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_ORG.includes(type)) {
            foundIcon = this.getOrgIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_EVENT.includes(type)) {
            foundIcon = this.getEventIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_ISSUES.includes(type)) {
            foundIcon = this.getIssueIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_JAS_FEES.includes(type)) {
            foundIcon = this.getJASFeeIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_PREPAID_CREDIT.includes(type)) {
            foundIcon = this.getPrepaidCreditIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_PAYMENT_METHOD.includes(type)) {
            foundIcon = this.getPaymentMethodIcon(type, size);
        } else if (MC_Action_Log_Categories.TYPES_SUBSCRIPTION.includes(type)) {
            foundIcon = this.getSubscriptionIcon(type, size);
        }

        // Default
        if (foundIcon == null) {
            return (<Info fontSize={size} color={"secondary"}/>);
        }
        // Return icon
        return foundIcon;
    }

    public static getBillingLogsFilter(): (x: MC_Action_Log) => boolean {
        return (x: MC_Action_Log) => {
            const allBillingTypes: string[] = [
                ...this.TYPES_JAS_FEES,
                ...this.TYPES_PREPAID_CREDIT,
                ...this.TYPES_PAYMENT_METHOD,
                ...this.TYPES_SUBSCRIPTION
            ];
            return allBillingTypes.includes(x.type);
        };
    }

}