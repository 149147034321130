import ConfirmAsyncOpDialog from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialog";
import {ConfirmAsyncOpDialogBaseProps} from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialogBaseProps";
import {MC_User} from "../../../iot/MC_User";
import {MC_Backend} from "../../../MC_Backend";

export interface DeleteUserDialogProps extends ConfirmAsyncOpDialogBaseProps<void> {
    user: MC_User | null;
}
function DeleteUserDialog(props: DeleteUserDialogProps) {
    // Data
    const userName: string = (props.user != null) ? props.user.name : "N/A";
    const desc: string = "You are about to permanently delete '" + userName + "'.";
    const successMsg: string = "This user has been deleted.";
    // Helper fn
    const performAction = () => {
        if (props.user == null) {
            throw "Cannot delete null user.";
        }
        return MC_Backend.getInstance().deleteUser(props.user);
    };
    // UI
    return <ConfirmAsyncOpDialog
        open={props.open} closeDialogFn={props.closeDialogFn}
        title={"Delete User?"} desc={desc}
        confirmText={"Delete"} confirmTextRed={true}
        successMsg={successMsg}
        performAction={performAction}
        doneSuccessFn={props.doneSuccessFn}
        doneFailureFn={props.doneFailureFn}
    />;
}

export default DeleteUserDialog;