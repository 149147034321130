import "./Users.css";
import UsersTable from "../../common/ui/table/UsersTable";
import TableControl, {TableControlSelectOption} from "../../common/ui/table/TableControl";
import React, {useContext} from "react";
import {MC_User} from "../../common/iot/MC_User";
import {UsersContext} from "../../App";
import {MC_Backend} from "../../common/MC_Backend";
import GeneralHeader from "../../common/ui/GeneralHeader";
import MCLoadingBar from "../../common/ui/misc/MCLoadingBar";
import {useLocation, useNavigate} from "react-router-dom";
import useLocalStorageState from "../../common/ui/UseLocalStorageState";

const STORAGE_USERS_SORT_FIELD: string = "users_sort_field";
const STORAGE_USERS_SEARCH_KEY: string = "users_search_key";

function Users(props: any) {
    const ctx = useContext(UsersContext);
    const location = useLocation();
    const nav = useNavigate();
    // State vars
    const [sortField, updateSortField] = useLocalStorageState(STORAGE_USERS_SORT_FIELD, "last_activity");
    const [searchKey, updateSearchKey] = useLocalStorageState(STORAGE_USERS_SEARCH_KEY, "");

    // Table control
    const tableSortOptions: TableControlSelectOption[] = [
        {value: "last_activity", name: "Last Seen"},
        {value: "name", name: "Name"},
        {value: "email", name: "Email"},
        {value: "uid", name: "UID"}
    ];

    // Validate search key
    React.useEffect(() => {
        if (searchKey.length > 0) {
            if (searchKey.replace(/\s/g, '').length === 0) {
                // search key is all white space
                updateSearchKey(""); // Clear it
            }
        }
    }, [searchKey, updateSearchKey]);

    // Validate search key
    React.useEffect(() => {
        if (searchKey.length > 0) {
            if (searchKey.replace(/\s/g, '').length === 0) {
                // search key is all white space
                updateSearchKey(""); // Clear it
            }
        }
    }, [searchKey, updateSearchKey]);

    // Refresh users fn
    let refreshFn = () => {
        MC_Backend.getInstance().loadUsers().finally();
    };

    // Load the users if they have not been loaded yet
    React.useEffect(
        () => {
            if (ctx.users == null && !ctx.loading && ctx.errMsg == null) {
                refreshFn();
            }
        },
        [ctx.users, ctx.loading, ctx.errMsg, location, nav]
    );

    // Filter/sort the users whenever necessary
    React.useEffect(
        () => {
            const backend = MC_Backend.getInstance();
            backend.updateUsersContext({...backend.usersCtx}); // Trigger consumer fn
        },
        [searchKey, sortField]
    );

    const tableCtl = <TableControl
        searchKey={searchKey} updateSearchKey={updateSearchKey} searchHint={"Search by Name or Email..."}
        sortOptions={tableSortOptions} sortField={sortField} updateSortField={updateSortField}
    />;

    // UI
    return (
        <UsersContext.Consumer>
            {(usersCtx) => {
                const curUsers = parseCurrentUsers(
                    (usersCtx.users != null) ? usersCtx.users : [],
                    sortField,
                    searchKey
                );
                return (
                    <div className={"users-root-div"}>

                        {/* Title */}
                        <GeneralHeader title={"Users"} refreshAction={(!usersCtx.loading) ? refreshFn : () => {}}/>

                        {/* Content */}
                        <div>

                            {/* Loading div */}
                            <MCLoadingBar loadingMessage={"Loading Users..."}
                                          loading={usersCtx.loading} errorMessage={usersCtx.errMsg}
                            />

                            {/* Description */}
                            <p className={"small-note"}>
                                Here are all the user accounts created within your monitoring platform.
                            </p>

                            {
                                (usersCtx.users != null)
                                &&
                                <div>
                                    {/* Table */}
                                    <UsersTable users={curUsers} tableCtl={tableCtl}/>
                                </div>
                            }

                            <br/><br/>
                        </div>
                    </div>
                );
            }}
        </UsersContext.Consumer>
    );
}

// Filter & sort users
function parseCurrentUsers(allUsers: MC_User[], sortField: string, searchKey: string): MC_User[] {

    // 1) Filter the users
    let curUsers: MC_User[] = allUsers;
    if (searchKey.length > 0) {
        const filterUserName = (a: MC_User): boolean => a.name.toLowerCase().includes(searchKey.toLowerCase());
        const filterUserEmail = (a: MC_User): boolean => a.email.toLowerCase().includes(searchKey.toLowerCase());
        curUsers = curUsers.filter((u) => (filterUserName(u) || filterUserEmail(u)));
    }

    // 2) Sort the users
    let sortFn: (a: MC_User, b: MC_User) => number;
    if (sortField === "uid") {
        sortFn = (a: MC_User, b: MC_User): number => a.uid.localeCompare(b.uid);
    } else if (sortField === "name") {
        sortFn = (a: MC_User, b: MC_User): number => a.name.localeCompare(b.name);
    } else if (sortField === "email") {
        sortFn = (a: MC_User, b: MC_User): number => a.email.localeCompare(b.email);
    } else {
        // sortField === "last_activity"
        sortFn = (a: MC_User, b: MC_User): number => {
            if (a.lastActivity != null && b.lastActivity != null) {
                return b.lastActivity.getTime() - a.lastActivity.getTime();
            } else if (a.lastActivity != null) {
                return -1;
            }  else if (b.lastActivity != null) {
                return 1;
            }
            return 0;
        };
    }
    curUsers.sort(sortFn);

    // Done
    return curUsers;
}

export default Users;