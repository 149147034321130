import "./ConfirmAsyncOpDialog.css";
import React from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

export interface ConfirmAsyncOpDialogProps<DataType> {
    // General
    open: boolean; // Whether the dialog is open or not
    closeDialogFn: () => void; // So dialog can close itself
    // Data
    title: string;
    desc: string;
    confirmText: string;
    confirmTextRed: boolean; // If the text should be red (primary color otherwise)
    successMsg: string;
    performAction: () => Promise<DataType>;
    // After completion
    doneSuccessFn: (data: DataType) => void;
    doneFailureFn: () => void;
}
interface ConfirmAsyncOpDialogState<DataType> {
    step: number;
    asyncOp: Promise<DataType> | null;
    // Once asyncOp has started
    result: DataType | null;
    done: boolean;
    success: boolean;
    failureMsg: string | null;
}
function ConfirmAsyncOpDialog<DataType>(props: ConfirmAsyncOpDialogProps<DataType>) {
    // Step index constants
    const STEP_CONFIRM: number = 0;
    const STEP_WAIT: number = 1;
    // State
    const [state, setState] = React.useState<ConfirmAsyncOpDialogState<DataType>>({
        step: STEP_CONFIRM, asyncOp: null, result: null,
        done: false, success: false, failureMsg: null
    });

    // Helper fns
    const exitFn = () => {
        // Close dialog
        props.closeDialogFn();
        // Reset state (after a small delay)
        window.setTimeout(() => {
            setState({
                step: STEP_CONFIRM, asyncOp: null, result: null,
                done: false, success: false, failureMsg: null
            });
        }, 1000);
    };
    const confirmFn = () => {
        // Perform the operation (update state on completion)
        const asyncOperation = props.performAction();
        setState({
            step: STEP_WAIT, asyncOp: asyncOperation, result: null,
            done: false, success: false, failureMsg: null
        });
        // Handle completion
        asyncOperation.then((data: DataType) => {
            setState({
                step: STEP_WAIT, asyncOp: state.asyncOp, result: data,
                done: true, success: true, failureMsg: null
            });
        }).catch((err) => {
            setState({
                step: STEP_WAIT, asyncOp: state.asyncOp, result: null,
                done: true, success: false, failureMsg: err as string
            });
        });
    }
    const allDoneFn = (success: boolean) => {
        // Close dialog
        exitFn();
        // Perform final action
        if (success) {
            props.doneSuccessFn(state.result!);
        } else {
            props.doneFailureFn();
        }
    };
    return (
        <Dialog open={props.open} className={"caod-root"}>

            {/* Header (title & close) */}
            <DialogTitle>
                <div className={"header-row"}>
                    <span className={"header-side-div"}/>
                    <span className={"title"}>{props.title}</span>
                    <IconButton className={"header-side-div"} onClick={exitFn}>
                        <Close fontSize={"medium"}/>
                    </IconButton>
                </div>
            </DialogTitle>

            {/* Content */}
            <DialogContent className={"caod-content-div"}>
                {/* Confirm step */}
                {
                    (state.step === STEP_CONFIRM) &&
                    <p>{props.desc}</p>
                }
                {/* Wait async step */}
                {
                    (state.step === STEP_WAIT)
                    &&
                    <React.Fragment>
                        {/* Loading icon */}
                        {
                            (!state.done) &&
                            <CircularProgress color="primary"/>
                        }
                        {/* Completion message */}
                        {
                            (state.done) &&
                            <p>{(state.success) ? props.successMsg : state.failureMsg}</p>
                        }
                    </React.Fragment>
                }
            </DialogContent>

            {/* Actions */}
            <DialogActions className={"confirm-page-actions"}>
                {/* Confirm step buttons */}
                {
                    (state.step === STEP_CONFIRM)
                    &&
                    <React.Fragment>
                        <Button className={"action-btn"} color={"secondary"} onClick={exitFn}>
                            <strong>Cancel</strong>
                        </Button>
                        <Button className={"action-btn"} color={(props.confirmTextRed) ? "warning" : "primary"} onClick={confirmFn}>
                            <strong>{props.confirmText}</strong>
                        </Button>
                    </React.Fragment>
                }
                {/* Waiting step buttons */}
                {
                    (state.step === STEP_WAIT && state.done) &&
                    <Button className={"action-btn"} onClick={() => allDoneFn(state.success)}>
                        <strong>Okay</strong>
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmAsyncOpDialog;