import ConfirmAsyncOpDialog from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialog";
import {MC_Device} from "../../../iot/MC_Device";
import {ConfirmAsyncOpDialogBaseProps} from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialogBaseProps";
import {MC_Backend} from "../../../MC_Backend";

export interface DeleteDeviceDialogProps extends ConfirmAsyncOpDialogBaseProps<void> {
    device: MC_Device | null;
}
function DeleteDeviceDialog(props: DeleteDeviceDialogProps) {
    // Data
    const deviceName: string = (props.device != null) ? props.device.name : "N/A";
    const desc: string = "You are about to permanently delete '" + deviceName + "'.";
    const successMsg: string = "This device has been deleted.";
    // Helper fn
    const performAction = () => {
        if (props.device == null) {
            throw "Cannot delete null device.";
        }
        return MC_Backend.getInstance().deleteDevice(props.device);
    };
    // UI
    return <ConfirmAsyncOpDialog
        open={props.open} closeDialogFn={props.closeDialogFn}
        title={"Delete Device?"} desc={desc}
        confirmText={"Delete"} confirmTextRed={true}
        successMsg={successMsg}
        performAction={performAction}
        doneSuccessFn={props.doneSuccessFn}
        doneFailureFn={props.doneFailureFn}
    />;
}

export default DeleteDeviceDialog;