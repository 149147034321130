import {MC_Backend} from "../../../MC_Backend";
import ThreeStepAsyncOpDialog from "../ThreeStepAsyncOpDialog/ThreeStepAsyncOpDialog";
import {MC_User} from "../../../iot/MC_User";
import {MC_Organization} from "../../../iot/MC_Organization";
import {UsersContext} from "../../../../App";
import PaginatedSelectItemList from "../../item-list/select-list/PaginatedSelectItemList";
import UserItem from "../../item-list/UserItem";
import {ThreeStepAddDialogBaseProps} from "./ThreeStepAddDialogBaseProps";

export interface AddUserToOrgDialogProps extends ThreeStepAddDialogBaseProps<MC_User> {
    org: MC_Organization | null;
}
function AddUserToOrgDialog(props: AddUserToOrgDialogProps): any {
    const org: MC_Organization | null = props.org;
    if (org == null) {
        return null; // If this org is deleted, this dialog should not show
    }
    // Helper fns
    const getSelectionComponent = (selectFn: (user: MC_User) => void) => {
        return (
            <UsersContext.Consumer>
                {(usersCtx) => {
                    let loadFn = () => MC_Backend.getInstance().loadUsers().finally();
                    let renderItem = (idx: number, u: MC_User | null) => {
                        // User can only be selected if not already in org
                        const userSelectFn = (u != null && !org.userIDs.includes(u.uid)) ? selectFn : undefined;
                        const key = (u != null) ? u.uid : "null" + idx;
                        return <UserItem key={key} user={u} selectAction={userSelectFn} nameLink={false} showPhone={true}/>;
                    };
                    const sortByName = (a: MC_User, b: MC_User) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    const sortFn = (u1: MC_User, u2: MC_User) => {
                        const orgHasUser1: boolean = org.userIDs.includes(u1.uid);
                        const orgHasUser2: boolean = org.userIDs.includes(u2.uid);
                        // If these users do not have the same relationship with org, compare them
                        if (orgHasUser1 !== orgHasUser2) {
                            // Sort relevant users first (relevant meaning it is not already connected)
                            return (orgHasUser1) ? 1 : -1;
                        }
                        // Default to sort by name
                        return sortByName(u1, u2);
                    }
                    return <PaginatedSelectItemList
                        allowSearch={true}
                        pageSize={4}
                        fillPageWithNullItems={true}
                        loadDataFn={loadFn}
                        emptyMessage={"No users found."}
                        dataArray={usersCtx.users}
                        loading={usersCtx.loading}
                        errMsg={usersCtx.errMsg}
                        sortFn={sortFn}
                        renderItem={renderItem}
                    />
                }}
            </UsersContext.Consumer>
        );
    }
    const getConfirmDescFn = (user: MC_User) => {
      return "You are about to add '" + user.name + "' to organization: '" + org.name + "'.";
    }
    const addUserFn = (user: MC_User): Promise<any> => {
        return MC_Backend.getInstance().addUserToOrg(user, org);
    }
    const getSuccessDesc = (user: MC_User) => {
        return "User '" + user.name + "' added to organization '" + org.name + "'";
    }
    // UI
    return (
        <ThreeStepAsyncOpDialog<MC_User>
            open={props.open} closeDialogFn={props.closeDialogFn}
            title={"Add User"}
            getSelectionComponent={getSelectionComponent}
            getConfirmDesc={getConfirmDescFn}
            confirmText={"Add User"}
            performActionFn={addUserFn}
            getSuccessDesc={getSuccessDesc}
            doneSuccessFn={() => {}}
            doneFailureFn={() => {}}
        />
    );
}

export default AddUserToOrgDialog;