import "./IssueTile.css";
import React from "react";
import {MC_Issue} from "../../iot/MC_Issue";
import HelpIcon from '@mui/icons-material/Help';
import {Paper} from "@mui/material";
import {MC_Constants} from "../../MC_Constants";
import {MC_User} from "../../iot/MC_User";
import {useNavigate} from "react-router-dom";

interface IssueTilePropsInterface {
    issue: MC_Issue;
    getUserFn: (uid: string) => MC_User | null;
}
export function IssueTile({issue, getUserFn}: IssueTilePropsInterface) {
    const nav = useNavigate();

    // Get icon & status
    const iconSize: "large" = "large";
    let statusStr: string;
    let iconColorStr: string;
    if (issue.open && issue.help_uids.length === 0) {
        // Needs assistance
        statusStr = "SUPPORT REQUIRED";
        iconColorStr = "red";
    } else if (issue.open) {
        // In progress
        statusStr = "In Progress";
        iconColorStr = "orange";
    } else {
        // Resolved
        statusStr = "Resolved";
        iconColorStr = "green";
    }

    // Other info
    let issueUserName: string = issue.uid;
    const issueUser: MC_User | null = getUserFn(issue.uid);
    if (issueUser != null) {
        issueUserName = issueUser.name;
    }
    let helpStr: string = "";
    if (issue.help_uids.length > 0) {
        for (let i = 0; i < issue.help_uids.length; i++) {
            const helpuid = issue.help_uids[i]
            let name: string = helpuid;
            const usr: MC_User | null = getUserFn(helpuid);
            if (usr != null) {
                name = usr.name;
            }
            helpStr += (i < (issue.help_uids.length - 1)) ? name + ", " : name;
        }
    }

    // Helper fn
    const viewThisIssue = () => nav("/core/support/" + issue.id);

    // UI
    return (
        <Paper elevation={3} className={"issue-tile-paper"} onClick={viewThisIssue}>
            <div className={"issue-tile-root"}>

                {/* Icon & status col */}
                <div className={"icon-col"}>
                    <HelpIcon sx={{color: iconColorStr}} fontSize={iconSize} />
                    <span className={"status"}>{statusStr}</span>
                </div>

                {/* Content col */}
                <div className={"content-col"}>
                    <div className={"content-header"}>
                        <span className={"subj-txt"}><b>{issue.subject}</b></span>
                        <span className={"help-txt"}>
                            {helpStr.length === 0 && "No support member assigned"}
                            {helpStr.length > 0 &&
                                <React.Fragment>
                                    Assigned to <b>{helpStr}</b>
                                </React.Fragment>
                            }
                        </span>
                    </div>
                    <div className={"content-message"}>
                        <span className={"msg-txt"}>{issue.message}</span>
                    </div>
                    <div className={"content-footer"}>
                        <span className={"footer-txt"}>{issueUserName}</span>
                        <span className={"footer-txt"}>{MC_Constants.getReadableDateStr(issue.created)}</span>
                    </div>
                </div>

            </div>
        </Paper>
    );
}

export default IssueTile;