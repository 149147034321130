import "./Devices.css"
import TableControl, {TableControlSelectOption} from "../../common/ui/table/TableControl";
import DevicesTable from "../../common/ui/table/DevicesTable";
import React, {useContext} from "react";
import {MC_Device} from "../../common/iot/MC_Device";
import {DevicesContext} from "../../App";
import {MC_Backend} from "../../common/MC_Backend";
import GeneralHeader from "../../common/ui/GeneralHeader";
import MCLoadingBar from "../../common/ui/misc/MCLoadingBar";
import CreateDeviceDialog from "../../common/ui/dialog/CreateDialog/CreateDeviceDialog";
import useLocalStorageState from "../../common/ui/UseLocalStorageState";

const STORAGE_DEVICES_SORT_FIELD: string = "devices_sort_field";
const STORAGE_DEVICES_SEARCH_KEY: string = "devices_search_key";
const STORAGE_DEVICES_SOLD_FILTER: string = "devices_sold_filter";

function Devices(props: any) {
    const ctx = useContext(DevicesContext);
    // State vars
    const [createDeviceOpen, setCreateDeviceOpen] = React.useState<boolean>(false);
    const [sortField, updateSortField] = useLocalStorageState(STORAGE_DEVICES_SORT_FIELD, "last_post");
    const [searchKey, updateSearchKey] = useLocalStorageState(STORAGE_DEVICES_SEARCH_KEY, "");
    const [soldFilter, updateSoldFilter] = useLocalStorageState(STORAGE_DEVICES_SOLD_FILTER, "");

    // Refresh devices fn
    const refreshFn = () => {
        MC_Backend.getInstance().loadDevices().finally(); // Update UI via devices context consumer fn
    };

    // Validate search key
    React.useEffect(() => {
        if (searchKey.length > 0) {
            if (searchKey.replace(/\s/g, '').length === 0) {
                // search key is all white space
                updateSearchKey(""); // Clear it
            }
        }
    }, [searchKey, updateSearchKey]);

    // Load devices if needed
    React.useEffect(() => {
        if (ctx.devices == null && !ctx.loading && ctx.errMsg == null) {
            refreshFn();
        }
    }, [ctx.devices, ctx.loading, ctx.errMsg]);

    // Filter/sort the devices whenever necessary
    React.useEffect(
        () => {
            const backend = MC_Backend.getInstance();
            backend.setDevicesContextState({...backend.devicesCtx}); // Trigger devices ctx consumer fn
        },
        [searchKey, sortField]
    );

    // UI
    return (
        <DevicesContext.Consumer>
            {(devicesCtx) => {
                // Prep
                const curDevices: MC_Device[] = parseCurrentDevices(
                    (devicesCtx.devices != null) ? devicesCtx.devices : [],
                    sortField,
                    searchKey,
                    soldFilter
                );
                const tableSortOptions: TableControlSelectOption[] = [
                    {value: "last_post", name: "Last Seen"},
                    {value: "name", name: "Name"},
                    {value: "type", name: "Type"},
                    {value: "jas_id", name: "Serial #"}
                ];
                const tableCtl = (
                    <TableControl
                        searchKey={searchKey} updateSearchKey={updateSearchKey} searchHint={"Search by Name or Serial #..."}
                        sortOptions={tableSortOptions} sortField={sortField} updateSortField={updateSortField}
                        useSoldFilter={true} soldFilter={soldFilter} updateSoldFilter={updateSoldFilter}
                    />
                );
                // UI
                return (
                    <div className={"devices-root"}>

                        {/* Create device dialog */}
                        <CreateDeviceDialog open={createDeviceOpen} closeDialogFn={() => setCreateDeviceOpen(false)} />

                        {/* Title */}
                        <GeneralHeader title={"Devices"} createAction={() => setCreateDeviceOpen(true)}
                                       refreshAction={(!devicesCtx.loading) ? refreshFn : () => {}}
                        />

                        {/* Loading div */}
                        <MCLoadingBar loadingMessage={"Loading Devices..."}
                                      loading={devicesCtx.loading} errorMessage={devicesCtx.errMsg}
                        />

                        {/* Description */}
                        <p className={"small-note"}>
                            Here are all the devices integrated with your monitoring platform.
                        </p>

                        {/* Content */}
                        <div>
                            {(devicesCtx.devices != null && devicesCtx.refreshTS != null) &&
                                <DevicesTable devices={curDevices} tableCtl={tableCtl} refreshTS={devicesCtx.refreshTS}/>
                            }
                        </div>

                        <br/><br/>

                    </div>
                );
            }}
        </DevicesContext.Consumer>
    );
}

// Filter & sort users
function parseCurrentDevices(
    allDevices: MC_Device[],
    sortField: string,
    searchKey: string,
    soldFilter: string
): MC_Device[] {

    // 1) Optionally filter the devices
    let curDevices: MC_Device[] = allDevices;
    if (searchKey.length > 0) {
        // Filter by name or ID
        const filterDeviceName = (a: MC_Device): boolean => a.name.toLowerCase().includes(searchKey.toLowerCase());
        //const filterDeviceID = (a: MC_Device): boolean => a.id.toLowerCase().includes(searchKey.toLowerCase());
        const filterDeviceJASID = (a: MC_Device): boolean => a.jas_id.toLowerCase().includes(searchKey.toLowerCase());
        curDevices = curDevices.filter((a: MC_Device) => (filterDeviceName(a) || filterDeviceJASID(a)));
    }
    if (soldFilter === "promo") {
        curDevices = curDevices.filter((x: MC_Device) => x.demo);
    } else if (soldFilter === "sold") {
        curDevices = curDevices.filter((x: MC_Device) => x.sold);
    } else if (soldFilter === "unsold") {
        curDevices = curDevices.filter((x: MC_Device) => !x.sold);
    }

    // Sort fns
    const sortDeviceLastPost = (a: MC_Device, b: MC_Device): number =>{
        if (a.last_post != null && b.last_post != null) {
            return b.last_post.getTime() - a.last_post.getTime();
        } else if (a.last_post != null) {
            return -1;
        }  else if (b.last_post != null) {
            return 1;
        }
        return 0;
    };
    const sortDeviceName = (a: MC_Device, b: MC_Device): number => a.name.localeCompare(b.name);
    const sortDeviceType = (a: MC_Device, b: MC_Device): number => a.fullType.localeCompare(b.fullType);
    const sortDeviceID = (a: MC_Device, b: MC_Device): number => a.id.localeCompare(b.id);
    const sortDeviceJASID = (a: MC_Device, b: MC_Device): number => a.jas_id.localeCompare(b.jas_id);

    // 2) Sort
    let sortFn: (a: MC_Device, b: MC_Device) => number;
    if (sortField === "name") {
        sortFn = sortDeviceName;
    } else if (sortField === "type") {
        sortFn = sortDeviceType;
    } else if (sortField === "id") {
        sortFn = sortDeviceID;
    } else if (sortField === "jas_id") {
        sortFn = sortDeviceJASID;
    } else {
        // sortField === "last_post"
        sortFn = sortDeviceLastPost;
    }
    curDevices.sort(sortFn);

    // Done
    return curDevices;
}

export default Devices;
