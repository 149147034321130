import {MC_Backend} from "../../../MC_Backend";
import ThreeStepAsyncOpDialog from "../ThreeStepAsyncOpDialog/ThreeStepAsyncOpDialog";
import {MC_Organization} from "../../../iot/MC_Organization";
import {DevicesContext} from "../../../../App";
import PaginatedSelectItemList from "../../item-list/select-list/PaginatedSelectItemList";
import {MC_Device} from "../../../iot/MC_Device";
import DeviceItem from "../../item-list/DeviceItem";
import {ThreeStepAddDialogBaseProps} from "./ThreeStepAddDialogBaseProps";

export interface AddDeviceToOrgDialogProps extends ThreeStepAddDialogBaseProps<MC_Device> {
    org: MC_Organization | null;
}
function AddDeviceToOrgDialog(props: AddDeviceToOrgDialogProps): any {
    const org: MC_Organization | null = props.org;
    if (org == null) {
        return null; // If org is deleted, this dialog should not show
    }
    // Helper fns
    const getSelectionComponent = (selectFn: (device: MC_Device) => void) => {
        return (
            <DevicesContext.Consumer>
                {(devicesCtx) => {
                    let loadFn = () => MC_Backend.getInstance().loadDevices().finally();
                    let renderItem = (idx: number, d: MC_Device | null) => {
                        // The device cannot be selected if the org already owns it
                        const deviceSelectFn = (d != null && !org.deviceIDs.includes(d.id))
                            ? selectFn
                            : undefined
                        ;
                        const key = (d != null) ? d.id : "null" + idx;
                        return <DeviceItem key={key} device={d} selectAction={deviceSelectFn} nameLink={false}/>;
                    };
                    const sortByName = (a: MC_Device, b: MC_Device) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    const sortFn = (d1: MC_Device, d2: MC_Device) => {
                        const orgHasD1: boolean = org.deviceIDs.includes(d1.id);
                        const orgHasD2: boolean = org.deviceIDs.includes(d2.id);
                        // If these devices do not have the same relationship with org, compare them
                        if (orgHasD1 !== orgHasD2) {
                            // Sort relevant devices first (relevant meaning it is not already connected)
                            return (orgHasD1) ? 1 : -1;
                        }
                        // Default to sort by name
                        return sortByName(d1, d2);
                    }
                    return <PaginatedSelectItemList
                        allowSearch={true}
                        pageSize={4}
                        fillPageWithNullItems={true}
                        loadDataFn={loadFn}
                        emptyMessage={"No devices found."}
                        sortFn={sortFn}
                        dataArray={devicesCtx.devices}
                        loading={devicesCtx.loading}
                        errMsg={devicesCtx.errMsg}
                        renderItem={renderItem}
                        isDevices={true}
                    />
                }}
            </DevicesContext.Consumer>
        );
    }
    const getConfirmDescFn = (d: MC_Device) => {
        return "You are about to add '" + d.name + "' to organization: '" + org.name + "'.";
    }
    const addDeviceFn = (d: MC_Device): Promise<any> => MC_Backend.getInstance().addDeviceToOrg(d, org);
    const getSuccessDesc = (d: MC_Device) => {
        return "Device '" + d.name + "' has been added to this organization '" + org.name + "'." ;
    };
    // UI
    return (
        <ThreeStepAsyncOpDialog<MC_Device>
            open={props.open} closeDialogFn={props.closeDialogFn}
            title={"Add Device"}
            getSelectionComponent={getSelectionComponent}
            getConfirmDesc={getConfirmDescFn}
            confirmText={"Add Device"}
            performActionFn={addDeviceFn}
            getSuccessDesc={getSuccessDesc}
            doneSuccessFn={() => {}}
            doneFailureFn={() => {}}
        />
    );
}

export default AddDeviceToOrgDialog;