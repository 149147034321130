import React from "react";

// Custom local storage based hook
function useLocalStorageState(key: string, initialVal: string): [string, React.Dispatch<React.SetStateAction<string>>] {
    // Get stored item or default value
    const [state, setState] = React.useState<string>(
        localStorage.getItem(key) || initialVal
    );
    // Update each time it changes
    React.useEffect(() => {
        localStorage.setItem(key, state);
    }, [key, state]);

    // Done
    return [state, setState];
}

export default useLocalStorageState;