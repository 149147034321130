import "./Support.css";
import React, {useContext} from "react";
import {IssuesContext, UsersContext} from "../../App";
import {MC_Backend} from "../../common/MC_Backend";
import MCLoadingBar from "../../common/ui/misc/MCLoadingBar";
import GeneralHeader from "../../common/ui/GeneralHeader";
import useLocalStorageState from "../../common/ui/UseLocalStorageState";
import TableControl, {TableControlSelectOption} from "../../common/ui/table/TableControl";
import {MC_Issue} from "../../common/iot/MC_Issue";
import {IssuesTable} from "../../common/ui/custom-table/IssuesTable";
import {MC_User} from "../../common/iot/MC_User";

const STORAGE_ISSUES_SORT_FIELD: string = "issues_sort_field";
const STORAGE_ISSUES_SEARCH_KEY: string = "issues_search_key";

export function Support(props: any) {
    const ctx = useContext(IssuesContext);
    const usersCtx = useContext(UsersContext);
    // State vars
    const [updateFlag, setUpdateFlag] = React.useState<boolean>(false);
    const [loadedUsers, setLoadedUsers] = React.useState<MC_User[] | null>(usersCtx.users);
    const [sortField, updateSortField] = useLocalStorageState(STORAGE_ISSUES_SORT_FIELD, "open");
    const [searchKey, updateSearchKey] = useLocalStorageState(STORAGE_ISSUES_SEARCH_KEY, "");

    // Validate search key
    React.useEffect(() => {
        if (searchKey.length > 0) {
            if (searchKey.replace(/\s/g, '').length === 0) {
                // search key is all white space
                updateSearchKey(""); // Clear it
            }
        }
    }, [searchKey, updateSearchKey]);

    // Load issues only if needed
    const refreshFn = () => {
        MC_Backend.getInstance().loadIssues().finally(); // The result will be updated via context provider
    };
    React.useEffect(() => {
        if (ctx.issues == null && !ctx.loading && ctx.errMsg == null) {
            refreshFn();
        }
    }, [ctx.issues, ctx.loading, ctx.errMsg]);

    // Filter/sort the alerts whenever necessary
    React.useEffect(
        () => {
            const backend = MC_Backend.getInstance();
            backend.setIssuesContextState({...backend.issuesCtx}); // Trigger issues ctx consumer fn
        },
        [searchKey, sortField]
    );

    // Load users (if needed)
    React.useEffect(() => {
        // Duplicate vars to avoid unnecessary trigger var dependencies
        const mcbackend = MC_Backend.getInstance();
        const ctx = mcbackend.usersCtx;
        // Check if users are null
        if (ctx.users == null) {
            if (ctx.loading) {
                // Just wait it out
            } else if (ctx.errMsg != null) {
                // Err loading users, just give up for now
                setLoadedUsers([]);
            } else {
                // This will be handled in a useEffect() fn below
                mcbackend.loadUsers().finally(() => {
                    // Trigger UI update
                    setUpdateFlag(!updateFlag);
                });
            }
        }
    }, [updateFlag, loadedUsers]);
    // Check for loaded users every second (if needed)
    React.useEffect(() => {
        const intervalID = setInterval(() => {
            //console.log("loadedusers null? " + (loadedUsers == null));
            const curUsers = MC_Backend.getInstance().usersCtx.users;
            if (loadedUsers == null && curUsers != null) {
                setLoadedUsers(curUsers);
            }
        }, 1000);
        // Return stop interval fn
        return () => clearInterval(intervalID);
    }, []); // Don't retrigger at all, since setInterval will call it.

    // UI
    return (
        <IssuesContext.Consumer>
            {(curCtx) => {
                // Prep
                const curIssues: MC_Issue[] = parseCurrentIssues(
                    (curCtx.issues != null) ? curCtx.issues : [],
                    sortField,
                    searchKey
                );
                const tableSortOptions: TableControlSelectOption[] = [
                    {value: "open", name: "Unresolved"},
                    {value: "created", name: "Timestamp"},
                ];
                const tableCtl = <TableControl
                    searchKey={searchKey} updateSearchKey={updateSearchKey} searchHint={"Search by Subject or Message..."}
                    sortOptions={tableSortOptions} sortField={sortField} updateSortField={updateSortField}
                />;
                // UI
                return (
                    <div className={"support-root"}>

                        {/* Control header */}
                        <GeneralHeader title={"Customer Support"} refreshAction={(!curCtx.loading) ? refreshFn : () => {}}/>

                        {/* Loading bar */}
                        <MCLoadingBar loading={curCtx.loading || loadedUsers == null} errorMessage={curCtx.errMsg}
                                      loadingMessage={"Loading Support Issues..."}
                        />

                        {/* Description */}
                        <p className={"small-note"}>
                            This is a table of all customer support issues created recently within the platform.
                        </p>

                        {/* Content */}
                        <div>
                            {(curCtx.issues != null && loadedUsers != null) &&
                                <IssuesTable issues={curIssues} tableCtl={tableCtl} users={loadedUsers}/>
                            }
                        </div>

                        <br/><br/>

                    </div>
                );
            }}
        </IssuesContext.Consumer>
    );
}

function parseCurrentIssues(
    allIssues: MC_Issue[],
    sortField: string,
    searchKey: string
): MC_Issue[] {

    // 1) Filter the issues
    let issues: MC_Issue[] = allIssues;
    if (searchKey.length > 0) {
        const lowKey: string = searchKey.toLowerCase();
        const filterBySubj = (a: MC_Issue): boolean => a.subject.toLowerCase().includes(lowKey);
        const filterByMessage = (a: MC_Issue): boolean =>  a.message.toLowerCase().includes(lowKey);
        issues = issues.filter((x) => (filterBySubj(x) || filterByMessage(x)));
    }

    // 2) Sort the issues
    let sortFn: (a: MC_Issue, b: MC_Issue) => number;
    const sortCreated: (a: MC_Issue, b: MC_Issue) => number = (a, b) => {
      return b.created.getTime() - a.created.getTime();
    };
    if (sortField === "open") {
        sortFn = (a, b) => {
            if (a.open && b.open) {
                return a.help_uids.length - b.help_uids.length;
            } else if (a.open && !b.open) {
                return -1;
            } else if (!a.open && b.open) {
                return 1;
            }
            return 0;
        };
    } else {
        // Default to sort by creation time
        sortFn = sortCreated;
    }
    issues.sort(sortFn);

    // Done
    return issues;
}


export default Support;