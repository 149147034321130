import React from "react";
import {useLocation} from "react-router-dom";

function ScrollToTop(props: any) {
    const {pathname} = useLocation();
    // This effect runs everytime the path changes
    React.useEffect(() => {
        // Scroll to top
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}

export default ScrollToTop;