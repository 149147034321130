import "./UserContactProfile.css";
import {UsersContext} from "../../../App";
import MCLoadingBar from "./MCLoadingBar";
import {MC_User} from "../../iot/MC_User";
import React from "react";
import UserItem from "../item-list/UserItem";
import {MC_Backend} from "../../MC_Backend";

export interface UserContactProfileInterface {
    uid: string;
}
export function UserContactProfile({uid}: UserContactProfileInterface) {
    return (
        <UsersContext.Consumer>
            {(curCtx) => {
                // Parse user
                const user: MC_User | null = MC_Backend.getInstance().findUserByID(uid);
                // UI
                return (
                    <div className={"ucp-root"}>
                        {/* Loading */}
                        {curCtx.loading &&
                            <MCLoadingBar loading={curCtx.loading} loadingMessage={"Loading..."} errorMessage={curCtx.errMsg}/>
                        }
                        {/* Profile */}
                        {(user != null) &&
                            <UserItem nameLink={true} showPhone={true} user={user}/>
                        }
                        {/* Possible error */}
                        {(user == null && curCtx.users != null) &&
                            <p className={"error-note"}>User not found: {uid}</p>
                        }
                    </div>
                );
            }}
        </UsersContext.Consumer>
    );
}

export default UserContactProfile;