import "./ViewOrganization.css";
import {Box, Tab} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {MC_Organization} from "../../../common/iot/MC_Organization";
import {MC_Backend} from "../../../common/MC_Backend";
import React, {useContext} from "react";
import GeneralHeader from "../../../common/ui/GeneralHeader";
import MCLoadingBar from "../../../common/ui/misc/MCLoadingBar";
import ViewDevices from "../../../common/ui/view-section/ViewDevices";
import ViewUsers from "../../../common/ui/view-section/ViewUsers";
import ViewActivityLog from "../../../common/ui/view-section/ViewActivityLog";
import AddUserToOrgDialog from "../../../common/ui/dialog/AddDialog/AddUserToOrgDialog";
import {MC_User} from "../../../common/iot/MC_User";
import {MC_Device} from "../../../common/iot/MC_Device";
import AddDeviceToOrgDialog from "../../../common/ui/dialog/AddDialog/AddDeviceToOrgDialog";
import {MC_Action_Log} from "../../../common/iot/MC_Action_Log";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import RemoveDeviceFromOrgDialog from "../../../common/ui/dialog/RemoveDialog/RemoveDeviceFromOrgDialog";
import RemoveUserFromOrgDialog from "../../../common/ui/dialog/RemoveDialog/RemoveUserFromOrgDialog";
import DeleteOrgDialog from "../../../common/ui/dialog/DeleteDialog/DeleteOrgDialog";
import {OrganizationsContext} from "../../../App";
import OrganizationInfoTab from "./OrganizationInfoTab";
import OrganizationSubscriptionsTab from "./OrganizationSubscriptionsTab";

interface ViewOrganizationState {
    refreshing: boolean;
    errMsg: string | null;
}
interface RemoveDeviceState {
    device: MC_Device | null;
    open: boolean;
}
interface RemoveUserState {
    user: MC_User | null;
    open: boolean;
}
function ViewOrganization(props: any) {
    const TAB_INFO: string = "info";
    const TAB_SUBS: string = "SUBSCRIPTIONS";
    const TAB_DEVICES: string = "devices";
    const TAB_USERS: string = "users";
    const TAB_ACTIVITY_LOG: string = "activity_log";
    const nav = useNavigate();
    const ctx = useContext(OrganizationsContext);

    // State
    const [curState, setCurState] = React.useState<ViewOrganizationState>({refreshing: false, errMsg: null});
    const [deleteOrgOpen, setDeleteOrgOpen] = React.useState<boolean>(false);
    const [addDeviceOpen, setAddDeviceOpen] = React.useState<boolean>(false);
    const [addUserOpen, setAddUserOpen] = React.useState<boolean>(false);
    const [removeDeviceState, setRemoveDeviceState] = React.useState<RemoveDeviceState>({device: null, open: false});
    const [removeUserState, setRemoveUserState] = React.useState<RemoveUserState>({user: null, open: false});
    const [tabValue, setTabValue] = React.useState<string>(TAB_INFO);

    // Load orgs if they are not loading already
    React.useEffect(() => {
        if (ctx.organizations == null && !ctx.loading && ctx.errMsg == null) {
            MC_Backend.getInstance().loadOrganizations().finally();
        }
    }, [ctx.organizations, ctx.loading, ctx.errMsg]);

    // Parse Org ID
    const {orgID} = useParams();
    const orgIDStr: string | null = (orgID != null) ? orgID as string : null;
    if (orgIDStr == null) {
        return (<p className={"bad"}>Missing Organization ID.</p>);
    }

    // Helper fns
    const refreshOrgFn = () => {
        if (!curState.refreshing) {
            setCurState({refreshing: true, errMsg: null});
            MC_Backend.getInstance().loadOrganization(orgIDStr)
                .then(() => setCurState({refreshing: false, errMsg: null}))
                .catch((e) => setCurState({refreshing: false, errMsg: e as string}))
            ;
            // Also logs if needed
            if (tabValue === TAB_ACTIVITY_LOG) {
                MC_Backend.getInstance().loadOverview();
            }
        }
    };
    const goBackFn = () => nav("/core/organizations");
    const addDeviceFn = () => setAddDeviceOpen(true);
    const addUserFn = () => setAddUserOpen(true);
    const removeDeviceFn = (d: MC_Device) => setRemoveDeviceState({device: d, open: true});
    const removeUserFn = (u: MC_User) => setRemoveUserState({user: u, open: true});

    // UI
    return (
        <OrganizationsContext.Consumer>
            {
                (orgsCtx) => {
                    // Parse org by ID
                    const expectingNonNullOrg = (orgsCtx.organizations != null);
                    const org: MC_Organization | null = MC_Backend.getInstance().findOrgByID(orgIDStr);
                    // Helper vars
                    const isLoading = curState.refreshing || orgsCtx.loading;
                    const loadingError: string | null = (curState.errMsg != null) ? curState.errMsg : orgsCtx.errMsg;
                    // UI
                    //const viewLink: string = CustomConstants.getOrgViewLink(org.id);
                    return (
                        <div className={"view-org-root"}>

                            {/* Dialogs */}
                            <React.Fragment>
                                {/* Delete org dialog */}
                                <DeleteOrgDialog
                                    open={deleteOrgOpen}
                                    org={org}
                                    closeDialogFn={() => setDeleteOrgOpen(false)}
                                    doneSuccessFn={goBackFn}
                                    doneFailureFn={() => {}}
                                />
                                {/* Add device to organization dialog */}
                                <AddDeviceToOrgDialog
                                    open={addDeviceOpen}
                                    org={org}
                                    closeDialogFn={() => setAddDeviceOpen(false)}
                                    doneSuccessFn={refreshOrgFn}
                                    doneFailureFn={() => {}}
                                />
                                {/* Add user to organization dialog */}
                                <AddUserToOrgDialog
                                    open={addUserOpen}
                                    org={org}
                                    closeDialogFn={() => setAddUserOpen(false)}
                                    doneSuccessFn={refreshOrgFn}
                                    doneFailureFn={() => {}}
                                />
                                {/* Remove device from org dialog */}
                                <RemoveDeviceFromOrgDialog
                                    open={removeDeviceState.open}
                                    org={org} device={removeDeviceState.device}
                                    closeDialogFn={() => setRemoveDeviceState({device: removeDeviceState.device, open: false})}
                                    doneSuccessFn={refreshOrgFn}
                                    doneFailureFn={() => {}}
                                />
                                {/* Remove user from org dialog */}
                                <RemoveUserFromOrgDialog
                                    open={removeUserState.open}
                                    org={org} user={removeUserState.user}
                                    closeDialogFn={() => setRemoveUserState({user: removeUserState.user, open: false})}
                                    doneSuccessFn={refreshOrgFn}
                                    doneFailureFn={() => {}}
                                />
                            </React.Fragment>

                            {/* Header */}
                            <GeneralHeader title={(org != null) ? org.name : "..."}
                                           icon={"org"}
                                           backAction={goBackFn}
                                           refreshAction={(org != null) ? refreshOrgFn : () => {}}
                            />

                            {/* Refresh indicator */}
                            <MCLoadingBar loadingMessage={"Refreshing..."} loading={isLoading}  errorMessage={loadingError}/>

                            {/* Show error if there is no org, but there should be */}
                            {
                                (expectingNonNullOrg && org == null) &&
                                <p className={"error-note"}>Could not find organization with ID: {orgIDStr}</p>
                            }

                            {/* Show profile if user is loaded */}
                            {
                                (org != null) &&
                                <React.Fragment>

                                    {/* Content */}
                                    <div className={"content-div"}>

                                        {/* Tabbed content */}
                                        <TabContext value={tabValue}>

                                            {/* Tab header */}
                                            <Box className={"tablistbox"}>
                                                <TabList
                                                    variant={"scrollable"} allowScrollButtonsMobile={true}
                                                    value={tabValue} textColor={"primary"}
                                                    onChange={(e, v: string) => setTabValue(v)}>
                                                    <Tab value={TAB_INFO} label="Info" />
                                                    <Tab value={TAB_SUBS} label="Subscriptions" />
                                                    <Tab value={TAB_DEVICES} label="Devices" />
                                                    <Tab value={TAB_USERS} label="Users" />
                                                    <Tab value={TAB_ACTIVITY_LOG} label="Activity Log" />
                                                </TabList>
                                            </Box>

                                            {/* Info tab */}
                                            <TabPanel value={TAB_INFO} tabIndex={0}>
                                                <OrganizationInfoTab org={org} setDeleteOrgOpen={setDeleteOrgOpen}/>
                                            </TabPanel>

                                            {/* License tab */}
                                            <TabPanel value={TAB_SUBS} tabIndex={1}>
                                                <OrganizationSubscriptionsTab org={org}/>
                                            </TabPanel>

                                            {/* Devices tab */}
                                            <TabPanel value={TAB_DEVICES} tabIndex={2}>
                                                {/* Devices paper */}
                                                <ViewDevices deviceIDs={org.deviceIDs} desc={"Here are the devices used by this organization."}
                                                             addAction={addDeviceFn}
                                                             removeAction={removeDeviceFn}
                                                />
                                            </TabPanel>

                                            {/* Users tab */}
                                            <TabPanel value={TAB_USERS} tabIndex={3}>
                                                {/* Users paper */}
                                                <ViewUsers userIDs={org.userIDs} desc={"Here are the users with access to this organization."}
                                                           addAction={addUserFn}
                                                           removeAction={removeUserFn}
                                                />
                                            </TabPanel>

                                            {/* Activity log tab */}
                                            <TabPanel value={TAB_ACTIVITY_LOG} tabIndex={4}>
                                                <ViewActivityLog
                                                    pageSize={10}
                                                    title={"Activity Log"}
                                                    desc={"Here is recent activity relating to this organization."}
                                                    logFilterFn={(x: MC_Action_Log) => x.organization_ids.includes(org.id)}
                                                />
                                            </TabPanel>

                                        </TabContext>

                                    </div>

                                    <br/><br/><br/>

                                </React.Fragment>
                            }

                        </div>
                    );
                }
            }
        </OrganizationsContext.Consumer>
    );
}

export default ViewOrganization;