import "./OrganizationInfoTab.css";
import {Alert, Button, Paper, Snackbar, TextareaAutosize} from "@mui/material";
import DetailRow from "../../../common/ui/DetailRow";
import {CustomConstants} from "../../../custom/CustomConstants";
import React from "react";
import {MC_Organization} from "../../../common/iot/MC_Organization";
import {MC_Backend} from "../../../common/MC_Backend";
import TextInputRow from "../../../common/ui/misc/TextInputRow";
import {MC_Constants} from "../../../common/MC_Constants";

export interface OrganizationInfoTabProps {
    org: MC_Organization;
    setDeleteOrgOpen: (x: boolean) => void;
}
function OrganizationInfoTab(props: OrganizationInfoTabProps) {
    // Data
    const org: MC_Organization = props.org;
    // State
    const [copySnackOpen, setCopySnackOpen] = React.useState<boolean>(false);
    const [progressSnackOpen, setProgressSnackOpen] = React.useState<boolean>(false);
    const [errorSnackOpen, setErrorSnackOpen] = React.useState<boolean>(false);
    const [successSnackOpen, setSuccessSnackOpen] = React.useState<boolean>(false);
    const [tempName, setTempName] = React.useState<string>(org.name);
    const [tempAdminNotes, setTempAdminNotes] = React.useState<string>(org.adminNotes);
    const [opInProgress, setOpInProgress] = React.useState<boolean>(false);
    // Helper
    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const viewMonitoringOrgFn = () => {
        const targetURL: string = CustomConstants.MONITORING_SOFTWARE_URL + "/log_in?org_id=" + org.id;
        openInNewTab(targetURL);
    };
    const copyInviteLink = () => {
        const inviteLink = CustomConstants.MONITORING_SOFTWARE_URL + "/create_account?org_id=" + org.id + "&org_key=" + org.key;
        navigator.clipboard.writeText(inviteLink).finally();
        setCopySnackOpen(true);
    };
    const updateFn = () => {
        if (!opInProgress) {
            if (tempName === "") {
                setErrorSnackOpen(true);
                return;
            }
            setOpInProgress(true);
            setProgressSnackOpen(true);
            MC_Backend.getInstance().updateOrganization(org.id, tempName, tempAdminNotes)
                .then(() => {
                    setSuccessSnackOpen(true);
                })
                .catch((e) => {
                    console.error("Update err: " + JSON.stringify(e));
                    setErrorSnackOpen(true);
                })
                .finally(() => {
                    setOpInProgress(false);
                })
            ;
        }
    };
    // UI
    return (
        <div>

            {/* Snack bars */}
            <Snackbar
                open={progressSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setProgressSnackOpen(false)}>
                <Alert severity="info" sx={{ width: '100%' }}>
                    Updating Organization...
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setErrorSnackOpen(false)}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Error Updating Organization.
                </Alert>
            </Snackbar>
            <Snackbar
                open={successSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setSuccessSnackOpen(false)}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Updated Organization.
                </Alert>
            </Snackbar>

            {/* Info */}
            <Paper className={"content-start"} elevation={3}>
                <TextInputRow label={"Name"} value={tempName} updateValue={(newValue) => setTempName(newValue)} />
                <br/>
                <DetailRow label={"Name"} value={org.name}/>
                <DetailRow label={"ID"} value={org.id}/>
                <DetailRow label={"Key"} value={org.key}/>
                <DetailRow label={"Created"} value={MC_Constants.getReadableDateStr(org.created)}/>
                <DetailRow label={"Modified"} value={MC_Constants.getReadableDateStr(org.modified)}/>
                <DetailRow label={"Admin Notes"} value={""}/>
                <TextareaAutosize
                    className={"admin-notes-area"}
                    minRows={4} maxRows={8} maxLength={140}
                    disabled={false}
                    placeholder={"Notes about this organization are private to management, and are NOT visible to users."}
                    value={tempAdminNotes}
                    onChange={(ev) => setTempAdminNotes(ev.target.value)}
                />
                <div className={"center-row"}>
                    <Button variant="text" color={"primary"} disabled={opInProgress} onClick={updateFn}>Update</Button>
                </div>
            </Paper>

            <br/>

            {/* Actions */}
            <div>

                {/* View the org */}
                <div>
                    <p className={"note btn-note"}>This will open a new tab for this organization in the monitoring software.</p>
                    <Button variant="contained" color={"primary"} onClick={() => viewMonitoringOrgFn()}>View Organization</Button>
                </div>
                <Snackbar
                    open={copySnackOpen}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={() => setCopySnackOpen(false)}
                    key={"copy-snackbar"}>
                    <Alert severity="info" sx={{ width: '100%' }}>
                        Invite Link Copied!
                    </Alert>
                </Snackbar>

                {/* Copy invite link */}
                <div>
                    <p className={"note btn-note"}>This will copy the invite link for this organization.</p>
                    <Button variant="contained" color={"secondary"} onClick={() => copyInviteLink()}>Copy Invite Link</Button>
                </div>

                {/* Delete the org */}
                <div>
                    <p className={"note btn-note"}>This will permanently delete the organization, and remove it from all users & devices.</p>
                    <Button variant="contained" color={"warning"} onClick={() => props.setDeleteOrgOpen(true)}><strong>Delete Organization</strong></Button>
                </div>

            </div>
        </div>
    );
}

export default OrganizationInfoTab;