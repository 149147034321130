import {MC_Constants} from "../MC_Constants";
import {MC_Device_Manual_License} from "./MC_Device_Manual_License";
import {SimpleSubscription} from "./simple_stripe_types";

export class MC_Device {

    public static FIELD_ID: string = "id";
    public static FIELD_JAS_ID: string = "jas_id";
    public static FIELD_CLAIM_CODE: string = "claim_code";
    public static FIELD_DEMO: string = "demo";
    public static FIELD_SOLD: string = "sold";
    public static FIELD_USE_MANUAL_LICENSE: string = "use_manual_license";
    public static FIELD_MANUAL_LICENSE: string = "manual_license";
    public static FIELD_ANNUAL_PRICE: string = "annual_price";
    public static FIELD_PRICE_ID: string = "price_id";
    public static FIELD_SIMPLE_SUBSCRIPTION: string = "simple_subscription";
    public static FIELD_CREATED: string = "created";
    public static FIELD_MODIFIED: string = "modified";
    public static FIELD_TYPE: string = "type";
    public static FIELD_FULL_TYPE: string = "full_type";
    public static FIELD_NAME: string = "name";
    public static FIELD_NOTES: string = "notes";
    public static FIELD_SETTINGS: string = "settings";
    public static FIELD_INTERVAL: string = "interval";
    public static FIELD_LAST_POST: string = "last_post";
    public static FIELD_ORGANIZATION_IDS: string = "organization_ids";
    public static FIELD_ADMIN_SETTINGS: string = "admin_settings";
    public static FIELD_ADMIN_NOTES: string = "admin_notes";
    public static FIELD_RECENT: string = "recent";

    public id: string;
    public jas_id: string;
    public readableJASID: string; // Calculated
    public claim_code: string;
    public demo: boolean;
    public sold: boolean;
    public useManualLicense: boolean;
    public manualLicense: MC_Device_Manual_License | null;
    public annualPrice: number;
    public priceID: string | null;
    public simple_sub: SimpleSubscription | null;
    public created: Date | null;
    public modified: Date | null;
    public type: string;
    public fullType: string;
    public name: string;
    public notes: string;
    public settings: any;
    public interval: number;
    public last_post: Date | null;
    public organizationIDs: string[];
    public adminSettings: any;
    public adminNotes: string;
    public recent: any;

    public constructor(data: any) {
        let createdDate: Date | null = MC_Constants.parseDate(data[MC_Device.FIELD_CREATED] as string);
        let modifiedDate: Date | null = MC_Constants.parseDate(data[MC_Device.FIELD_MODIFIED] as string);
        let lastPostDate: Date | null = MC_Constants.parseDate(data[MC_Device.FIELD_LAST_POST] as string);
        this.id = data[MC_Device.FIELD_ID] as string;
        this.jas_id = data[MC_Device.FIELD_JAS_ID] as string
        this.readableJASID = MC_Constants.parseReadableJASID(this.jas_id, this.id);
        this.claim_code = (data[MC_Device.FIELD_CLAIM_CODE])
            ? data[MC_Device.FIELD_CLAIM_CODE] as string
            : "N/A"
        ;
        this.demo = data[MC_Device.FIELD_DEMO] as boolean;
        this.sold = data[MC_Device.FIELD_SOLD] as boolean;
        this.useManualLicense = MC_Constants.parseNullableField<boolean>(
            data, MC_Device.FIELD_USE_MANUAL_LICENSE, false
        );
        const manualLicenseAny: any | null = data[MC_Device.FIELD_MANUAL_LICENSE];
        this.manualLicense = (manualLicenseAny != null)
            ? new MC_Device_Manual_License(manualLicenseAny)
            : null
        ;
        this.annualPrice = MC_Constants.parseNullableField<number>(
            data, MC_Device.FIELD_ANNUAL_PRICE, 0
        );
        this.priceID = MC_Constants.parseNullableField<string | null>(
            data,
            MC_Device.FIELD_PRICE_ID,
            null
        );
        this.simple_sub = (data[MC_Device.FIELD_SIMPLE_SUBSCRIPTION] != null)
            ? SimpleSubscription.fromData(data[MC_Device.FIELD_SIMPLE_SUBSCRIPTION])
            : null
        ;
        this.created = createdDate;
        this.modified = modifiedDate;
        this.type = data[MC_Device.FIELD_TYPE] as string;
        this.fullType = data[MC_Device.FIELD_FULL_TYPE] as string;
        this.name = data[MC_Device.FIELD_NAME] as string;
        this.notes = data[MC_Device.FIELD_NOTES] as string;
        this.settings = MC_Constants.parseNullableField<any>(
            data, MC_Device.FIELD_SETTINGS, {}
        );
        this.interval = data[MC_Device.FIELD_INTERVAL] as number;
        this.last_post = lastPostDate;
        this.organizationIDs = data[MC_Device.FIELD_ORGANIZATION_IDS] as string[];
        this.adminSettings = data[MC_Device.FIELD_ADMIN_SETTINGS];
        this.adminNotes = data[MC_Device.FIELD_ADMIN_NOTES] as string;
        this.recent = data[MC_Device.FIELD_RECENT];
    }

    public getLicenseStatus(): string {
        if (this.demo) {
            return "DEMO";
        }
        if (!this.sold) {
            return "UNSOLD";
        }
        if (this.simple_sub != null) {
            return this.simple_sub.status;
        }
        return "None";
    }

    public isOnline(fromRefreshTS: Date): boolean {
        if (this.last_post != null) {
            const refreshTS: number = fromRefreshTS.getTime();
            const lastPostTS: number = this.last_post.getTime();
            // Online ff this device was online after refresh (refreshed individually)
            if (lastPostTS >= refreshTS) {
                return true;
            }
            // Must be online within 2 intervals from refresh time
            const minPassed = (refreshTS - lastPostTS) / 60000;
            if (minPassed <= (2 * this.interval)) {
                return true;
            }
        }
        return false;
    }

    // Optional custom settings tab
    public getSettingsUI(): JSX.Element | null {
        return null;
    }

}
