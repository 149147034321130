import "./Settings.css";
import {CustomConstants} from "../../custom/CustomConstants";
import React, {useContext} from "react";
import {OverviewContext} from "../../App";
import {MC_Backend} from "../../common/MC_Backend";
import GeneralHeader from "../../common/ui/GeneralHeader";
import MCLoadingBar from "../../common/ui/misc/MCLoadingBar";
import ViewAnything from "../../common/ui/view-section/ViewAnything";
import {TextField} from "@mui/material";
import {MC_User} from "../../common/iot/MC_User";
import {MC_Constants} from "../../common/MC_Constants";

export function Settings() {
    // Initial context
    const ctx = useContext(OverviewContext);
    const managerDesc: string = "These people will receive a weekly monitoring platform report.";
    const serviceDesc: string = "These teams will receive notifications for new support requests.";

    // Helper
    const refreshFn = () => {
        MC_Backend.getInstance().loadOverview().finally(() => {
            // The result will be updated via context provider
        });
    };

    // Load overview if needed
    React.useEffect(() => {
        if (ctx.overview == null && !ctx.loading && ctx.errMsg == null) {
            refreshFn();
        }
    }, [ctx.overview, ctx.loading, ctx.errMsg]);

    // UI
    return (
        <OverviewContext.Consumer>
            {
                (curCtx) => {
                    const profDescWithLink: JSX.Element = <p className={"note"}>
                        You can edit your profile in the&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href={CustomConstants.MONITORING_SOFTWARE_URL}>
                            <b>monitoring software</b>
                        </a>
                        .
                    </p>;
                    let managerEmails: string[] = [];
                    let serviceEmails: string[] = [];
                    let lastEmailReportTS: Date | null = null;
                    if (curCtx.overview != null) {
                        const overview = curCtx.overview;
                        managerEmails = overview.mcSettings.managerEmails;
                        serviceEmails = overview.mcSettings.serviceEmails;
                        lastEmailReportTS = curCtx.overview.lastEmailReport.timestamp;
                    }
                    const profile: MC_User = MC_Backend.getInstance().selfProfile!;
                    return (
                        <div className={"settings-root"}>

                            {/* Title */}
                            <GeneralHeader title={"Settings"} refreshAction={(!curCtx.loading) ? refreshFn : () => {}}/>

                            {/* Loading */}
                            <MCLoadingBar loadingMessage={""} loading={curCtx.loading}  errorMessage={curCtx.errMsg}/>

                            {/* Version */}
                            <p className={"small-note"}>Admin Portal Version: {CustomConstants.ADMIN_SOFTWARE_VERSION}</p>

                            {/* Content */}
                            <div className={"settings-content"}>

                                {/* Manager emails */}
                                <div className={"settings-sub-content"}>
                                    <ViewAnything title={"Email Report List"} desc={managerDesc}>
                                        <div>
                                            {(managerEmails.length === 0) && <p className={"note"}>There are no emails selected to receive the report.</p>}
                                            {managerEmails.map((email) =>
                                                <TextField key={email} className={"prof-tf"}
                                                           label="Email" variant="filled"
                                                           disabled={true} value={email}
                                                />
                                            )}
                                        </div>
                                        <p className={"note"}>Contact JAS to add another person to the email list.</p>
                                        <span className={"last-email-note"}>
                                            Last Email Report: {MC_Constants.getReadableDateStr(lastEmailReportTS)}
                                        </span>
                                    </ViewAnything>
                                </div>

                                {/* Service emails */}
                                <div className={"settings-sub-content"}>
                                    <ViewAnything title={"Service Team(s)"} desc={serviceDesc}>
                                        <div>
                                            {(serviceEmails.length === 0) && <p className={"note"}>There are no emails selected to receive support notifications.</p>}
                                            {serviceEmails.map((email) =>
                                                <TextField key={email} className={"prof-tf"} label="Email" variant="filled" disabled={true} value={email}/>
                                            )}
                                        </div>
                                        <p className={"note"}>Contact JAS to add another team to the email list.</p>
                                    </ViewAnything>
                                </div>

                                {/* Profile */}
                                <div className={"settings-sub-content"}>
                                    <ViewAnything title={"Profile"}>
                                        <br/>
                                        <TextField className={"prof-tf"} label="Name" variant="filled" disabled={true} value={profile.name}/>
                                        <TextField className={"prof-tf"} label="Email" variant="filled" disabled={true} value={profile.email}/>
                                        <TextField className={"prof-tf"} label="Phone" variant="filled" disabled={true} value={profile.phone}/>
                                        {profDescWithLink}
                                    </ViewAnything>
                                </div>

                            </div>

                            <br/><br/>
                        </div>
                    );
                }
            }
        </OverviewContext.Consumer>
    );
}

export default Settings;