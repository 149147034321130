import "./DetailRow.css"

interface DetailRowInterface {
    label: string;
    value?: string;
    color?: string;
    valueJSX?: JSX.Element;
}

function DetailRow(props: DetailRowInterface) {
    let valueClassName: string = "udr-value";
    if (props.color != null) {
        valueClassName += " " + props.color;
    }
    return (
        <div className={"udr-div"}>
            <span className={"udr-label"}>
                {props.label}:&nbsp;
            </span>
            {/* No JSX */}
            {(props.valueJSX) == null &&
                <span className={valueClassName}>
                    <strong>
                        {(props.value != null) ? props.value : "N/A"}
                    </strong>
                </span>
            }
            {/* JSX */}
            {(props.valueJSX != null) && props.valueJSX}
        </div>
    );
}

export default DetailRow;