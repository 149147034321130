import {MC_Action_Log} from "./MC_Action_Log";
import {MC_Constants} from "../MC_Constants";

export class MC_Overview {

    public static FIELD_MC_SETTINGS: string = "settings";
    public static FIELD_CLIENT_DB_SUMMARY: string = "client_db_summary";
    public static FIELD_SENSOR_DB_SUMMARY: string = "sensor_db_summary";
    public static FIELD_RECENT_ALERTS_SUMMARY: string = "recent_alerts_summary";
    public static FIELD_LAST_EMAIL_REPORT: string = "last_email_report";
    public static FIELD_LOGS: string = "logs";

    public mcSettings: MC_Settings;
    public clientDBSummary: MC_Client_DB_Summary;
    public sensorDBSummary: MC_Sensor_DB_Summary;
    public recentAlertsSummary: MC_Recent_Alerts_Summary;
    public lastEmailReport: MC_Last_Email_Report;
    public logs: MC_Action_Log[];

    constructor(data: any) {
        this.mcSettings = new MC_Settings(data[MC_Overview.FIELD_MC_SETTINGS]);
        this.clientDBSummary = new MC_Client_DB_Summary(data[MC_Overview.FIELD_CLIENT_DB_SUMMARY]);
        this.sensorDBSummary = new MC_Sensor_DB_Summary(data[MC_Overview.FIELD_SENSOR_DB_SUMMARY]);
        this.recentAlertsSummary = new MC_Recent_Alerts_Summary(data[MC_Overview.FIELD_RECENT_ALERTS_SUMMARY]);
        this.lastEmailReport = new MC_Last_Email_Report(data[MC_Overview.FIELD_LAST_EMAIL_REPORT]);
        this.logs = MC_Action_Log.processLogs(data[MC_Overview.FIELD_LOGS]);
    }

}

export class MC_Settings {

    public static FIELD_MANAGER_EMAILS: string = "manager_emails";
    public static FIELD_SERVICE_EMAILS: string = "service_emails";

    public managerEmails: string[];
    public serviceEmails: string[];

    constructor(data: any) {
        if (data == null) {
            data = {};
        }
        this.managerEmails = MC_Constants.parseNullableField<string[]>(
            data,
            MC_Settings.FIELD_MANAGER_EMAILS,
            []
        );
        this.serviceEmails = MC_Constants.parseNullableField<string[]>(
            data,
            MC_Settings.FIELD_SERVICE_EMAILS,
            []
        );
    }

}

export class MC_Client_DB_Summary {

    public static FIELD_DEVICE_COUNT: string = "device_count";
    public static FIELD_ALERT_COUNT: string = "alert_count";
    public static FIELD_ORG_COUNT: string = "organization_count";
    public static FIELD_EVENT_COUNT: string = "event_count";
    public static FIELD_USER_COUNT: string = "user_count";
    public static FIELD_NEW_DEVICE_COUNT: string = "new_device_count";
    public static FIELD_NEW_ALERT_COUNT: string = "new_alert_count";
    public static FIELD_NEW_ORG_COUNT: string = "new_organization_count";
    public static FIELD_NEW_EVENT_COUNT: string = "new_event_count";
    public static FIELD_NEW_USER_COUNT: string = "new_user_count";
    public static FIELD_TIMESTAMP: string = "timestamp";

    public deviceCount: number;
    public alertCount: number;
    public orgCount: number;
    public eventCount: number;
    public userCount: number;
    public newDeviceCount: number;
    public newAlertCount: number;
    public newOrgCount: number;
    public newEventCount: number;
    public newUserCount: number;
    public timestamp: Date;

    constructor(data: any) {
        this.deviceCount = data[MC_Client_DB_Summary.FIELD_DEVICE_COUNT] as number;
        this.alertCount = data[MC_Client_DB_Summary.FIELD_ALERT_COUNT] as number;
        this.orgCount = data[MC_Client_DB_Summary.FIELD_ORG_COUNT] as number;
        this.eventCount = data[MC_Client_DB_Summary.FIELD_EVENT_COUNT] as number;
        this.userCount = data[MC_Client_DB_Summary.FIELD_USER_COUNT] as number;
        this.newDeviceCount = data[MC_Client_DB_Summary.FIELD_NEW_DEVICE_COUNT] as number;
        this.newAlertCount = data[MC_Client_DB_Summary.FIELD_NEW_ALERT_COUNT] as number;
        this.newOrgCount = data[MC_Client_DB_Summary.FIELD_NEW_ORG_COUNT] as number;
        this.newEventCount = data[MC_Client_DB_Summary.FIELD_NEW_EVENT_COUNT] as number;
        this.newUserCount = data[MC_Client_DB_Summary.FIELD_NEW_USER_COUNT] as number;
        //console.log("ts: " + data[MC_Client_DB_Summary.FIELD_TIMESTAMP]);
        let tsVal: number = Date.parse(data[MC_Client_DB_Summary.FIELD_TIMESTAMP]);
        if (isNaN(tsVal)) {
            tsVal = 0;
        }
        this.timestamp = new Date(tsVal);
    }

}

export class MC_Sensor_DB_Summary {

    public static FIELD_ROW_COUNT: string = "row_count";
    public static FIELD_NEW_ROW_COUNT: string = "new_row_count";
    public static FIELD_TIMESTAMP: string = "timestamp";

    public rowCount: number;
    public newRowCount: number;
    public timestamp: Date;

    constructor(data: any) {
        this.rowCount = data[MC_Sensor_DB_Summary.FIELD_ROW_COUNT] as number;
        this.newRowCount = data[MC_Sensor_DB_Summary.FIELD_NEW_ROW_COUNT] as number;
        let tsVal: number = Date.parse(data[MC_Sensor_DB_Summary.FIELD_TIMESTAMP]);
        if (isNaN(tsVal)) {
            tsVal = 0;
        }
        this.timestamp = new Date(tsVal);
    }

}

export class MC_Recent_Alerts_Summary {

    public static FIELD_RECENT_ALERTS: string = "recent_alerts";
    public static FIELD_PAST_ALERTS: string = "past_alerts";
    public static FIELD_START: string = "start";
    public static FIELD_END: string = "end";

    public recentAlerts: number;
    public pastAlerts: number;
    public start: Date;
    public end: Date;

    constructor(data: any) {
        this.recentAlerts = data[MC_Recent_Alerts_Summary.FIELD_RECENT_ALERTS];
        this.pastAlerts = data[MC_Recent_Alerts_Summary.FIELD_PAST_ALERTS];
        let startVal: number = Date.parse(data[MC_Recent_Alerts_Summary.FIELD_START]);
        if (isNaN(startVal)) {
            startVal = 0;
        }
        this.start = new Date(startVal);
        let endVal: number = Date.parse(data[MC_Recent_Alerts_Summary.FIELD_END]);
        if (isNaN(endVal)) {
            endVal = 0;
        }
        this.end = new Date(endVal);
    }

}

export class MC_Last_Email_Report {

    public static FIELD_SENT: string = "sent";
    public static FIELD_FAILED: string = "failed";
    public static FIELD_TIMESTAMP: string = "timestamp";

    public sent: string[];
    public failed: string[];
    public timestamp: Date;

    constructor(data: any) {
        this.sent = data[MC_Last_Email_Report.FIELD_SENT] as string[];
        this.failed = data[MC_Last_Email_Report.FIELD_FAILED] as string[];
        let tsVal: number = Date.parse(data[MC_Last_Email_Report.FIELD_TIMESTAMP]);
        if (isNaN(tsVal)) {
            tsVal = 0;
        }
        this.timestamp = new Date(tsVal);
    }

}
