import {MC_Device} from "./iot/MC_Device";
import {MC_User} from "./iot/MC_User";
import {MC_Recent_Alert} from "./iot/MC_Recent_Alert";
import {MC_Organization} from "./iot/MC_Organization";
import axios, {AxiosRequestConfig} from "axios";
import {CustomConstants} from "../custom/CustomConstants";
import {MC_Overview} from "./iot/MC_Overview";
import {MC_Create_Device_Interface, MC_Create_Org_Interface} from "./iot/MC_Create_Interface";
import {CustomDeviceFactory} from "../custom/CustomDeviceFactory";
import {MC_Constants} from "./MC_Constants";
import {MC_Issue} from "./iot/MC_Issue";
import {CompleteMFADialogConfig} from "./ui/dialog/CompleteMFADialog/CompleteMFADialog";
import {
    Auth,
    browserLocalPersistence,
    getMultiFactorResolver,
    IdTokenResult,
    MultiFactorResolver,
    signInWithEmailAndPassword,
    User,
    UserCredential
} from "@firebase/auth";
import {MC_Device_Manual_License} from "./iot/MC_Device_Manual_License";
import {MC_Remote_Action_Response} from "./iot/MC_Remote_Action_Response";

export interface MCAuthContext {
    currentUser: User | null;
    updateCurrentUser: (newCurrentUser: User | null) => void;
}

export interface BaseMCContext {
    loading: boolean;
    errMsg: string | null;
    refreshTS: Date | null;
}

export interface MCOverviewContext extends BaseMCContext {
    overview: MC_Overview | null;
}

export interface MCRecentAlertsContext extends BaseMCContext {
    recentAlerts: MC_Recent_Alert[] | null;
}

export interface MCDevicesContext extends BaseMCContext {
    devices: MC_Device[] | null;
}

export interface MCOrganizationsContext extends BaseMCContext {
    organizations: MC_Organization[] | null;
}

export interface MCUsersContext extends BaseMCContext {
    users: MC_User[] | null;
}

export interface MCIssuesContext extends BaseMCContext {
    issues: MC_Issue[] | null;
}

export class MC_Backend {

    // Singleton access
    private static backend: MC_Backend | null;

    public static createInstance(auth: Auth): MC_Backend {
        this.backend = new MC_Backend(auth);
        return this.backend;
    }

    public static getInstance(): MC_Backend {
        return this.backend!;
    }

    // Current context
    private idTokenResult: IdTokenResult | null = null;
    public selfProfile: MC_User | null = null;
    public overviewCtx: MCOverviewContext = {overview: null, loading: false, errMsg: null, refreshTS: null};
    public recentAlertsCtx: MCRecentAlertsContext = {recentAlerts: null, loading: false, errMsg: null, refreshTS: null};
    public devicesCtx: MCDevicesContext = {devices: null, loading: false, errMsg: null, refreshTS: null};
    public orgsCtx: MCOrganizationsContext = {organizations: null, loading: false, errMsg: null, refreshTS: null};
    public usersCtx: MCUsersContext = {users: null, loading: false, errMsg: null, refreshTS: null};
    public issuesCtx: MCIssuesContext = {issues: null, loading: false, errMsg: null, refreshTS: null};

    // Update state context (assigned later with setState() fns from <App/>)
    public setOverviewContextState: (newOverviewCtx: MCOverviewContext) => void = (x) => {};
    public setRecentAlertsContextState: (newRecentAlertsCtx: MCRecentAlertsContext) => void = (x) => {};
    public setDevicesContextState: (newDevicesAlertsCtx: MCDevicesContext) => void = (x) => {};
    public setOrganizationsContextState: (newOrgsCtx: MCOrganizationsContext) => void = (x) => {};
    public setUsersContextState: (newUsersCtx: MCUsersContext) => void = (x) => {};
    public setIssuesContextState: (newIssuesCtx: MCIssuesContext) => void = (x) => {};

    // Update local copy of overview ctx & setState() with the new version
    public updateOverviewContext(overviewCtx: MCOverviewContext): void {
        this.overviewCtx = overviewCtx;
        this.setOverviewContextState(this.overviewCtx);
    }

    public updateRecentAlertsContext(recentAlertsCtx: MCRecentAlertsContext): void {
        this.recentAlertsCtx = recentAlertsCtx;
        this.setRecentAlertsContextState(this.recentAlertsCtx);
    }

    public updateDevicesContext(devicesCtx: MCDevicesContext): void {
        this.devicesCtx = devicesCtx;
        this.setDevicesContextState(this.devicesCtx);
    }

    public updateOrganizationsContext(orgsCtx: MCOrganizationsContext): void {
        this.orgsCtx = orgsCtx;
        this.setOrganizationsContextState(this.orgsCtx);
    }

    public updateUsersContext(usersCtx: MCUsersContext): void {
        this.usersCtx = usersCtx;
        this.setUsersContextState(this.usersCtx);
    }

    public updateIssuesContext(issuesCtx: MCIssuesContext): void {
        this.issuesCtx = issuesCtx;
        this.setIssuesContextState(this.issuesCtx);
    }

    private constructor(public auth: Auth) {
        this.clearContextData(); // This will init clear context
        this.auth.setPersistence(browserLocalPersistence).catch(() => console.error("err setting persistence"));
    }

    // -----------------------------------------------

    // Quick determination if the user is signed in
    public isSignedIn(): boolean {
        return (this.auth.currentUser != null && this.selfProfile != null);
    }

    // Returns the Firebase user auth token, refreshing it if needed
    public async getIDToken(allowUnauth: boolean = false): Promise<string> {
        // Determine if user is signed in
        if (this.auth.currentUser == null) {
            if (allowUnauth) {
                // Certain requests can be unauthenticated - use blank str to avoid throwing an error
                return "";
            } else {
                // Unauthenticated requests are not allowed
                throw "You are not signed in.";
            }
        }

        // We need to refresh the token if it's null, or expired
        let shouldRefreshToken = (this.idTokenResult == null); // Refresh if the token is null
        // Check for expired token
        if (this.idTokenResult != null) {
            let expireDate: Date = new Date(this.idTokenResult.expirationTime);
            let now = new Date();
            if (now.getTime() >= (expireDate.getTime() - (5 * 1000))) {
                // Token is expired or about to expire (within 5 seconds)
                shouldRefreshToken = true;
            }
        }

        // Refresh the token if needed
        if (shouldRefreshToken) {
            try {
                this.idTokenResult = await this.auth.currentUser.getIdTokenResult(true);
            } catch (err: any) {
                console.error((err.code != null) ? err.code : err.toString());
                throw "Failed to refresh credentials. Try logging in again.";
            }
        }

        // All good
        return this.idTokenResult!.token;
    }

    // Attempt to sign in as a cached user
    public async attemptSignInCachedUser(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const unsub = this.auth.onAuthStateChanged((state) => {
                // console.log("auth state change, not null? " + (state !== null));
                const hasCachedFirebaseUser: boolean = state !== null && this.auth.currentUser !== null;
                if (hasCachedFirebaseUser) {
                    // Logged into firebase, now find profile, and ensure they are an admin
                    this.completeSignInProcess(this.auth.currentUser!).then((userProfile) => {
                        resolve(true);
                    }).catch((err) => {
                       resolve(false);
                    });
                } else {
                    resolve(false);
                }
                unsub();
            }, (e) => {
                console.error("Auth state change err: " + e);
                reject("Cached user error");
                unsub();
            });
        });
    }

    public async signIn(
        email: string,
        password: string,
        setMFADialogOpenFn: (open: boolean) => void,
        setMFADialogConfigFn: (config: CompleteMFADialogConfig) => void,
    ): Promise<void> {
        let cred: UserCredential;
        try {
            cred = await signInWithEmailAndPassword(this.auth, email, password);
            await this.completeSignInProcess(cred.user);
        } catch (error: any) {
            let errCode = (error.code != null) ? error.code : error;
            if (errCode === 'auth/multi-factor-auth-required') {
                // Handle MFA (complete sign in
                const resolver: MultiFactorResolver = getMultiFactorResolver(this.auth, error);
                cred = await this.completeMFA(
                    setMFADialogOpenFn,
                    setMFADialogConfigFn,
                    resolver
                ); // This will throw an error if needed
                await this.completeSignInProcess(cred.user);
            } else if (errCode === 'auth/wrong-password') {
                throw "Invalid Email/Password combination.";
            } else {
                throw "Log In Error: " + errCode;
            }
        }
    }

    private completeMFA(
        setMFADialogOpenFn: (open: boolean) => void,
        setMFADialogConfigFn: (config: CompleteMFADialogConfig) => void,
        resolver: MultiFactorResolver
    ): Promise<UserCredential> {
        return new Promise((resolve, reject) => {
            // Set the MFA config
            setMFADialogConfigFn({
                resolver: resolver,
                successFn: (userCred: UserCredential) => {
                    resolve(userCred);
                },
                failureFn: (errStr) => {
                    reject(errStr);
                }
            });
            // Open the dialog
            setMFADialogOpenFn(true);
        });
    }

    private async completeSignInProcess(firebaseUser: User): Promise<MC_User> {
        try {
            const foundProfile: MC_User = await this.loadUser(firebaseUser.uid, false);
            if (!foundProfile.admin) {
                throw "User is not an admin.";
            }
            this.selfProfile = foundProfile;
            // Clear context
            this.clearContextData();
            // Load initial context
            this.initSessionWithBackgroundContext();
            // Return profile
            return foundProfile;
        } catch (err) {
            console.error("Sign in process error: " + err);
            await this.auth.signOut();
            throw "Wrong password, or you are not allowed to use this portal.";
        }
    }

    // Init clear data for a newly signed-in user
    public clearContextData(): void {
        this.overviewCtx = {overview: null, loading: false, errMsg: null, refreshTS: null};
        this.recentAlertsCtx = {recentAlerts: null, loading: false, errMsg: null, refreshTS: null};
        this.devicesCtx = {devices: null, loading: false, errMsg: null, refreshTS: null};
        this.orgsCtx = {organizations: null, loading: false, errMsg: null, refreshTS: null};
        this.usersCtx = {users: null, loading: false, errMsg: null, refreshTS: null};
    }

    // Init a new session & load essential context in the background
    public initSessionWithBackgroundContext(): void {
        this.loadIssues().catch((err) => {
            console.error("Error loading background issues context on sign-in");
        });
    }

    public signOut(): Promise<void> {
        return this.auth.signOut();
    }

    // --------- HTTP OPERATIONS ----------

    private async getHTTPRequestConfig(isJSONResponse: boolean = true): Promise<AxiosRequestConfig> {
        let authToken: string | null = await this.getIDToken(false);
        if (authToken == null) {
            authToken = "";
        }
        return {
            // `headers` are custom headers to be sent
            headers: {
                "authorization": "Bearer " + authToken,
                "auth-type": "user",
                "content-type": "application/json"
            },
            // `withCredentials` indicates whether or not cross-site Access-Control requests
            // should be made using credentials
            withCredentials: false, // default
            // `responseType` indicates the type of data that the server will respond with
            // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
            //   browser only: 'blob'
            responseType: (isJSONResponse) ? 'json' : 'blob', // default
            // `responseEncoding` indicates encoding to use for decoding responses (Node.js only)
            // Note: Ignored for `responseType` of 'stream' or client-side requests
            responseEncoding: 'utf8', // default
        };
    }

    // API GET request
    private async sendGETRequest(path: string): Promise<any> {
        let url: string = CustomConstants.API_ENDPOINT + path;
        let config = await this.getHTTPRequestConfig();
        return (await axios.get(url, config)).data;
    }

    // API POST request
    private async sendPOSTRequest(path: string, data: any): Promise<any> {
        const url: string = CustomConstants.API_ENDPOINT + path;
        const config = await this.getHTTPRequestConfig();
        const response = await axios.post(url, data, config);
        return response.data;
    }

    // API PUT request
    private async sendPUTRequest(path: string, data: any): Promise<any> {
        let url: string = CustomConstants.API_ENDPOINT + path;
        let config = await this.getHTTPRequestConfig();
        return (await axios.put(url, data, config)).data;
    }

    // API DELETE request
    private async sendDELETERequest(path: string): Promise<any> {
        let url: string = CustomConstants.API_ENDPOINT + path;
        let config = await this.getHTTPRequestConfig();
        return (await axios.delete(url, config)).data;
    }

    // ---------------------------------------------------

    // Load overview (Just subscribe to overview context for result)
    public async loadOverview(): Promise<void> {
        const oldCtx = this.overviewCtx;
        this.updateOverviewContext({
            ...oldCtx,
            loading: true,
            errMsg: null
        });
        // for load loop await new Promise(function(resolve) {setTimeout(resolve, 5000);});
        try {
            let overviewData: any = await this.sendGETRequest("/admin/overview");
            let newOverview: MC_Overview = new MC_Overview(overviewData);
            // Update the app
            this.updateOverviewContext({
                overview: newOverview,
                refreshTS: new Date(),
                loading: false,
                errMsg: null
            });
        } catch (err: any) {
            const errMsg: string = this.apiErrorResponseHandler(err, "Error loading overview.");
            this.updateOverviewContext({
                ...oldCtx,
                loading: false,
                errMsg: errMsg
            });
        }
    }

    // Load issues (Just subscribe to recent alerts context for result)
    public async loadIssues(): Promise<void> {
        const oldCtx = this.issuesCtx;
        this.updateIssuesContext({
            ...oldCtx,
            loading: true,
            errMsg: null
        });
        try {
            const issuesDataArray: any[] = await this.sendGETRequest("/issues");
            const issues: MC_Issue[] = MC_Issue.processIssues(issuesDataArray);
            this.updateIssuesContext({
                issues: issues,
                refreshTS: new Date(),
                loading: false,
                errMsg: null
            });
        } catch (err: any) {
            const errMsg: string = this.apiErrorResponseHandler(err, "Error loading issues.");
            this.updateIssuesContext({
                ...oldCtx,
                loading: false,
                errMsg: errMsg
            });
        }
    }

    // Load recent alerts (Just subscribe to recent alerts context for result)
    public async loadRecentAlerts(): Promise<void> {
        const oldCtx = this.recentAlertsCtx;
        this.updateRecentAlertsContext({
            ...oldCtx,
            loading: true,
            errMsg: null
        });
        try {
            let recentAlertsDataArray: any[] = await this.sendGETRequest("/admin/recent_alerts");
            let recentAlerts: MC_Recent_Alert[] = MC_Recent_Alert.getArrayFromData(recentAlertsDataArray);
            this.updateRecentAlertsContext(
                {
                    recentAlerts: recentAlerts,
                    refreshTS: new Date(),
                    loading: false,
                    errMsg: null
                }
            );
        } catch (err: any) {
            const errMsg: string = this.apiErrorResponseHandler(err, "Error loading recent alerts.");
            this.updateRecentAlertsContext({
                ...oldCtx,
                loading: false,
                errMsg: errMsg
            });
        }
    }

    // Load all devices (Just subscribe to devices context for result)
    public async loadDevices(): Promise<void> {
        const oldCtx = this.devicesCtx;
        this.updateDevicesContext({
            ...oldCtx,
           loading: true,
           errMsg: null
        });
        try {
            let devicesDataArray: any[] = await this.sendGETRequest("/devices");
            let devices: MC_Device[] = CustomDeviceFactory.getArrayFromData(devicesDataArray);
            this.updateDevicesContext(
                {
                    devices: devices,
                    refreshTS: new Date(),
                    loading: false,
                    errMsg: null
                }
            );
        } catch (err: any) {
            const errMsg: string = this.apiErrorResponseHandler(err, "Error loading devices.");
            this.updateDevicesContext({
                ...oldCtx,
                loading: false,
                errMsg: errMsg
            });
        }
    }

    // Load a specific device (Just subscribe to devices context for result)
    public async loadDevice(id: string): Promise<void> {
        // If no devices are loaded, just load them all (it will be the most recent version)
        if (this.devicesCtx.devices == null) {
            return this.loadDevices();
        }
        // Devices are already loaded, reload just this one
        try {
            let deviceData: any =  await this.sendGETRequest("/devices/" + id);
            let refreshedDevice: MC_Device = CustomDeviceFactory.fromData(deviceData);
            // Remove old device & add new device
            let deviceArray: MC_Device[] = this.devicesCtx.devices.filter((d) => d.id !== id);
            deviceArray = deviceArray.concat(refreshedDevice);
            // Update device context
            this.updateDevicesContext({
                ...this.devicesCtx,
                devices: deviceArray
            });
        } catch (err) {
            throw this.apiErrorResponseHandler(err, "Failed to load device");
        }
    }

    // Update device name/admin notes
    public async updateDeviceNameAndAdminNotes(deviceID: string, newName: string, newAdminNotes: string): Promise<void> {
        const updateData: any = {};
        updateData[MC_Device.FIELD_NAME] = newName;
        updateData[MC_Device.FIELD_ADMIN_NOTES] = newAdminNotes;
        return this.updateDevice(deviceID, updateData);
    }

    // Update use manual license flag
    public async updateDeviceUseManualLicense(deviceID: string, useManualLicense: boolean): Promise<void> {
        const update: any = {};
        update[MC_Device.FIELD_USE_MANUAL_LICENSE] = useManualLicense;
        return this.updateDevice(deviceID, update);
    }

    // Update manual license
    public async updateDeviceManualLicense(deviceID: string, manualLicense: MC_Device_Manual_License | null): Promise<void> {
        const update: any = {};
        update[MC_Device.FIELD_MANUAL_LICENSE] = (manualLicense != null) ? manualLicense.toJSON() : null;
        return this.updateDevice(deviceID, update);
    }

    // Update device name/admin notes
    public async updateDeviceSold(deviceID: string, newSold: boolean): Promise<void> {
        const updateData: any = {};
        updateData[MC_Device.FIELD_SOLD] = newSold;
        return this.updateDevice(deviceID, updateData);
    }

    // Update (user non-admin) settings
    public async updateDeviceSettings(deviceID: string, newSettings: any): Promise<void> {
        const updateData: any = {};
        updateData[MC_Device.FIELD_SETTINGS] = newSettings;
        return this.updateDevice(deviceID, updateData);
    }

    // Update admin settings
    public async updateDeviceAdminSettings(deviceID: string, newAdminSettings: any): Promise<void> {
        const updateData: any = {};
        updateData[MC_Device.FIELD_ADMIN_SETTINGS] = newAdminSettings;
        return this.updateDevice(deviceID, updateData);
    }

    // Update device properties
    private async updateDevice(deviceID: string, updateObj: any): Promise<void> {
        // If no devices are loaded, wait to load them all
        if (this.devicesCtx.devices == null) {
            await this.loadDevices();
        }
        // Update this device
        try {
            const deviceData = await this.sendPUTRequest("/devices/" + deviceID, updateObj);
            const updatedDevice: MC_Device = CustomDeviceFactory.fromData(deviceData);
            // Remove old device & add device
            let deviceArray: MC_Device[] = this.devicesCtx.devices!.filter((d) => d.id !== deviceID);
            deviceArray = deviceArray.concat(updatedDevice);
            // Update device context
            this.updateDevicesContext({
                ...this.devicesCtx,
                devices: deviceArray
            });
        } catch (err) {
            throw this.apiErrorResponseHandler(err, "Failed to update device.");
        }
    }

    // Load all organizations (Just subscribe to orgs context for result)
    public async loadOrganizations(): Promise<void> {
        const oldCtx = this.orgsCtx;
        this.updateOrganizationsContext({
            ...oldCtx,
            loading: true,
            errMsg: null
        });
        try {
            let orgsDataArray: any[] = await this.sendGETRequest("/organizations");
            let orgs: MC_Organization[] = MC_Organization.fromArrayData(orgsDataArray);
            this.updateOrganizationsContext(
                {
                    organizations: orgs,
                    refreshTS: new Date(),
                    loading: false,
                    errMsg: null
                }
            );
        } catch (err: any) {
            const errMsg: string = this.apiErrorResponseHandler(err, "Error loading organizations.");
            this.updateOrganizationsContext({
                ...oldCtx,
                loading: false,
                errMsg: errMsg
            });
        }
    }

    // Load a specific organization (Just subscribe to orgs context for result)
    public async loadOrganization(id: string): Promise<void> {
        // If no orgs are loaded, just load them all (it will be the most recent version)
        if (this.orgsCtx.organizations == null) {
            return this.loadOrganizations();
        }
        // Orgs are already loaded, reload just this one
        try {
            let orgData: any = await this.sendGETRequest("/organizations/" + id);
            let updatedOrg: MC_Organization = MC_Organization.fromData(orgData);
            // Remove old org & add new org
            let orgArray: MC_Organization[] = this.orgsCtx.organizations.filter((o) => o.id !== id);
            orgArray = orgArray.concat(updatedOrg);
            // Update user context
            this.updateOrganizationsContext({
                ...this.orgsCtx,
                organizations: orgArray,
            });
        } catch (err) {
            throw this.apiErrorResponseHandler(err, "Failed to load organization");
        }
    }

    // Update org name/admin notes
    public async updateOrganization(orgID: string, newName: string, newAdminNotes: string): Promise<void> {
        // If no orgs are loaded, wait to load them all
        if (this.orgsCtx.organizations == null) {
            await this.loadOrganizations();
        }
        // Update this org
        try {
            const updateData: any = {};
            updateData[MC_Organization.FIELD_NAME] = newName;
            updateData[MC_Organization.FIELD_ADMIN_NOTES] = newAdminNotes;
            const orgData = await this.sendPUTRequest("/organizations/" + orgID, updateData);
            const updatedOrg: MC_Organization = MC_Organization.fromData(orgData);
            // Remove old org & add org
            let orgArray: MC_Organization[] = this.orgsCtx.organizations!.filter((o) => o.id !== orgID);
            orgArray = orgArray.concat(updatedOrg);
            // Update org context
            this.updateOrganizationsContext({
                ...this.orgsCtx,
                organizations: orgArray
            });
        } catch (err) {
            throw this.apiErrorResponseHandler(err, "Failed to update organization.");
        }
    }

    // Load all users (Just subscribe to users context for result)
    public async loadUsers(): Promise<void> {
        const oldCtx = this.usersCtx;
        this.updateUsersContext({
            ...oldCtx,
            loading: true,
            errMsg: null
        })
        try {
            let usersDataArray: any[] = await this.sendGETRequest("/users");
            let users: MC_User[] = MC_User.fromArrayData(usersDataArray);
            this.updateUsersContext(
                {
                    users: users,
                    refreshTS: new Date(),
                    loading: false,
                    errMsg: null
                }
            );
        } catch (err: any) {
            const errMsg: string = this.apiErrorResponseHandler(err, "Error loading users.");
            this.updateUsersContext({
                ...oldCtx,
                loading: false,
                errMsg: errMsg
            });
        }
    }

    // Load a specific user (You can usually just subscribe to users context for result)
    public async loadUser(uid: string, addUserToCtx: boolean): Promise<MC_User> {
        // Users may or may not already be loaded
        try {
            // Load users if we are expecting them
            if (this.usersCtx.users == null && addUserToCtx) {
                await this.loadUsers();
            }
            // Load user
            let userData: any =  await this.sendGETRequest("/users/" + uid);
            let updatedUser: MC_User = MC_User.fromData(userData);

            // Update users ctx if applicable
            if (this.usersCtx.users != null && addUserToCtx) {
                // Remove old user & add new user
                let userArray: MC_User[] = this.usersCtx.users.filter((u) => u.uid !== uid);
                userArray = userArray.concat(updatedUser);
                // Update user context
                this.updateUsersContext({
                    ...this.usersCtx,
                    users: userArray
                });
            }
            // Return user
            return updatedUser;
        } catch (err) {
            throw this.apiErrorResponseHandler(err, "Failed to load user.");
        }
    }

    // Update user admin notes
    public async updateUser(uid: string, newAdminNotes: string): Promise<void> {
        // If no users are loaded, wait to load them all
        if (this.usersCtx.users == null) {
            await this.loadUsers();
        }
        // Update this user
        try {
            const updateData: any = {};
            updateData[MC_User.FIELD_ADMIN_NOTES] = newAdminNotes;
            const userData = await this.sendPUTRequest("/users/" + uid, updateData);
            const updatedUser: MC_User = MC_User.fromData(userData);
            // Remove old user & add user
            let userArray: MC_User[] = this.usersCtx.users!.filter((u) => u.uid !== uid);
            userArray = userArray.concat(updatedUser);
            // Update users context
            this.updateUsersContext({
                ...this.usersCtx,
                users: userArray
            });
        } catch (err) {
            throw this.apiErrorResponseHandler(err, "Failed to update user.");
        }
    }

    // ----- Issues ops -----

    // Load issue with ID
    public async loadIssue(id: string): Promise<MC_Issue> {
        // If no issues are loaded, just load them all (it will be the most recent version)
        if (this.issuesCtx.issues == null) {
            await this.loadIssues();
            const foundIssue: MC_Issue | null = this.findIssueByID(id);
            if (foundIssue == null) {
                throw "Issue not found.";
            }
            return foundIssue;
        }
        // Issues are already loaded, reload just this one
        try {
            const issueData: any = await this.sendGETRequest("/issues/" + id);
            const issue = MC_Issue.fromData(issueData);
            // Replace old issue with recent one
            const updatedIssuesArray = this.issuesCtx.issues.filter((x) => x.id !== id);
            updatedIssuesArray.push(issue);
            // Update user context
            this.updateIssuesContext({
                ...this.issuesCtx,
                issues: updatedIssuesArray
            });
            // Done
            return issue;
        } catch (err) {
            throw this.apiErrorResponseHandler(err, "Failed to load issue.");
        }
    }

    private async editIssue(issue: MC_Issue, opPath: string, updateData: any): Promise<MC_Issue> {
        // If no issues are loaded, wait to load them all
        if (this.issuesCtx.issues == null) {
            await this.loadIssues();
        }
        // Update this issue
        try {
            const baseIssuePath: string = "/issues/" + issue.id;
            const updatedIssueData = await this.sendPUTRequest( baseIssuePath + "/" + opPath, updateData);
            const updatedIssue = MC_Issue.fromData(updatedIssueData);
            // Update issues ctx
            let issueArray = this.issuesCtx.issues!.filter((x) => x.id !== issue.id);
            issueArray.push(updatedIssue);
            this.updateIssuesContext({
                ...this.issuesCtx,
                issues: issueArray
            });
            return updatedIssue;
        } catch (err) {
            throw this.apiErrorResponseHandler(err, "Failed to update issue support.");
        }
    }

    public async setIssueOpenState(issue: MC_Issue, newOpenVal: boolean): Promise<MC_Issue> {
        const updateData: any = {};
        updateData.open = newOpenVal;
        return this.editIssue(issue, "set_open", updateData);
    }

    public async addAdminToIssue(issue: MC_Issue, adminUID: string): Promise<MC_Issue> {
        const updateData: any = {};
        updateData.admin_uid = adminUID;
        return this.editIssue(issue, "add_admin", updateData);
    }

    public async removeAdminFromIssue(issue: MC_Issue, adminUID: string): Promise<MC_Issue> {
        const updateData: any = {};
        updateData.admin_uid = adminUID;
        return this.editIssue(issue, "remove_admin", updateData);
    }

    // ----- Find by ID -----

    // Find a given org by ID, null if it does not exist
    public findOrgByID(orgID: string): MC_Organization | null {
        if (this.orgsCtx == null) {
            return null;
        }
        if (this.orgsCtx.organizations != null) {
            for (let i = 0; i < this.orgsCtx.organizations!.length; i++) {
                if (this.orgsCtx.organizations[i].id === orgID) {
                    return this.orgsCtx.organizations[i];
                }
            }
        }
        return null;
    }

    // Find organizations by a list of IDs
    public findOrgsByIDs(orgIDs: string[]): MC_Organization[] {
        if (this.orgsCtx != null && this.orgsCtx.organizations != null) {
            return this.orgsCtx.organizations.filter((o) => orgIDs.includes(o.id));
        }
        return [];
    }

    // Find a given device by ID, null if it does not exist
    public findDeviceByID(deviceID: string): MC_Device | null {
        if (this.devicesCtx == null) {
            return null;
        }
        if (this.devicesCtx.devices != null) {
            for (let i = 0; i < this.devicesCtx.devices!.length; i++) {
                if (this.devicesCtx.devices[i].id === deviceID) {
                    return this.devicesCtx.devices[i];
                }
            }
        }
        return null;
    }

    // Returns an array of found devices, and an error message
    public findDevicesByIDs(deviceIDs: string[]): [MC_Device[], string | null] {
        if (this.devicesCtx == null) {
            return [[], "Missing devices context"];
        }
        let foundDevices: MC_Device[] = [];
        let errMsg: string | null = null;
        if (this.devicesCtx.devices != null) {
            for (let i = 0; i < this.devicesCtx.devices.length; i++) {
                if (deviceIDs.includes(this.devicesCtx.devices[i].id)) {
                    foundDevices[foundDevices.length] = this.devicesCtx.devices[i];
                }
            }
            // Detect missing devices
            let missingDeviceIDs: string[] = [];
            for (let i = 0; i < deviceIDs.length; i++) {
                let foundThisID: boolean = (foundDevices.filter((d) => d.id === deviceIDs[i])).length > 0;
                if (!foundThisID) {
                    missingDeviceIDs[missingDeviceIDs.length] = deviceIDs[i];
                }
            }
            // Create err msg accordingly
            if (missingDeviceIDs.length > 0) {
                errMsg = "Missing Devices: ";
                for (let i = 0; i < missingDeviceIDs.length; i++) {
                    if (i > 0) {
                        errMsg += ", ";
                    }
                    errMsg += missingDeviceIDs[i];
                }
            } else {
                errMsg = null;
            }
        } else {
            // devices array was null
            errMsg = "Devices have not been loaded yet.";
        }
        return [foundDevices, errMsg];
    }

    // Find a given user by ID, null if it does not exist
    public findUserByID(uid: string): MC_User | null {
        if (this.usersCtx == null) {
            return null;
        }
        if (this.usersCtx.users != null) {
            for (let i = 0; i < this.usersCtx.users!.length; i++) {
                if (this.usersCtx.users[i].uid === uid) {
                    return this.usersCtx.users[i];
                }
            }
        }
        return null;
    }

    public findUsersByIDs(userIDs: string[]): [MC_User[], string | null] {
        if (this.usersCtx == null) {
            return [[], "Mising users context"];
        }
        let foundUsers: MC_User[] = [];
        let errMsg: string | null = null;
        if (this.usersCtx.users != null) {
            for (let i = 0; i < this.usersCtx.users.length; i++) {
                if (userIDs.includes(this.usersCtx.users[i].uid)) {
                    foundUsers[foundUsers.length] = this.usersCtx.users[i];
                }
            }
            // Detect missing users
            let missingUserIDs: string[] = [];
            for (let i = 0; i < userIDs.length; i++) {
                let foundThisID: boolean = (foundUsers.filter((u) => u.uid === userIDs[i])).length > 0;
                if (!foundThisID) {
                    missingUserIDs[missingUserIDs.length] = userIDs[i];
                }
            }
            // Create err msg accordingly
            if (missingUserIDs.length > 0) {
                errMsg = "Missing User: ";
                for (let i = 0; i < missingUserIDs.length; i++) {
                    if (i > 0) {
                        errMsg += ", ";
                    }
                    errMsg += missingUserIDs[i];
                }
            } else {
                errMsg = null;
            }
        } else {
            // devices array was null
            errMsg = "Users have not been loaded yet.";
        }
        return [foundUsers, errMsg];

    }

    public findIssueByID(id: string): MC_Issue | null {
        if (this.issuesCtx.issues != null) {
            for (let issue of this.issuesCtx.issues) {
                if (issue.id === id) {
                    return issue;
                }
            }
        }
        return null;
    }

    public async addUserToOrg(user: MC_User, org: MC_Organization): Promise<void> {
        // PUT "/admin/user_relation"
        try {
            let reqData: any = {
                type: "add",
                organization_id: org.id,
                uid: user.uid
            };
            await this.sendPUTRequest("/admin/user_relation", reqData);
            // Update local data
            user.organizationIDs = Array.from(new Set(user.organizationIDs.concat(org.id)));
            this.updateUsersContext({...this.usersCtx});
            org.userIDs = Array.from(new Set(org.userIDs.concat(user.uid)));
            this.updateOrganizationsContext({...this.orgsCtx});
            // Refresh logs
            this.loadOverview().finally();
        } catch (e) {
            // Return an appropriate error response
            throw this.apiErrorResponseHandler(e, "Failed adding user to organization.");
        }
    }

    public async removeUserFromOrg(user: MC_User, org: MC_Organization): Promise<void> {
        // PUT "/admin/user_relation"
        try {
            let reqData: any = {
                type: "remove",
                organization_id: org.id,
                uid: user.uid
            };
            await this.sendPUTRequest("/admin/user_relation", reqData);
            // Update local data
            user.organizationIDs = user.organizationIDs.filter((id) => id !== org.id);
            this.updateUsersContext({...this.usersCtx});
            org.userIDs = org.userIDs.filter((id) => id !== user.uid);
            this.updateOrganizationsContext({...this.orgsCtx});
            // Refresh logs
            this.loadOverview().finally();
        } catch (e) {
            // Return an appropriate error response
            throw this.apiErrorResponseHandler(e, "Failed removing user from organization.");
        }
    }

    // Add a device to an org
    public async addDeviceToOrg(device: MC_Device, org: MC_Organization): Promise<void> {
        // PUT "/admin/device_relation"
        try {
            let reqData: any = {
                type: "add",
                organization_id: org.id,
                device_id: device.id
            };
            await this.sendPUTRequest("/admin/device_relation", reqData);
            // Update local data
            device.organizationIDs = Array.from(new Set(device.organizationIDs.concat(org.id)));
            this.updateDevicesContext({...this.devicesCtx});
            org.deviceIDs = Array.from(new Set(org.deviceIDs.concat(device.id)));
            this.updateOrganizationsContext({...this.orgsCtx});
            // Refresh logs
            this.loadOverview().finally();
        } catch (e) {
            // Return an appropriate error response
            throw this.apiErrorResponseHandler(e, "Failed adding device to organization.");
        }
    }

    // Remove a device from an org
    public async removeDeviceFromOrg(device: MC_Device, org: MC_Organization): Promise<void> {
        // PUT "/admin/device_relation"
        try {
            let reqData: any = {
                type: "remove",
                organization_id: org.id,
                device_id: device.id
            };
            await this.sendPUTRequest("/admin/device_relation", reqData);
            // Update local data
            device.organizationIDs = device.organizationIDs.filter((id) => id !== org.id);
            this.updateDevicesContext({...this.devicesCtx});
            org.deviceIDs = org.deviceIDs.filter((id) => id !== device.id);
            this.updateOrganizationsContext({...this.orgsCtx});
            // Refresh logs
            this.loadOverview().finally();
        } catch (e) {
            // Return an appropriate error response
            throw this.apiErrorResponseHandler(e, "Failed removing device from organization.");
        }
    }

    public async deleteUser(user: MC_User): Promise<void> {
        // DELETE "/users/uid"
        try {
            await this.sendDELETERequest("/users/" + user.uid);
            // Update local data
            this.usersCtx.users = this.usersCtx.users!.filter((u) => u.uid !== user.uid);
            this.updateUsersContext({...this.usersCtx});
            for (let orgID of user.organizationIDs) {
                let foundOrg: MC_Organization | null = this.findOrgByID(orgID);
                if (foundOrg != null) {
                    foundOrg!.userIDs = foundOrg!.userIDs.filter((x) => x !== user.uid);
                }
            }
            this.updateOrganizationsContext({...this.orgsCtx});
            // Refresh logs
            this.loadOverview().finally();
        } catch (e) {
            // Return an appropriate error response
            throw this.apiErrorResponseHandler(e, "Failed deleting user.");
        }
    }

    public async createDevice(data: MC_Create_Device_Interface): Promise<MC_Device> {
        // POST "/devices"
        try {
            // Create device in the backend
            let respData: any = await this.sendPOSTRequest("/devices", data);
            const newDevice: MC_Device = CustomDeviceFactory.fromData(respData);
            // Update local data
            this.devicesCtx.devices = this.devicesCtx.devices!.concat(newDevice);
            this.updateDevicesContext({...this.devicesCtx});
            // Don't need to add this device ID to any orgs (new devices don't have orgs yet)
            this.loadOverview().finally(); // Refresh logs
            return newDevice; // Return device
        } catch (e) {
            // Return an appropriate error response
            throw this.apiErrorResponseHandler(e, "Failed creating device.");
        }
    }

    public async deleteDevice(device: MC_Device): Promise<void> {
        // DELETE "/devices/id"
        try {
            await this.sendDELETERequest("/devices/" + device.id);
            // Update local data
            this.devicesCtx.devices = this.devicesCtx.devices!.filter((d) => d.id !== device.id);
            this.updateDevicesContext({...this.devicesCtx});
            for (let orgID of device.organizationIDs) {
                let foundOrg: MC_Organization | null = this.findOrgByID(orgID);
                if (foundOrg != null) {
                    foundOrg!.deviceIDs = foundOrg!.deviceIDs.filter((x) => x !== device.id);
                }
            }
            this.updateOrganizationsContext({...this.orgsCtx});
            // Refresh logs
            this.loadOverview().finally();
        } catch (e) {
            // Return an appropriate error response
            throw this.apiErrorResponseHandler(e, "Failed to delete device.");
        }
    }

    public async createOrganization(data: MC_Create_Org_Interface): Promise<MC_Organization> {
        // POST "/organizations"
        try {
            // Create org in the backend
            let respData: any = await this.sendPOSTRequest("/organizations", data);
            const newOrg: MC_Organization = MC_Organization.fromData(respData);
            // Update local data
            this.orgsCtx.organizations = this.orgsCtx.organizations!.concat(newOrg);
            this.updateOrganizationsContext({...this.orgsCtx});
            this.loadOverview().finally(); // Refresh logs
            return newOrg; // Return org
        } catch (e) {
            // Return an appropriate error response
            throw this.apiErrorResponseHandler(e, "Failed to create organization.");
        }
    }

    public async deleteOrganization(org: MC_Organization): Promise<void> {
        // DELETE "/organizations/id"
        try {
            await this.sendDELETERequest("/organizations/" + org.id);
            // Update local data
            this.orgsCtx.organizations = this.orgsCtx.organizations!.filter((o) => o.id !== org.id);
            this.updateOrganizationsContext({...this.orgsCtx});
            for (let userID of org.userIDs) {
                let foundUser: MC_User | null = this.findUserByID(userID);
                if (foundUser != null) {
                    foundUser.organizationIDs = foundUser.organizationIDs.filter((x) => x !== org.id);
                }
            }
            this.updateUsersContext({...this.usersCtx});
            for (let deviceID of org.deviceIDs) {
                let foundDevice: MC_Device | null = this.findDeviceByID(deviceID);
                if (foundDevice != null) {
                    foundDevice.organizationIDs = foundDevice.organizationIDs.filter((x) => x !== org.id);
                }
            }
            this.updateDevicesContext({...this.devicesCtx});
            // Refresh logs
            this.loadOverview().finally();
        } catch (e) {
            // Return an appropriate error response
            throw this.apiErrorResponseHandler(e, "Failed to delete organization.");
        }
    }

    public async callDeviceRemoteAction(
        deviceID: string, actionID: string, reqData: any
    ): Promise<MC_Remote_Action_Response> {
        // POST "devices/:deviceID/actions/:actionID"
        try {
            const path: string = "/devices/" + deviceID + "/actions/" + actionID;
            const resp: any = await this.sendPOSTRequest(path, reqData);
            return (resp as MC_Remote_Action_Response);
        } catch (e) {
            // Return appropriate error
            throw this.apiErrorResponseHandler(e, "Failed to call remote action.");
        }
    }

    // Get readable
    private apiErrorResponseHandler(err: any, defaultErrorMsg: string): string {
        // Determine if it was an api request error, or something else
        if (typeof err === 'string' || err instanceof String) {
            // err is already a string
            return err as string;
        } else if (err.response) {
            const status: number | null = (err.response.status != null) ? err.response.status as number : null;
            if (status != null) {
                if (status !== 0) {
                    // A request was made and server responded without a 2XX status code
                    const errRespData: any = err.response.data;
                    if (errRespData != null && errRespData.hasOwnProperty(MC_Constants.API_ERR_MESSAGE_PROPERTY)) {
                        // Throw API provided error
                        return errRespData[MC_Constants.API_ERR_MESSAGE_PROPERTY] as string;
                    }
                    // Tell status number
                    return "Status " + status + ": " + defaultErrorMsg;
                } else {
                    // Response status is 0, no network
                    return "Network Error: " + defaultErrorMsg;
                }
            }
            // Default unknown response status
            return "Unknown response status error.";
        } else if (err.request) {
            // The request was made but no response was received
            return "No server response";
        }
        // Default as last resort
        console.error("Unknown error: " + JSON.stringify(err));
        return "Unknown error: " + defaultErrorMsg;
    }

}


