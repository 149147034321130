import "./Item.css";
import {Divider, Paper} from "@mui/material";
import React from "react";
import {MC_Recent_Alert} from "../../iot/MC_Recent_Alert";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {MC_Constants} from "../../MC_Constants";

export interface RecentAlertItemProps {
    recentAlert: MC_Recent_Alert;
}
type RecentAlertResult = "success" | "partial" | "failed";
function RecentAlertItem(props: RecentAlertItemProps) {
    const ra = props.recentAlert;
    let alertResult: RecentAlertResult = getRecentAlertResult(ra);
    const iconColor: "primary" | "warning" | "error" = (alertResult === "success")
        ? "primary"
        : (alertResult === "partial") ? "warning" : "error"
    ;
    const tsStr: string = MC_Constants.getReadableDateStr(ra.recorded);
    return (
        <Paper elevation={3} className={"ra-root"}>

            <div className={"ra-row-col"}>

                {/* Header row */}
                <div className={"ra-header-row"}>
                    <NotificationsActiveIcon className={"ra-icon"} fontSize={"large"} color={iconColor} />
                    <div className={"ra-header-name-col"}>
                        <span className={"ra-title"}>{ra.description}</span>
                        <span className={"ra-subtitle"}>Contact: {ra.userName}</span>
                    </div>
                </div>

                <Divider sx={{width:"100%", m: 1}}/>

                {/* Contact info row */}
                <div className={"ra-contact-row"}>
                    {(ra.didEmail !== 0) &&
                        <span>
                        <span className={(ra.didEmail===1)?"ra-good":"ra-bad"}>
                            Email {(ra.didEmail===1)?"sent":"failed"}:&nbsp;
                        </span>
                            {ra.userEmail}
                    </span>
                    }
                    {(ra.didText !== 0) &&
                        <span>
                        <span className={(ra.didText===1)?"ra-good":"ra-bad"}>
                            Text {(ra.didText===1)?"sent":"failed"}:&nbsp;
                        </span>
                            {ra.userPhone}
                    </span>
                    }
                    {(ra.didCall !== 0) &&
                        <span>
                        <span className={(ra.didCall===1)?"ra-good":"ra-bad"}>
                            Call {(ra.didCall===1)?"sent":"failed"}:&nbsp;
                        </span>
                            {ra.userPhone}
                    </span>
                    }
                </div>

                <Divider sx={{width:"100%", m: 1}}/>

                {/* Full message row */}
                <div className={"ra-message-row"}>
                    <span className={"note"}>{ra.message}</span>
                </div>

                {/* Footer row */}
                <div className={"ra-footer-row"}>
                    <span className={"ra-ts"}>{tsStr}</span>
                </div>

            </div>

        </Paper>
    );
}

function getRecentAlertResult(alert: MC_Recent_Alert): RecentAlertResult {
    let hadSuccess: boolean = false;
    let hadFailure: boolean = false;
    // Email
    if (alert.didEmail === 1) {
        hadSuccess = true;
    } else if (alert.didEmail === -1) {
        hadFailure = true;
    }
    // Text
    if (alert.didText === 1) {
        hadSuccess = true;
    } else if (alert.didText === -1) {
        hadFailure = true;
    }
    // Call
    if (alert.didCall === 1) {
        hadSuccess = true;
    } else if (alert.didCall === -1) {
        hadFailure = true;
    }
    // Determine result
    if (hadSuccess && !hadFailure) {
        return "success";
    } else if (hadSuccess && hadFailure) {
        return "partial";
    } else {
        return "failed";
    }
}

export default RecentAlertItem;
