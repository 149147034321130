import {MC_Device} from "../../../iot/MC_Device";
import React from "react";
import {DevicesContext} from "../../../../App";
import {MC_Backend} from "../../../MC_Backend";
import MCLoadingBar from "../../misc/MCLoadingBar";
import DeviceItem from "../DeviceItem";

export interface DeviceItemListProps {
    deviceIDs: string[];
    selectAction?: (d: MC_Device) => void; // Add into a dialog
    removeAction?: (d: MC_Device) => void; // Remove from an org
}

function DeviceItemList(props: DeviceItemListProps) {
    // Load devices if needed
    let ctx = React.useContext(DevicesContext);
    React.useEffect(() => {
        if (ctx.devices == null && !ctx.loading && ctx.errMsg == null) {
            MC_Backend.getInstance().loadDevices().finally();
        }
    }, [ctx]);
    // UI
    return (
        <DevicesContext.Consumer>
            {(devicesCtx) => {
                // Parse display devices
                let allDeviceList: MC_Device[] | null = devicesCtx.devices;
                let deviceDisplayList = (allDeviceList != null)
                    ? allDeviceList.filter((d) => props.deviceIDs.includes(d.id))
                    : []
                ;

                // If the devices are loaded without error, check for device ID mismatch errors
                let errMsg: string | null = devicesCtx.errMsg;
                if (allDeviceList != null && errMsg == null && deviceDisplayList.length !== props.deviceIDs.length) {
                    errMsg = "";
                    // Collect device IDs that weren't found
                    for (let i = 0; i < props.deviceIDs.length; i++) {
                        let found: boolean = (deviceDisplayList.filter((d) => d.id === props.deviceIDs[i]).length > 0);
                        if (!found) {
                            errMsg += ((errMsg.length > 0) ? ", " : "") + props.deviceIDs[i];
                        }
                    }
                    errMsg = "Missing device IDs: " + errMsg;
                }

                // UI
                return (
                    <div className={"item-list-root"}>

                        {/* Loading bar */}
                        <MCLoadingBar loadingMessage={"Loading Devices..."}
                                      loading={devicesCtx.loading}
                                      errorMessage={errMsg}
                        />

                        {/* Warn for no devices */}
                        {
                            (allDeviceList != null && deviceDisplayList.length === 0) &&
                            <p className={"bad"}>No devices found.</p>
                        }

                        {/* Display devices */}
                        {deviceDisplayList.map(
                            (d) =>
                                <DeviceItem
                                    key={d.id} device={d} nameLink={true}
                                    selectAction={props.selectAction} removeAction={props.removeAction}
                                />
                        )}

                    </div>
                );
            }}
        </DevicesContext.Consumer>
    );
}

export default DeviceItemList;