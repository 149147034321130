import "./CreateOpDialog.css";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import React from "react";

export interface CreateOpDialogProps<ConfigDataType, CreatedDataType> {
    // General
    open: boolean;
    closeDialogFn: () => void;
    title: string;
    // Config step
    getConfigComponent: (curConfig: ConfigDataType | null, setConfig: (config: ConfigDataType) => void) => JSX.Element;
    // Confirm step
    getConfirmDesc: (data: ConfigDataType) => string; // Return "You are about to add user X to org Y"
    performActionFn: (config: ConfigDataType) => Promise<CreatedDataType>;
    // Wait step
    getSuccessDesc: (data: CreatedDataType) => string; // Return "User X added to org Y"
    doneSuccessFn: (data: CreatedDataType) => void;
    doneFailureFn: () => void;
}
interface CreateOpDialogState<ConfigDataType, CreatedDataType> {
    step: number;
    configData: ConfigDataType | null;
    asyncOp: Promise<CreatedDataType> | null;
    // Once asyncOp has started
    result: CreatedDataType | null;
    done: boolean;
    success: boolean;
    failureMsg: string | null;
}
function CreateOpDialog<ConfigDataType, CreatedDataType>(props: CreateOpDialogProps<ConfigDataType, CreatedDataType>) {
    // Constants
    const STEP_CONFIG: number = 0;
    const STEP_CONFIRM: number = 1;
    const STEP_WAIT: number = 2;
    // State
    const [state, setState] = React.useState<CreateOpDialogState<ConfigDataType, CreatedDataType>>(
        {step: STEP_CONFIG, configData: null, asyncOp: null, result: null, done: false, success: false, failureMsg: null}
    );
    // Helper fns
    const exitFn = () => {
        // Close dialog
        props.closeDialogFn();
        //props.open = false;
        // Reset state
        setState({step: STEP_CONFIG, configData: null, asyncOp: null, result: null, done: false, success: false, failureMsg: null});
    }
    const selectConfigFn = (config: ConfigDataType) => setState({...state, step: STEP_CONFIRM, configData: config});
    const cancelConfirmFn = () => setState({...state, step: STEP_CONFIG});
    const confirmFn = () => {
        // Perform op
        let operation = props.performActionFn(state.configData!);
        // Update state
        setState({...state, step: STEP_WAIT, asyncOp: operation});
        // Update state again on completion
        operation.then((data) => {
            setState({...state, step: STEP_WAIT, done: true, result: data, success: true, failureMsg: null});
        }).catch((e) => {
            setState({...state, step: STEP_WAIT, done: true, result: null, success: false, failureMsg: e as string});
        });
    }
    const allDoneFn = () => {
        if (state.done) {
            if (state.success) {
                props.doneSuccessFn(state.result!);
            } else {
                props.doneFailureFn();
            }
        }
        // Close dialog & reset state
        exitFn();
    }
    // UI
    return <Dialog className={"cod-root"} open={props.open}>
        {/* Header (title & close) */}
        <DialogTitle>
            <div className={"header-row"}>
                <span className={"header-side-div"}/>
                <span className={"title"}>{props.title}</span>
                {/* Do not allow exit while op is in progress */}
                {
                    (state.step === STEP_WAIT)
                        ? <div className={"header-side-div"}/>
                        : <IconButton className={"header-side-div"} onClick={exitFn}>
                            <Close fontSize={"medium"}/>
                        </IconButton>
                }
            </div>
        </DialogTitle>

        {/* Content */}
        <DialogContent>
            {/* Config */}
            {
                (state.step === STEP_CONFIG) &&
                props.getConfigComponent(state.configData, selectConfigFn)
            }
            {/* Confirm */}
            {
                (state.step === STEP_CONFIRM) &&
                <p>{props.getConfirmDesc(state.configData!)}</p>
            }
            {/* Wait step */}
            {
                (state.step === STEP_WAIT) &&
                <React.Fragment>
                    {/* Loading icon */}
                    {
                        (!state.done)
                        &&
                        <CircularProgress color="primary"/>
                    }
                    {/* Completion message */}
                    {
                        (state.done) &&
                        <p>
                            {(state.success) ? props.getSuccessDesc(state.result!) : state.failureMsg}
                        </p>
                    }
                </React.Fragment>
            }
        </DialogContent>

        {/* Actions */}
        <DialogActions className={"confirm-page-actions"}>
            {/* Confirm step buttons */}
            {
                (state.step === STEP_CONFIRM)
                &&
                <React.Fragment>
                    <Button className={"action-btn"} color={"secondary"} onClick={cancelConfirmFn}>
                        <strong>Cancel</strong>
                    </Button>
                    <Button className={"action-btn"} color={"success"} onClick={confirmFn}>
                        <strong>Create</strong>
                    </Button>
                </React.Fragment>
            }
            {/* Waiting step buttons */}
            {
                (state.step === STEP_WAIT && state.done)
                &&
                <Button className={"action-btn"} onClick={allDoneFn}>
                    <strong>Okay</strong>
                </Button>
            }
        </DialogActions>

    </Dialog>;
}

export default CreateOpDialog;