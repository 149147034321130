import {MC_Action_Log} from "./MC_Action_Log";
import {Link} from "react-router-dom";
import React, {CSSProperties} from "react";
import {MC_Device} from "./MC_Device";
import {Tooltip} from "@mui/material";
import {SimpleSubscriptionStatus} from "./simple_stripe_types";
import {MC_Device_Manual_License} from "./MC_Device_Manual_License";

// Part of a log string
type DescPart = {
    value: string,
    isQuote: boolean
};
const linkStyle: CSSProperties = {
    color: "var(--MC_MAIN)",
    textDecorationColor: "var(--MC_MAIN)"
};
export class MC_UI_Helper {

    private static breakLogDescIntoParts(desc: string): DescPart[] {
        const QUOTE_STR: string = "'"; // Careful if this changes ever
        // Find each index of a quote
        let foundQuoteIndexes: number[] = [];
        let searchIndex: number = 0;
        while (desc.indexOf(QUOTE_STR, searchIndex) !== -1) {
            let quoteIndex: number = desc.indexOf(QUOTE_STR, searchIndex);
            foundQuoteIndexes[foundQuoteIndexes.length] = quoteIndex;
            searchIndex = quoteIndex + 1;
        }

        // Handle no-quote descriptions & ill-formatted strings (odd number of quote indexes)
        if ((foundQuoteIndexes.length === 0) || (foundQuoteIndexes.length % 2) !== 0) {
            // Just treat the entire description as a single part
            return [
                {
                    value: desc,
                    isQuote: false
                }
            ];
        }

        // Create a part for each relevant part of the string
        let firstPart: DescPart = {
            value: desc.substring(0, foundQuoteIndexes[0]),
            isQuote: false
        };
        let parts: DescPart[] = [firstPart];
        let q: number = 0; // Quote index (they come in groups of 2 tho
        do {
            // Add quote part (substr from quote start to quote end)
            parts[parts.length] = {
                value: desc.substring(foundQuoteIndexes[q] + 1, foundQuoteIndexes[q + 1]),
                isQuote: true
            };
            // Add normal part (substr to start of next quote OR end of desc)
            parts[parts.length] = {
                value: ((q+2) < foundQuoteIndexes.length)
                    ? desc.substring(foundQuoteIndexes[q + 1] + 1, foundQuoteIndexes[q + 2]) // Just substr till the next quote
                    : desc.substring(foundQuoteIndexes[q + 1] + 1) // Substr until the end of the desc
                ,
                isQuote: false
            }
            // Increment by 2 (cause quotes are dealt with in pairs)
            q += 2;
        } while (q < foundQuoteIndexes.length); // Stop when there are no more quote indexes to deal with

        // Done, nice
        return parts;
    }

    // Render a desc part (basic string, or react link to the referenced item
    private static renderDescPart(part: DescPart, log: MC_Action_Log, idx: number): any {
        if (part.isQuote) {
            // Try to find what the log is referencing
            let quote: string = part.value;

            // Check each target
            if (quote === log.config.target_user_name) {
                // Quote is referencing a user, return a link to the profile
                return (
                    <Link key={"part" + idx} to={"/core/users/" + log.config.target_user_id} style={linkStyle}>
                        <span><strong>{quote}</strong></span>
                    </Link>
                );
            } else if (quote === log.config.target_device_name || quote === log.config.target_device_jas_id) {
                // Quote is referencing a device, return a link to the profile
                return (
                    <Link key={"part" + idx} to={"/core/devices/" + log.config.target_device_id} style={linkStyle}>
                        <span><strong>{quote}</strong></span>
                    </Link>
                );
            } else if (quote === log.config.target_alert_name) {
                // Quote is referencing an alert, ignore it
            } else if (quote === log.config.target_organization_name) {
                // Quote is referencing an organization, return a link to the profile
                return (
                    <Link key={"part" + idx} to={"/core/organizations/" + log.config.target_organization_id} style={linkStyle}>
                        <span><strong>{quote}</strong></span>
                    </Link>
                );
            } else if (quote === log.config.target_event_name) {
                // Quote is referencing an event, ignore it
            }
        }
        return <span key={"part" + idx}>{part.value}</span>;
    }

    public static getLinkedTextForLog(log: MC_Action_Log, baseClassName: string): any {
        // Holds the parts of the string
        let parts: DescPart[] = this.breakLogDescIntoParts(log.desc);
        // Piece the parts back together
        return (
            <span className={baseClassName}>
                {parts.map((p, idx) => this.renderDescPart(p, log, idx))}
            </span>
        );
    }

    public static getLicenseCell(device: MC_Device): any {
        let licenseSpanClass: string = "";
        let licenseTooltip: string = "";
        let licenseMsg: string = "";
        if (device.demo) {
            licenseSpanClass = "green";
            licenseTooltip = "NO LICENSE REQUIRED";
            licenseMsg = "Promo Unit";
        } else if (!device.sold) {
            licenseSpanClass = "note";
            licenseTooltip = "No customer yet";
            licenseMsg = "Unsold";
        } else {
            // Device is sold
            if (device.useManualLicense) {
                // Manual license
                const manualLicense: MC_Device_Manual_License | null = device.manualLicense;
                if (manualLicense != null) {
                    const nowMs: number = new Date().getTime();
                    const endDate: Date | null = manualLicense.getEndDate();
                    const endMs: number = (endDate != null) ? endDate.getTime() : 0;
                    const daysAhead: number = (endMs - nowMs) / (24 * 60 * 60 * 1000);
                    const roundedDaysAhead = parseInt(daysAhead + "");
                    if (daysAhead > 30) {
                        licenseSpanClass = "green";
                        licenseTooltip = "Expires in " + roundedDaysAhead + " days";
                        licenseMsg = "ACTIVE (Manual)";
                    } else if (daysAhead > 0) {
                        licenseSpanClass = "orange";
                        licenseTooltip = "Expires in " + roundedDaysAhead + " days";
                        licenseMsg = "EXPIRES SOON (Manual)";
                    } else {
                        licenseSpanClass = "red";
                        licenseTooltip = "Awaiting license renewal";
                        licenseMsg = "EXPIRED (Manual)";
                    }
                } else {
                    // No manual license
                    licenseSpanClass = "red";
                    licenseTooltip = "Waiting for manual license";
                    licenseMsg = "UNLICENSED (Manual)";
                }
            } else if (device.simple_sub != null) {
                // Automatic Subscription license
                let status: SimpleSubscriptionStatus = device.simple_sub.status;
                if (status === "incomplete") {
                    licenseSpanClass = "orange";
                    licenseTooltip = "Waiting for payment";
                    licenseMsg = "Incomplete";
                } else if (status === "incomplete_expired") {
                    licenseSpanClass = "red";
                    licenseTooltip = "Payment window expired";
                    licenseMsg = "Incomplete Expired";
                } else if (status === "trialing") {
                    licenseSpanClass = "green-txt";
                    licenseTooltip = "In Trial Period";
                    licenseMsg = "Free Trial";
                } else if (status === "active") {
                    licenseSpanClass = "green-txt";
                    licenseTooltip = "Active Subscription";
                    licenseMsg = "Active";
                } else if (status === "paused") {
                    licenseSpanClass = "orange";
                    licenseTooltip = "Paused Subscription";
                    licenseMsg = "Paused";
                } else if (status === "past_due") {
                    licenseSpanClass = "orange";
                    licenseTooltip = "Payment Past Due";
                    licenseMsg = "Past Due";
                } else if (status === "unpaid") {
                    licenseSpanClass = "red";
                    licenseTooltip = "Payment Overdue";
                    licenseMsg = "Overdue";
                } else if (status === "canceled") {
                    licenseSpanClass = "red";
                    licenseTooltip = "Subscription Cancelled";
                    licenseMsg = "Cancelled";
                }  else {
                    licenseSpanClass = "red";
                    licenseTooltip = status;
                    licenseMsg = "UNKNOWN";
                }
            } else {
                licenseSpanClass = "red";
                licenseTooltip = "Waiting for subscription";
                licenseMsg = "UNLICENSED";
            }
        }
        return <Tooltip title={licenseTooltip} placement={"top"}>
            <span className={licenseSpanClass}>
                <strong className={"no-pointer-events"}>{licenseMsg}</strong>
            </span>
        </Tooltip>;
    }

}
