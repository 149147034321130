import './Alerts.css';
import React, {useContext} from "react";
import {MC_Recent_Alert} from "../../common/iot/MC_Recent_Alert";
import TableControl, {TableControlSelectOption} from "../../common/ui/table/TableControl";
import {RecentAlertsContext} from "../../App";
import {MC_Backend} from "../../common/MC_Backend";
import MCLoadingBar from "../../common/ui/misc/MCLoadingBar";
import GeneralHeader from "../../common/ui/GeneralHeader";
import useLocalStorageState from "../../common/ui/UseLocalStorageState";
import AlertsTable from "../../common/ui/custom-table/AlertsTable";

const STORAGE_ALERTS_SORT_FIELD: string = "alerts_sort_field";
const STORAGE_ALERTS_SEARCH_KEY: string = "alerts_search_key";

const Alerts = (props: any) => {
    const ctx = useContext(RecentAlertsContext);
    // State vars
    const [sortField, updateSortField] = useLocalStorageState(STORAGE_ALERTS_SORT_FIELD, "timestamp");
    const [searchKey, updateSearchKey] = useLocalStorageState(STORAGE_ALERTS_SEARCH_KEY, "");

    // Helpers
    const refreshFn = () => {
        MC_Backend.getInstance().loadRecentAlerts().finally(); // The result will be updated via context provider
    }

    // Validate search key
    React.useEffect(() => {
        if (searchKey.length > 0) {
            if (searchKey.replace(/\s/g, '').length === 0) {
                // search key is all white space
                updateSearchKey(""); // Clear it
            }
        }
    }, [searchKey, updateSearchKey]);

    // Load recent alerts only if needed
    React.useEffect(() => {
        if (ctx.recentAlerts == null && !ctx.loading && ctx.errMsg == null) {
            refreshFn();
        }
    }, [ctx.recentAlerts, ctx.loading, ctx.errMsg]);

    // Filter/sort the alerts whenever necessary
    React.useEffect(
        () => {
            const backend = MC_Backend.getInstance();
            backend.setRecentAlertsContextState({...backend.recentAlertsCtx}); // Trigger alerts ctx consumer fn
        },
        [searchKey, sortField]
    );

    // UI
    return (
        <RecentAlertsContext.Consumer>
            {(curCtx) => {
                // Prep
                const curAlerts: MC_Recent_Alert[] = parseCurrentAlerts(
                    (curCtx.recentAlerts != null) ? curCtx.recentAlerts : [],
                    sortField,
                    searchKey
                );
                const tableSortOptions: TableControlSelectOption[] = [
                    {value: "timestamp", name: "Timestamp"},
                    {value: "name", name: "Name"},
                    {value: "result", name: "Result"}
                ];
                const tableCtl = <TableControl
                    searchKey={searchKey} updateSearchKey={updateSearchKey} searchHint={"Search by User or Description..."}
                    sortOptions={tableSortOptions} sortField={sortField} updateSortField={updateSortField}
                />;
                // UI
                return (
                    <div className={"alerts-root"}>

                        {/* Control header */}
                        <GeneralHeader title={"Recent Alerts"} refreshAction={(!curCtx.loading) ? refreshFn : () => {}}/>

                        {/* Loading bar */}
                        <MCLoadingBar loading={curCtx.loading} errorMessage={curCtx.errMsg}
                                      loadingMessage={"Loading Recent Alerts..."}
                        />

                        {/* Description */}
                        <p className={"small-note"}>
                            This is a table of all alerts triggered recently from your monitoring platform.
                        </p>

                        {/* Content */}
                        <div>
                            {(curCtx.recentAlerts != null) &&
                                <AlertsTable tableCtl={tableCtl} alerts={curAlerts}/>
                            }
                        </div>

                        <br/><br/>

                    </div>
                );
            }}
        </RecentAlertsContext.Consumer>
    );
}

// Filter & sort alerts
function parseCurrentAlerts(
    allAlerts: MC_Recent_Alert[],
    sortField: string,
    searchKey: string
): MC_Recent_Alert[] {

    // 1) Filter the alerts
    let curAlerts: MC_Recent_Alert[] = allAlerts;
    if (searchKey.length > 0) {
        const lowKey: string = searchKey.toLowerCase();
        const filterAlertUserName = (a: MC_Recent_Alert): boolean => a.userName.toLowerCase().includes(lowKey);
        const filterAlertDesc = (a: MC_Recent_Alert): boolean => a.description.toLowerCase().includes(lowKey);
        const filterAlertMessage = (a: MC_Recent_Alert): boolean => {
            // Some alerts were triggered before the 'message' field was added
            if (a.message != null) {
                return a.message.toLowerCase().includes(lowKey);
            }
            return false;
        }
        curAlerts = curAlerts.filter((x) => (filterAlertUserName(x) || filterAlertDesc(x) || filterAlertMessage(x)));
    }

    // 2) Sort the alerts
    let sortFn: (a: MC_Recent_Alert, b: MC_Recent_Alert) => number;
    if (sortField === "result") {
        sortFn = (a: MC_Recent_Alert, b: MC_Recent_Alert): number => {
            if (a.result !== b.result) {
                return (a.result < 0) ? -1 : 1; // Sort failed alerts first
            }
            return 0;
        };
    } else if (sortField === "name") {
        sortFn = (a: MC_Recent_Alert, b: MC_Recent_Alert): number => a.userName.localeCompare(b.userName);
    } else {
        // sortField === "timestamp"
        sortFn = (a: MC_Recent_Alert, b: MC_Recent_Alert): number => {
            return b.recorded.getTime() - a.recorded.getTime();
        };
    }
    curAlerts.sort(sortFn);

    // Done
    return curAlerts;
}

export default Alerts;