import {MC_Create_Org_Interface} from "../../../iot/MC_Create_Interface";
import React from "react";
import {Button, TextField} from "@mui/material";

export interface CreateOrganizationComponentProps {
    existingConfig: MC_Create_Org_Interface | null;
    selectConfig: (config: MC_Create_Org_Interface) => void;
}
interface CreateOrganizationComponentState {
    name: string;
}
function CreateOrganizationComponent(props: CreateOrganizationComponentProps) {
    // State
    const [state, setState] = React.useState<CreateOrganizationComponentState>({name: ""});
    // Update state on first render
    React.useEffect(() => {
        if (props.existingConfig != null) {
            setState({
                name: props.existingConfig.name
            });
        }
    }, [props.existingConfig]);
    // Helper fns
    // const handleTZChange = (event: SelectChangeEvent) => setState({name: state.name, id: state.id});
    const continueFn = () => {
        const newConfig: MC_Create_Org_Interface = {
            name: state.name
        };
        props.selectConfig(newConfig);
    };
    // Err msg
    let errMsg: string | null = null;
    if (state.name.length < 5) {
        errMsg = "Name must be at least 5 characters.";
    }
    // UI
    return <div className={"create-config-root"}>

        {/* Usage note */}
        <p className={"note"}>An organization represents a business customer.</p>

        {/* Name */}
        <TextField label="Name" variant="filled" value={state.name}
                   onChange={(event) => {
                       setState({name: event.target.value});
                   }}
        />

        <br/>

        {/* Optional error note */}
        {
            (errMsg != null) &&
            <span className={"error-note"}>{errMsg}</span>
        }

        {/* Confirm button */}
        <Button variant="text" color={"primary"} disabled={errMsg != null} onClick={continueFn}><b>Continue</b></Button>

    </div>;
}

export default CreateOrganizationComponent;