import "./ItemList.css";
import {MC_Organization} from "../../../iot/MC_Organization";
import {OrganizationsContext} from "../../../../App";
import MCLoadingBar from "../../misc/MCLoadingBar";
import React from "react";
import {MC_Backend} from "../../../MC_Backend";
import OrganizationItem from "../OrganizationItem";

export interface OrganizationItemListProps {
    organizationIDs: string[];
    selectAction?: (org: MC_Organization) => void; // Select an org in dialog
    removeAction?: (org: MC_Organization) => void; // Remove from user
}
function OrganizationItemList(props: OrganizationItemListProps) {
    // Load orgs if needed
    let ctx = React.useContext(OrganizationsContext);
    React.useEffect(() => {
        if (ctx.organizations == null && !ctx.loading && ctx.errMsg == null) {
            MC_Backend.getInstance().loadOrganizations().finally();
        }
    }, [ctx]);
    // UI
    return (
        <OrganizationsContext.Consumer>
            {(orgsCtx) => {
                // Parse display orgs
                let allOrgList: MC_Organization[] | null = orgsCtx.organizations;
                let orgDisplayList = (allOrgList != null)
                    ? allOrgList.filter((o) => props.organizationIDs.includes(o.id))
                    : []
                ;

                // If the orgs are loaded without error, check for org ID mismatch errors
                let errMsg: string | null = orgsCtx.errMsg;
                if (allOrgList != null && errMsg == null && orgDisplayList.length !== props.organizationIDs.length) {
                    errMsg = "";
                    // Collect org IDs that weren't found
                    for (let i = 0; i < props.organizationIDs.length; i++) {
                        let found: boolean = (orgDisplayList.filter((o) => o.id === props.organizationIDs[i]).length > 0);
                        if (!found) {
                            errMsg += ((errMsg.length > 0) ? ", " : "") + props.organizationIDs[i];
                        }
                    }
                    errMsg = "Missing Organization IDs: " + errMsg;
                }

                // UI
                return (
                    <div className={"item-list-root"}>

                        {/* Loading bar */}
                        <MCLoadingBar loadingMessage={"Loading Organizations..."}
                                      loading={orgsCtx.loading}
                                      errorMessage={errMsg}
                        />

                        {/* Warn for no organizations */}
                        {
                            (allOrgList != null && orgDisplayList.length === 0)
                            &&
                            <p className={"bad"}>No organizations found.</p>
                        }

                        {/* Display organizations */}
                        {orgDisplayList.map(
                            (o) =>
                                <OrganizationItem
                                    key={o.id} org={o} nameLink={true}
                                    selectAction={props.selectAction} removeAction={props.removeAction}
                                />
                        )}

                    </div>
                );
            }}
        </OrganizationsContext.Consumer>
    );
}

export default OrganizationItemList;