import React from "react";
import {Alert, Snackbar, SnackbarOrigin} from "@mui/material";

export interface SingleSnackBarProps {
    open: boolean;
    setOpen: (x: boolean) => void;
    message: string;
}
export interface MCFullSnackBarProps {
    infoProps?: SingleSnackBarProps;
    warningProps?: SingleSnackBarProps;
    errorProps?: SingleSnackBarProps;
    successProps?: SingleSnackBarProps;
}
function MCFullSnackBar(props: MCFullSnackBarProps) {
    const autoHideDuration: number = 3000; // Usually 3000
    const origin: SnackbarOrigin = { vertical: "bottom", horizontal: "center" };
    const alertSx: any = { width: '100%' };
    return (
        <React.Fragment>

            {/* Info */}
            {(props.infoProps != null) &&
                <Snackbar
                    open={props.infoProps.open}
                    autoHideDuration={autoHideDuration} anchorOrigin={origin}
                    onClose={() => props.infoProps?.setOpen(false)}>
                    <Alert severity="info" sx={alertSx}>
                        {props.infoProps.message}
                    </Alert>
                </Snackbar>
            }

            {/* Warning */}
            {(props.warningProps != null) &&
                <Snackbar
                    open={props.warningProps.open}
                    autoHideDuration={autoHideDuration} anchorOrigin={origin}
                    onClose={() => props.warningProps?.setOpen(false)}>
                    <Alert severity="warning" sx={alertSx}>
                        {props.warningProps.message}
                    </Alert>
                </Snackbar>
            }

            {/* Error */}
            {(props.errorProps != null) &&
                <Snackbar
                    open={props.errorProps.open}
                    autoHideDuration={autoHideDuration} anchorOrigin={origin}
                    onClose={() => props.errorProps?.setOpen(false)}>
                    <Alert severity="error" sx={alertSx}>
                        {props.errorProps.message}
                    </Alert>
                </Snackbar>
            }

            {/* Success */}
            {(props.successProps != null) &&
                <Snackbar
                    open={props.successProps.open}
                    autoHideDuration={autoHideDuration} anchorOrigin={origin}
                    onClose={() => props.successProps?.setOpen(false)}>
                    <Alert severity="success" sx={alertSx}>
                        {props.successProps.message}
                    </Alert>
                </Snackbar>
            }

        </React.Fragment>
    );
}

export default MCFullSnackBar;