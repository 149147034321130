
export class CustomConstants {

    // Version
    public static ADMIN_SOFTWARE_VERSION: string = "v0.3.1";

    // Admin
    public static ADMIN_SOFTWARE_NAME: string = "JAS Demo";
    public static ADMIN_SOFTWARE_ENDPOINT: string = "https://admin.demo.jasmonitoring.com";
    public static MONITORING_SOFTWARE_URL: string = "https://demo.jasmonitoring.com";

    // Backend
    public static API_ENDPOINT: string = "https://us-central1-jas-white-label.cloudfunctions.net/v2";
    public static SERVER_UIDS: string[] = [
        "jas-data-server",
        "jas-schedule-server",
        "jas-billing-server"
    ];

    // (Automatic billing)
    public static STRIPE_OEM_CONNECT_ID: string = "acct_1NkX95Ffm8leHrub"; // Dillon Fake Biz account ID
    public static STRIPE_PRICE_TEMP_HUMIDITY_WIFI: string = "price_1ONMuKFfm8leHrubqQHpVZIg";
    public static STRIPE_PRICE_TEMP_WIFI: string = "price_1ONMxWFfm8leHrubiw2nNCF0";
    public static STRIPE_PRICE_CO2_WIFI: string = "price_1ONMyBFfm8leHrubT27W6Fsc";
    public static STRIPE_PRICE_PRESSURE_WIFI: string = "price_1ONMwgFfm8leHrub0W112Ohr";
    public static STRIPE_PRICE_STEFFES_CELLULAR: string = "price_1OX7nSFfm8leHrubS02PHvYo";

    // Return the stripe fee amount for a given price ID (100 = $1)
    public static getJASFeeForPriceID(priceID: string | null): number {
        if (priceID == null) {
            return 0;
        } else if (priceID === this.STRIPE_PRICE_TEMP_HUMIDITY_WIFI) {
            // $150 total
            // $75 JAS fee
            return 75 * 100;
        } else if (priceID === this.STRIPE_PRICE_TEMP_WIFI) {
            // $150 total
            // $65 JAS fee
            return 65 * 100;
        } else if (priceID === this.STRIPE_PRICE_CO2_WIFI) {
            // $125 total
            // $60 JAS fee
            return 60 * 100;
        } else if (priceID === this.STRIPE_PRICE_PRESSURE_WIFI) {
            // $175 total
            // $100 JAS fee
            return 100 * 100;
        } else if (priceID === this.STRIPE_PRICE_STEFFES_CELLULAR) {
            // $750 total
            // $350 JAS fee
            return 350 * 100;
        }
        // Default to none
        //console.error("No JAS fee for unknown price ID: " + priceID);
        return 0;
    }

}
