import "./ThreeStepAsyncOpDialog.css";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton
} from "@mui/material";
import React from "react";
import {Close} from "@mui/icons-material";

// TSAO Dialog
export interface ThreeStepAsyncOpDialogProps<SelectDataType> {
    // General
    open: boolean; // Whether the dialog is open or not
    closeDialogFn: () => void; // So dialog can close itself
    title: string;

    // Selection step
    getSelectionComponent: (selectDataFn: (data: SelectDataType) => void) => JSX.Element;

    // Confirm step
    getConfirmDesc: (data: SelectDataType) => string; // Return "You are about to add user X to org Y"
    confirmText: string;
    performActionFn: (data: SelectDataType) => Promise<SelectDataType>;

    // Waiting step
    getSuccessDesc: (data: SelectDataType) => string; // Return "User X added to org Y"
    doneSuccessFn: (data: SelectDataType) => void;
    doneFailureFn: () => void;
}
interface ThreeStepAsyncOpDialogState<SelectedDataType> {
    step: number;
    selectedData: SelectedDataType | null;
    asyncOp: Promise<SelectedDataType> | null;
    // Once asyncOp has started
    done: boolean;
    success: boolean;
    failureMsg: string | null;
}
function ThreeStepAsyncOpDialog<DataType>(props: ThreeStepAsyncOpDialogProps<DataType>) {
    // Step index constants
    const STEP_SELECT_DATA: number = 0;
    const STEP_CONFIRM_SELECTION: number = 1;
    const STEP_WAIT_FOR_RESULT: number = 2;
    // State
    const [state, setState] = React.useState<ThreeStepAsyncOpDialogState<DataType>>({
        step: STEP_SELECT_DATA, selectedData: null, asyncOp: null,
        done: false, success: false, failureMsg: null
    });
    // Helper fns
    const exitFn = () => {
        // Close dialog
        props.closeDialogFn();
        // Reset state (after small delay)
        window.setTimeout(() => {
            setState({
                step: STEP_SELECT_DATA, selectedData: null, asyncOp: null,
                done: false, success: false, failureMsg: null
            });
        }, 1500);
    };
    const selectDataFn = (data: DataType) => {
        setState({
            step: STEP_CONFIRM_SELECTION, selectedData: data, asyncOp: null,
            done: false, success: false, failureMsg: null
        });
    };
    const cancelSelectionFn = () => {
        setState({
            step: STEP_SELECT_DATA, selectedData: null, asyncOp: null,
            done: false, success: false, failureMsg: null
        });
    };
    const confirmSelectionFn = () => {
        // Perform the operation (update state on completion)
        const asyncOperation = props.performActionFn(state.selectedData!);
        setState({
            step: STEP_WAIT_FOR_RESULT, selectedData: state.selectedData, asyncOp: asyncOperation,
            done: false, success: false, failureMsg: null
        });
        // Handle completion
        asyncOperation.then((data) => {
            setState({
                step: STEP_WAIT_FOR_RESULT, selectedData: state.selectedData, asyncOp: state.asyncOp,
                done: true, success: true, failureMsg: null
            });
        }).catch((err) => {
            setState({
                step: STEP_WAIT_FOR_RESULT, selectedData: state.selectedData, asyncOp: state.asyncOp,
                done: true, success: false, failureMsg: err as string
            });
        });
    }
    const allDoneFn = (success: boolean) => {
        // Close dialog
        exitFn();
        // Perform final action
        if (success) {
            props.doneSuccessFn(state.selectedData!);
        } else {
            props.doneFailureFn();
        }
    };
    return (
        <Dialog open={props.open} className={"tsao-root"}>

            {/* Header (title & close) */}
            <DialogTitle>
                <div className={"header-row"}>
                    <div className={"header-side-div"}/>
                    <span className={"title"}>{props.title}</span>
                    {/* Do not allow exit while op is in progress */}
                    {
                        (state.step === STEP_WAIT_FOR_RESULT)
                            ? <div className={"header-side-div"}/>
                            : <IconButton className={"header-side-div"} onClick={exitFn}>
                                <Close fontSize={"medium"}/>
                            </IconButton>
                    }
                </div>
            </DialogTitle>

            {/* Content */}
            <DialogContent className={"tsao-content-div"}>
                {/* Data selection step */}
                {
                    (state.step === STEP_SELECT_DATA) &&
                    props.getSelectionComponent((data: DataType) => selectDataFn(data))
                }
                {/* Confirm selection step */}
                {
                    (state.step === STEP_CONFIRM_SELECTION) &&
                    <div className={"desc-div"}>
                        <p>{props.getConfirmDesc(state.selectedData!)}</p>
                    </div>
                }
                {/* Async op step */}
                {
                    (state.step === STEP_WAIT_FOR_RESULT) &&
                    <React.Fragment>
                        {/* Loading icon */}
                        {
                            (!state.done) &&
                            <div className={"desc-div"}>
                                <CircularProgress size={"50px"} color="primary"/>
                            </div>
                        }
                        {/* Completion message */}
                        {
                            (state.done) &&
                            <div className={"desc-div"}>
                                <p className={(state.success) ? "desc-success" : "desc-failure"}>{(state.success) ? props.getSuccessDesc(state.selectedData!) : state.failureMsg}</p>
                            </div>
                        }
                    </React.Fragment>
                }
            </DialogContent>

            {/* Actions */}
            <DialogActions className={"confirm-page-actions"}>
                {/* Confirm step buttons */}
                {
                    (state.step === STEP_CONFIRM_SELECTION)
                    &&
                    <React.Fragment>
                        <Button className={"action-btn"} color={"secondary"} onClick={cancelSelectionFn}>
                            <strong>Cancel</strong>
                        </Button>
                        <Button className={"action-btn"} color={"primary"} onClick={confirmSelectionFn}>
                            <strong>{props.confirmText}</strong>
                        </Button>
                    </React.Fragment>
                }
                {/* Waiting step buttons */}
                {
                    (state.step === STEP_WAIT_FOR_RESULT && state.done)
                    &&
                    <Button className={"action-btn"} onClick={() => allDoneFn(state.success)}>
                        <strong>Okay</strong>
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default ThreeStepAsyncOpDialog;