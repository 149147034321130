import ConfirmAsyncOpDialog from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialog";
import {MC_User} from "../../../iot/MC_User";
import {MC_Organization} from "../../../iot/MC_Organization";
import {MC_Backend} from "../../../MC_Backend";
import {ConfirmAsyncOpDialogBaseProps} from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialogBaseProps";

export interface RemoveUserFromOrgDialogProps extends ConfirmAsyncOpDialogBaseProps<void> {
    user: MC_User | null;
    org: MC_Organization | null;
}
function RemoveUserFromOrgDialog(props: RemoveUserFromOrgDialogProps) {
    // Handle malformed case
    if (props.user == null || props.org == null) {
        return null;//<p className={"error-note"}>Remove user dialog error.</p>
    }
    // Data
    const desc: string = "You are about to remove '" + props.user.name + "' from organization '" + props.org.name + "'.";
    const successMsg: string = "User '" + props.user.name + "' has been removed from organization '" + props.org.name + "'.";
    // Helper fn
    const performAction = () => {
        return MC_Backend.getInstance().removeUserFromOrg(props.user!, props.org!);
    };
    // UI
    return <ConfirmAsyncOpDialog<void>
        open={props.open} closeDialogFn={props.closeDialogFn}
        title={"Remove User?"} desc={desc}
        confirmText={"Remove"} confirmTextRed={true}
        successMsg={successMsg}
        performAction={performAction}
        doneSuccessFn={props.doneSuccessFn}
        doneFailureFn={props.doneFailureFn}
    />;
}

export default RemoveUserFromOrgDialog;