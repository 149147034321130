import {MC_User} from "../../../iot/MC_User";
import {UsersContext} from "../../../../App";
import React from "react";
import MCLoadingBar from "../../misc/MCLoadingBar";
import {MC_Backend} from "../../../MC_Backend";
import UserItem from "../UserItem";

export interface UserItemListProps {
    userIDs: string[];
    selectAction?: (u: MC_User) => void; // Select user in dialog
    removeAction?: (u: MC_User) => void; // Remove from org
}

function UserItemList(props: UserItemListProps) {
    // Load users if needed
    let ctx = React.useContext(UsersContext);
    React.useEffect(() => {
        if (ctx.users == null && !ctx.loading && ctx.errMsg == null) {
            MC_Backend.getInstance().loadUsers().finally();
        }
    }, [ctx]);
    // UI
    return (
        <UsersContext.Consumer>
            {(usersCtx) => {
                // Parse display users
                let allUserList: MC_User[] | null = usersCtx.users;
                let userDisplayList = (allUserList != null)
                    ? allUserList.filter((u) => props.userIDs.includes(u.uid))
                    : []
                ;

                // If the users are loaded without error, check for user ID mismatch errors
                let errMsg: string | null = usersCtx.errMsg;
                if (allUserList != null && errMsg == null && userDisplayList.length !== props.userIDs.length) {
                    errMsg = "";
                    // Collect user IDs that weren't found
                    for (let i = 0; i < props.userIDs.length; i++) {
                        let found: boolean = (userDisplayList.filter((u) => u.uid === props.userIDs[i]).length > 0);
                        if (!found) {
                            errMsg += ((errMsg.length > 0) ? ", " : "") + props.userIDs[i];
                        }
                    }
                    errMsg = "Missing user IDs: " + errMsg;
                }

                // UI
                return (
                    <div className={"item-list-root"}>

                        {/* Loading bar */}
                        <MCLoadingBar loadingMessage={"Loading Users..."}
                                      loading={usersCtx.loading}
                                      errorMessage={errMsg}
                        />

                        {/* Warn for no users */}
                        {
                            (allUserList != null && userDisplayList.length === 0)
                            &&
                            <p className={"bad"}>No users found.</p>
                        }

                        {/* Display users */}
                        {userDisplayList.map((u) =>
                            <UserItem
                                key={u.uid} user={u} nameLink={true}
                                showPhone={true}
                                selectAction={props.selectAction} removeAction={props.removeAction}
                            />
                        )}

                    </div>
                );
            }}
        </UsersContext.Consumer>
    );
}

export default UserItemList;