import "./ViewUser.css";
import "../../../common/ui/view-section/ViewPaper.css";
import {useNavigate, useParams} from "react-router-dom";
import {MC_User} from "../../../common/iot/MC_User";
import {Box, Tab} from "@mui/material";
import {MC_Backend} from "../../../common/MC_Backend";
import GeneralHeader from "../../../common/ui/GeneralHeader";
import React, {useContext} from "react";
import MCLoadingBar from "../../../common/ui/misc/MCLoadingBar";
import ViewOrganizations from "../../../common/ui/view-section/ViewOrganizations";
import ViewActivityLog from "../../../common/ui/view-section/ViewActivityLog";
import {MC_Action_Log} from "../../../common/iot/MC_Action_Log";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import AddOrgToUserDialog from "../../../common/ui/dialog/AddDialog/AddOrgToUserDialog";
import RemoveUserFromOrgDialog from "../../../common/ui/dialog/RemoveDialog/RemoveUserFromOrgDialog";
import {MC_Organization} from "../../../common/iot/MC_Organization";
import DeleteUserDialog from "../../../common/ui/dialog/DeleteDialog/DeleteUserDialog";
import {UsersContext} from "../../../App";
import UserInfoTab from "./UserInfoTab";

interface ViewUserState {
    refreshing: boolean;
    errMsg: string | null;
}
interface RemoveOrgState {
    open: boolean;
    org: MC_Organization | null;
}
function ViewUser(props: any) {
    const TAB_INFO: string = "info";
    const TAB_ORGS: string = "organizations";
    const TAB_ACTIVITY_LOG: string = "activity_log";
    const ctx = useContext(UsersContext);
    const nav = useNavigate();
    // View user state
    const [curState, setCurState] = React.useState<ViewUserState>({refreshing: false, errMsg: null});
    const [deleteUserOpen, setDeleteUserOpen] = React.useState<boolean>(false);
    const [addOrgDialogOpen, setAddOrgDialogOpen] = React.useState<boolean>(false);
    const [removeOrgState, setRemoveOrgState] = React.useState<RemoveOrgState>({open: false, org: null});
    const [tabValue, setTabValue] = React.useState<string>(TAB_INFO);

    // Load users if they are not loading already
    React.useEffect(() => {
        if (ctx.users == null && !ctx.loading && ctx.errMsg == null) {
            const backend = MC_Backend.getInstance();
            backend.loadUsers().finally();
        }
    }, [ctx.loading, ctx.users, ctx.errMsg]);

    // Parse UID
    const {uid} = useParams();
    const uidStr: string | null = (uid != null) ? uid as string : null;
    if (uidStr == null) {
        return (<p className={"bad"}>Missing UID</p>);
    }

    // Helper fns
    const goBackFn = () => nav("/core/users");
    const refreshUserFn = () => {
        if (!curState.refreshing) {
            setCurState({refreshing: true, errMsg: null});
            MC_Backend.getInstance().loadUser(uidStr, true)
                .then(() => setCurState({refreshing: false, errMsg: null}))
                .catch((e) => setCurState({refreshing: false, errMsg: e as string}))
            ;
            // Also logs if needed
            if (tabValue === TAB_ACTIVITY_LOG) {
                MC_Backend.getInstance().loadOverview().finally();
            }
        }
    };

    // UI
    return (
        <UsersContext.Consumer>
            {
                (usersCtx) => {
                    // Parse user by UID
                    const expectingNonNullUser = (usersCtx.users != null);
                    const user: MC_User | null = MC_Backend.getInstance().findUserByID((uidStr));
                    // Helper vars
                    const isLoading = curState.refreshing || usersCtx.loading;
                    const loadingError: string | null = (curState.errMsg != null) ? curState.errMsg : usersCtx.errMsg;
                    // UI
                    return (
                        <div className={"view-user-root"}>

                            {/* Dialogs */}
                            <React.Fragment>
                                {/* Delete user dialog */}
                                <DeleteUserDialog
                                    open={deleteUserOpen}
                                    user={user}
                                    closeDialogFn={() => setDeleteUserOpen(false)}
                                    doneSuccessFn={goBackFn}
                                    doneFailureFn={() => {}}
                                />
                                {/* Add org to user dialog */}
                                <AddOrgToUserDialog
                                    open={addOrgDialogOpen}
                                    user={user!}
                                    closeDialogFn={() => setAddOrgDialogOpen(false)}
                                    doneSuccessFn={refreshUserFn}
                                    doneFailureFn={() => {}}
                                />
                                {/* Remove user from org */}
                                <RemoveUserFromOrgDialog
                                    open={removeOrgState.open}
                                    user={user}
                                    org={removeOrgState.org}
                                    closeDialogFn={() => setRemoveOrgState({open: false, org: removeOrgState.org})}
                                    doneSuccessFn={refreshUserFn}
                                    doneFailureFn={() => {}}
                                />
                            </React.Fragment>

                            {/* Header */}
                            <GeneralHeader title={(user != null) ? user.email : "..."}
                                           icon={"user"}
                                           backAction={goBackFn}
                                           refreshAction={(user != null) ? refreshUserFn : () => {}}
                            />

                            {/* Refresh indicator */}
                            <MCLoadingBar loadingMessage={"Refreshing..."} loading={isLoading}  errorMessage={loadingError}/>

                            {/* Show error if there is no user, but there should be */}
                            {
                                (expectingNonNullUser && user == null) &&
                                <p className={"error-note"}>Could not find user with UID: {uidStr!}</p>
                            }

                            {/* Show profile if user is loaded */}
                            {
                                (user != null) &&
                                <React.Fragment>

                                    {/* User content */}
                                    <div className={"content-div"}>

                                        {/* Tab content */}
                                        <TabContext value={tabValue}>

                                            {/* Tab header */}
                                            <Box className={"tablistbox"}>
                                                <TabList
                                                    variant={"scrollable"} allowScrollButtonsMobile={true}
                                                    value={tabValue} textColor={"primary"}
                                                    onChange={(e, v: string) => setTabValue(v)}>
                                                    <Tab value={TAB_INFO} label="Info" />
                                                    <Tab value={TAB_ORGS} label="Organizations" />
                                                    <Tab value={TAB_ACTIVITY_LOG} label="Activity Log" />
                                                </TabList>
                                            </Box>

                                            {/* Info tab */}
                                            <TabPanel value={TAB_INFO} tabIndex={0}>
                                                <UserInfoTab user={user} setDeleteUserOpen={setDeleteUserOpen}/>
                                            </TabPanel>

                                            {/* Organizations tab */}
                                            <TabPanel value={TAB_ORGS} tabIndex={1}>
                                                <ViewOrganizations organizationIDs={user.organizationIDs}
                                                                   desc={(user.enabled)
                                                                       ? "This user has access to the organizations listed below:"
                                                                       : "This user is disabled, and currently has no access."}
                                                                   addAction={() => setAddOrgDialogOpen(true)}
                                                                   removeAction={(o) => setRemoveOrgState({open: true, org: o})}
                                                />
                                            </TabPanel>

                                            {/* Activity log */}
                                            <TabPanel value={TAB_ACTIVITY_LOG} tabIndex={2}>
                                                <ViewActivityLog
                                                    pageSize={10}
                                                    title={"Activity Log"}
                                                    desc={"Here is recent activity relating to this user."}
                                                    logFilterFn={(x: MC_Action_Log) => x.user_ids.includes(user!.uid)}
                                                />
                                            </TabPanel>

                                        </TabContext>

                                    </div>

                                </React.Fragment>
                            }

                            <br/><br/><br/>

                        </div>
                    );
                }
            }
        </UsersContext.Consumer>
    );
}

export default ViewUser;