import "./IssueInfoTab.css";
import {MC_Issue} from "../../../common/iot/MC_Issue";
import {Button, Paper} from "@mui/material";
import React from "react";
import UserContactProfile from "../../../common/ui/misc/UserContactProfile";
import UserItemList from "../../../common/ui/item-list/list/UserItemList";
import {MC_Constants} from "../../../common/MC_Constants";
import {MC_User} from "../../../common/iot/MC_User";
import {MC_Backend} from "../../../common/MC_Backend";
import MCFullSnackBar from "../../../common/ui/misc/MCFullSnackBar";
import AddAdminToIssueDialog from "../../../common/ui/dialog/AddDialog/AddAdminToIssueDialog";
import RemoveAdminFromIssueDialog from "../../../common/ui/dialog/RemoveDialog/RemoveAdminFromIssueDialog";

export interface IssueInfoTabProps {
    issue: MC_Issue;
}
export function IssueInfoTab({issue}: IssueInfoTabProps) {
    // State
    const [addDialogOpen, setAddDialogOpen] = React.useState<boolean>(false);
    const [removeDialogOpen, setRemoveDialogOpen] = React.useState<boolean>(false);
    const [removeAdminUser, setRemoveAdminUser] = React.useState<MC_User | null>(null);
    const [progressSnackOpen, setProgressSnackOpen] = React.useState<boolean>(false);
    const [errorSnackOpen, setErrorSnackOpen] = React.useState<boolean>(false);
    const [successSnackOpen, setSuccessSnackOpen] = React.useState<boolean>(false);
    const [opInProgress, setOpInProgress] = React.useState<boolean>(false);

    // Values
    const createdStr = MC_Constants.getReadableDateStr(issue.created);
    // Functions
    const addSupportMemberFn = () => {
        setAddDialogOpen(true);
    };
    const removeSupportMemberFn = (u: MC_User) => {
        setRemoveAdminUser(u);
        setRemoveDialogOpen(true);
    };
    const toggleIssueOpenFn = () => {
        if (!opInProgress) {
            setOpInProgress(true);
            setProgressSnackOpen(true);
            MC_Backend.getInstance().setIssueOpenState(issue, !issue.open)
                .then((x) => {
                    setSuccessSnackOpen(true);
                })
                .catch((e) => {
                    console.error("Update err: " + JSON.stringify(e));
                    setErrorSnackOpen(true);
                })
                .finally(() => {
                    setOpInProgress(false);
                })
            ;
        }
    };
    // UI
    return (
        <Paper className={"issue-info-root"} elevation={3}>

            {/* Dialogs */}
            <React.Fragment>
                <AddAdminToIssueDialog
                    issue={issue}
                    open={addDialogOpen}
                    closeDialogFn={() => setAddDialogOpen(false)}
                    doneSuccessFn={() => {}}
                    doneFailureFn={() => {}}
                />
                <RemoveAdminFromIssueDialog
                    issue={issue}
                    adminUser={removeAdminUser}
                    open={removeDialogOpen}
                    closeDialogFn={() => setRemoveDialogOpen(false)}
                    doneSuccessFn={() => {}}
                    doneFailureFn={() => {}}
                />
            </React.Fragment>

            {/* Snack bar */}
            <MCFullSnackBar
                infoProps={{open: progressSnackOpen, setOpen: setProgressSnackOpen, message: "Updating..."}}
                errorProps={{open: errorSnackOpen, setOpen: setErrorSnackOpen, message: "Failed to update."}}
                successProps={{open: successSnackOpen, setOpen: setSuccessSnackOpen, message: "Updated!"}}
            />

            {/* Issue subj & message */}
            <div className={"ii-subsection"}>
                <span className={"ii-subtitle"}>Details</span>
                <span className={"issue-detail"}>
                    <span className={"note"}>Subject: </span>
                    {issue.subject}
                </span>
                <span className={"issue-detail"}>
                    <span className={"note"}>Message: </span>
                    {issue.message}
                </span>
                <br/>
                <span className={"issue-detail note"}>
                    Created on {createdStr}
                </span>
            </div>

            <div className={"ii-divider"}/>

            {/* User info */}
            <div className={"ii-subsection"}>
                <span className={"ii-subtitle"}>User</span>
                <UserContactProfile uid={issue.uid}/>
            </div>

            <div className={"ii-divider"}/>

            {/* Support admins */}
            <div className={"ii-subsection"}>
                <span className={"ii-subtitle"}>Support Members</span>
                <UserItemList userIDs={issue.help_uids} removeAction={removeSupportMemberFn}/>
                <br/>
                <div className={"ii-actions"}>
                    <Button variant="contained" color={"success"}
                            onClick={() => addSupportMemberFn()}>
                        Add Support Member
                    </Button>
                </div>
            </div>

            <div className={"ii-divider"}/>

            {/* Actions */}
            <div className={"ii-actions"}>
                <p className={"note"}>
                    {(issue.open)
                        ? "Close this issue if it has been resolved."
                        : "Reopen this issue if it requires further attention."
                    }
                </p>
                <Button variant="contained" color={(issue.open) ? "primary" : "warning"}
                        onClick={() => toggleIssueOpenFn()}>
                    {(issue.open) ? "Close Issue" : "Reopen Issue"}
                </Button>
            </div>

        </Paper>
    );
}

export default IssueInfoTab;