import "./ItemList.css";
import {RecentAlertsContext} from "../../../../App";
import MCLoadingBar from "../../misc/MCLoadingBar";
import React from "react";
import {MC_Backend} from "../../../MC_Backend";
import {MC_Recent_Alert} from "../../../iot/MC_Recent_Alert";
import RecentAlertItem from "../RecentAlertItem";

export interface RecentAlertItemListProps {
    deviceID: string;
}
function RecentAlertItemList(props: RecentAlertItemListProps) {
    // Load alerts if needed
    let ctx = React.useContext(RecentAlertsContext);
    React.useEffect(() => {
        if (ctx.recentAlerts == null && !ctx.loading && ctx.errMsg == null) {
            MC_Backend.getInstance().loadRecentAlerts().finally();
        }
    }, [ctx]);
    // UI
    return (
        <RecentAlertsContext.Consumer>
            {(raCtx) => {
                // Parse display alerts
                const allAlerts: MC_Recent_Alert[] | null = raCtx.recentAlerts;
                let alertDisplayList = (allAlerts != null)
                    ? allAlerts.filter((a) => props.deviceID === a.deviceID)
                    : []
                ;
                // Sort by timestamp
                alertDisplayList = alertDisplayList.sort((a, b) => {
                   return (b.recorded.getTime() - a.recorded.getTime());
                });
                // UI
                return (
                    <div className={"ra-list-root"}>

                        {/* Loading bar */}
                        <MCLoadingBar loadingMessage={"Loading Recent Alerts..."}
                                      loading={raCtx.loading}
                                      errorMessage={raCtx.errMsg}
                        />

                        {/* Indicate for no alerts */}
                        {
                            (allAlerts != null && alertDisplayList.length === 0)
                            &&
                            <p className={"good"}>No alerts found.</p>
                        }

                        {/* Display alerts */}
                        {alertDisplayList.map((a, idx) => <RecentAlertItem key={a.alertID + "" + idx} recentAlert={a}/>)}

                    </div>
                );
            }}
        </RecentAlertsContext.Consumer>
    );

}

export default RecentAlertItemList;