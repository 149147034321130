import "./PaginatedSelectItemList.css";
import React from "react";
import SelectListControl from "./SelectListControl";
import MCLoadingBar from "../../misc/MCLoadingBar";
import {Pagination} from "@mui/material";
import {MC_Device} from "../../../iot/MC_Device";

export interface PaginatedSelectItemListProps<DataType> {
    allowSearch: boolean;
    pageSize: number;
    fillPageWithNullItems: boolean; // If true, will fill page size with skeleton items
    loadDataFn: () => void; // Called if the data array is null
    dataArray: DataType[] | null; // The data to display in the list
    loading: boolean; // If the data is loading
    errMsg: string | null; // If the data has an error message
    emptyMessage: string; // Message to display if there are no items found
    sortFn: (a: DataType, b: DataType) => number; // Sort the elements displayed
    renderItem: (idx: number, data: DataType | null) => JSX.Element | null; // Render the item with JSX
    hideLoadingBar?: boolean; // True indicates the loading bar will never be shown
    isDevices?: boolean;
}
interface PaginatedSelectItemListState {
    page: number;
    search: string;
}
function PaginatedSelectItemList<DataType>(props: PaginatedSelectItemListProps<DataType>) {
    // State
    const [curState, setState] = React.useState<PaginatedSelectItemListState>({
        page: 1, // Page num in MUI <Pagination/> starts at 1, not 0 (Unlike MUI <TablePagination/>)
        search: ""
    });

    // Load data if needed
    React.useEffect(() => {
        if (props.dataArray == null && !props.loading && props.errMsg == null) {
            props.loadDataFn();
        }
    }, [props]);

    // UI
    // Filter data array based on search state
    let arr: DataType[] | null = (props.dataArray == null)
        ? []
        : props.dataArray.filter((d) => {
            if (curState.search.length > 0) {
                let dName: string = (d as any).name as string;
                let filterBool = (dName != null && (dName!.toLowerCase().indexOf(curState.search.toLowerCase()) !== -1));
                if (props.isDevices && !filterBool) {
                    let jasID = (d as any).jas_id;
                    filterBool = (jasID != null && (jasID.toLowerCase().indexOf(curState.search.toLowerCase()) !== -1));
                }
                return filterBool;
            }
            return true;
        })
    ;
    // Determine number of pages
    const pageCount: number = (arr == null)
        ? 1
        : Math.max(1, Math.ceil(arr!.length / props.pageSize))
    ;
    // Ensure current page is allowed
    React.useEffect(() => {
        if (curState.page > pageCount) {
            setState({page: 1, search: curState.search});
        }
    }, [curState, pageCount]);

    // Sort render items
    const allDataItemArray: DataType[] = (arr == null) ? [] : arr;
    allDataItemArray.sort(props.sortFn);

    // Filter items to render based on page
    const adjPageIndex: number = curState.page - 1; // Page num in MUI <Pagination/> starts at 1, not 0 (Unlike MUI <TablePagination/>)
    const renderDataItemArray: DataType[] = allDataItemArray.filter((d, i) => {
        // Index must be high enough to fit on current page, but too high to fit on the next page
        return ((i >= (adjPageIndex * props.pageSize)) && (i < ((adjPageIndex + 1) * props.pageSize)));
    });
    const blanksNeeded: number = props.pageSize - renderDataItemArray.length;
    const skeletonItemsArray: any[] = (blanksNeeded > 0)
        ? new Array(blanksNeeded).fill(null)
        : []
    ;

    // Determine if loading bar should be hidden
    const hideLoading = (props.hideLoadingBar != null) ? props.hideLoadingBar : false;

    // UI
    return (
        <div className={"pgsil-root"}>

            {/* Search header */}
            <div className={"pgsil-header"}>
                {/* Search filter (by ID */}
                {
                    (props.allowSearch) &&
                    <SelectListControl
                        search={curState.search}
                        updateSearch={(val) => setState({page: curState.page, search: val})}
                        isDevices={props.isDevices}
                    />
                }
                {/* Loading bar (and error) if applicable */}
                <MCLoadingBar key={"loading-bar"} loadingMessage={""} loading={!hideLoading && props.loading} errorMessage={props.errMsg}/>
            </div>

            {/* Content list */}
            <div className={"pgsil-content"}>
                {/* Empty message */}
                {(renderDataItemArray.length === 0) && <p key={"empty-msg"} className={"error-note"}>{props.emptyMessage}</p>}
                {/* Items */}
                {renderDataItemArray.map((d, idx) => props.renderItem(idx, d))}
                {/* Skeleton items to fix size */}
                {props.fillPageWithNullItems && skeletonItemsArray.map((x, idx) => props.renderItem(idx, null))}
            </div>

            {/* Pagination footer */}
            <div className={"pgsil-footer"}>
                <Pagination
                    key={"pagination"}
                    variant="outlined" color="primary"
                    count={pageCount} page={curState.page}
                    onChange={(event: React.ChangeEvent<unknown>, page: number) => setState({page: page, search: curState.search})}
                />
            </div>

        </div>
    );
}

export default PaginatedSelectItemList;

