import CreateOpDialog from "./CreateOpDialog";
import {CreateDialogBaseProps} from "./CreateDialogBaseProps";
import {MC_Device} from "../../../iot/MC_Device";
import {MC_Create_Device_Interface} from "../../../iot/MC_Create_Interface";
import {MC_Backend} from "../../../MC_Backend";
import {useNavigate} from "react-router-dom";
import CreateDeviceComponent from "./CreateDeviceComponent";

export interface CreateDeviceDialogProps extends CreateDialogBaseProps {}
function CreateDeviceDialog(props: CreateDeviceDialogProps) {
    const nav = useNavigate();
    // Config component
    const getConfigComponent = (
        existingConfig: MC_Create_Device_Interface | null,
        selectConfig: (config: MC_Create_Device_Interface) => void
    ) => {
        return <CreateDeviceComponent existingConfig={existingConfig} selectConfig={selectConfig}/>;
    };
    // Helper fns
    const getConfirmDesc = (config: MC_Create_Device_Interface) => {
        return "You are about to create a " + config.full_type + " named '" + config.name + "'.";
    };
    const performAction = async (config: MC_Create_Device_Interface): Promise<MC_Device> => {
        return await MC_Backend.getInstance().createDevice(config);
    };
    const getSuccessDesc = (d: MC_Device) => {
        return "Successfully created '" + d.name + "' (" + d.fullType + ").";
    };
    const navToNewDevice = (newDevice: MC_Device) => {
        nav("/core/devices/" + newDevice.id);
    }
    // UI
    return <CreateOpDialog<MC_Create_Device_Interface, MC_Device>
        open={props.open}
        closeDialogFn={props.closeDialogFn}
        title={"Create Device"}
        getConfigComponent={getConfigComponent}
        getConfirmDesc={getConfirmDesc}
        performActionFn={performAction}
        getSuccessDesc={getSuccessDesc}
        doneSuccessFn={navToNewDevice}
        doneFailureFn={() => {}}
    />;
}

export default CreateDeviceDialog;