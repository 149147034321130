import "./MCLoadingBar.css";
import {Box, LinearProgress} from "@mui/material";
import React from "react";

// The MC loading bar will only show when it has details to show

export interface MCLoadingBarProps {
    loading: boolean;
    loadingMessage: string;
    errorMessage: string | null;
}
function MCLoadingBar(props: MCLoadingBarProps) {
    return (
        <div className={"mclb-root"}>
            {/* Optional loading message */}
            {
                (props.loading && props.loadingMessage.length > 0)
                    ? <React.Fragment><span className={"note"}>{props.loadingMessage}</span><br/></React.Fragment>
                    : null
            }

            {/* Optional loading bar */}
            {
                (props.loading)
                    ? <Box sx={{ width: '90%' }}><LinearProgress/></Box>
                    : null
            }

            {/* Error message */}
            {
                (props.errorMessage != null)
                    ? <p className={"error-note"}>{props.errorMessage}</p>
                    : null
            }
            {/* Spacing between loading bar/err msg if needed*/}
            {
                (props.loading || props.errorMessage != null)
                &&
                <br/>
            }

        </div>
    );
}

export default MCLoadingBar;