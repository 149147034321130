import "./UsersTable.css"
import {MC_User} from "../../iot/MC_User";
import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow, Tooltip
} from "@mui/material";
import {Link} from "react-router-dom";
import React, {CSSProperties} from "react";

const alignVal: "left" | "center" | "right" = "left";

const linkStyle: CSSProperties = {
    color: "var(--MC_MAIN)",
    textDecorationColor: "var(--MC_MAIN)"
};
function userToRow(user: MC_User) {
    let uidStr: string = (user.uid.length > 5)
        ? "..." + user.uid.substr(user.uid.length - 5)
        : user.uid
    ;
    const isEnabled: boolean = user.enabled;
    const isAdmin: boolean = user.admin;
    const statusStr: string = (isEnabled)
        ? (isAdmin) ? "ADMIN" : "Enabled"
        : "DISABLED"
    ;
    const statusColorClass: string = (isEnabled)
        ? (isAdmin) ? "admin" : "good"
        : "bad"
    ;
    const statusTooltip: string = (isEnabled)
        ? (isAdmin) ? "This user has full access" : "This user has normal access"
        : "This user is NOT allowed to log in"
    ;
    // UI
    return (
        <TableRow key={user.uid} hover={true}>
            <TableCell align={alignVal}>
                <span className={"id-span"}>
                    {uidStr}
                </span>
            </TableCell>
            <TableCell align={alignVal}>
                <span className={"email-span"}>
                    <Link to={"/core/users/" + user.uid} style={linkStyle}>
                        <strong>{user.email}</strong>
                    </Link>
                </span>
            </TableCell>
            <TableCell align={alignVal}>
                <span className={"name-span"}>
                    {user.name}
                </span>
            </TableCell>
            <TableCell align={alignVal}>
                <span className={"phone-span"}>
                    {user.phone}
                </span>
            </TableCell>
            <TableCell align={alignVal}>
                <Tooltip title={statusTooltip} placement={"top"}>
                    <strong className={statusColorClass}>
                        <span className={"status-span"}>
                            {statusStr}
                        </span>
                    </strong>
                </Tooltip>
            </TableCell>
            <TableCell align={alignVal}>
                <span className={"last-activity-span"}>
                    {user.getLastActivityStr()}
                </span>
            </TableCell>
        </TableRow>
    );
}

export interface UsersProps {
    users: MC_User[];
    tableCtl?: JSX.Element;
}
function UsersTable({users, tableCtl}: UsersProps) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // Handle pagination changes
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div className={"table-div"}>
            <TableContainer component={Paper} elevation={6}>

                {/* Optional table control header */}
                {(tableCtl != null) && tableCtl}

                <Divider sx={{width: "100%"}}/>

                <Table>
                    {/* Header with column names */}
                    <TableHead>
                        <TableRow>
                            <TableCell align={alignVal}><strong>ID</strong></TableCell>
                            <TableCell align={alignVal}><strong>Email</strong></TableCell>
                            <TableCell align={alignVal}><strong>Name</strong></TableCell>
                            <TableCell align={alignVal}><strong>Phone</strong></TableCell>
                            <TableCell align={alignVal}><strong>Status</strong></TableCell>
                            <TableCell align={alignVal}><strong>Last Seen</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    {/* Table cell for each user */}
                    <TableBody>
                        {
                            users.map((u, idx) => {
                                let shouldDisplay = (idx >= (page * rowsPerPage)) && (idx < ((page + 1) * rowsPerPage));
                                if (shouldDisplay) {
                                    return userToRow(u);
                                }
                                return null;
                            })
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {/* Pagination control */}
                            <TablePagination
                                count={users.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[10, 25, 50]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}

export default UsersTable;
