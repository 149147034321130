import {alpha, styled} from "@mui/material/styles";
import {InputBase} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

const searchColor: string = "#9b9b9b";
const Search = styled("div")(({theme}) => ({
    position: 'relative',
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(searchColor, 0.15),
    '&:hover': {
        backgroundColor: alpha(searchColor, 0.25),
    },
    marginLeft: 0,
    width: '100%'
    /*
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },*/
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    flexGrow: 1,
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        /*
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },*/
    },
}));

export interface SelectListControlProps {
    search: string;
    updateSearch: (newSearch: string) => void;
    isDevices?: boolean;
}
function SelectListControl(props: SelectListControlProps) {
    const handleSearchKeyChange = (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => props.updateSearch(ev.target.value);
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder={(props.isDevices) ? "Search by name or serial #..." : "Search by name..."}
                value={props.search}
                onChange={handleSearchKeyChange}
            />
        </Search>
    );
}

export default SelectListControl;
