import "./Item.css";
import {Avatar, Paper, Skeleton} from "@mui/material";
import {Link} from "react-router-dom";
import React, {CSSProperties} from "react";
import {MC_Action_Log} from "../../iot/MC_Action_Log";
import {MC_UI_Helper} from "../../iot/MC_UI_Helper";
import {MC_Constants} from "../../MC_Constants";
import {CustomConstants} from "../../../custom/CustomConstants";
import {MC_Action_Log_Categories} from "../../iot/MC_Action_Log_Categories";

const linkStyle: CSSProperties = {
    color: "var(--MC_MAIN)",
    textDecorationColor: "var(--MC_MAIN)",
};
function ActivityLogItem(props: {log: MC_Action_Log | null}) {
    // Parse Device/Org/Alert to extract links & shit
    let log: MC_Action_Log | null = props.log;
    const isUserServer: boolean = (log != null)
        ? (CustomConstants.SERVER_UIDS.includes(log.user_id))
        : false
    ;
    return (
        <Paper className={"item-root item-row-root"} elevation={4}>

            {/* Icon column */}
            <div className={"item-icon-col log-icon-col"}>
                {/* Skeleton */}
                {(log == null) &&
                    <Skeleton animation={false} variant="circular" sx={{ fontSize: 'large' }}>
                        <Avatar/>
                    </Skeleton>
                }
                {/* Icon */}
                {(log != null) && MC_Action_Log_Categories.getIcon(log.type, "large")}
            </div>

            {/* Content column */}
            <div className={"log-content-col"}>

                {/* Description */}
                <div className={"log-content-desc"}>
                    <span className={"note"}>
                        {/* Skeleton */}
                        {(log == null) && <Skeleton className={"skel-text"} animation={false} variant="text"/>}
                        {/* Skeleton */}
                        {(log != null) && MC_UI_Helper.getLinkedTextForLog(log, "log-content-desc")}
                    </span>
                </div>

                {/* Footer */}
                <div className={"log-content-footer"}>
                    {/* User who performed the action */}
                    <span className={"log-footer-text note"}>
                        {/* Skeleton */}
                        {(log == null) && <Skeleton className={"skel-text"} animation={false} variant="text"/>}
                        {
                            (log != null) &&
                            <React.Fragment>
                                Performed by
                                {
                                    (isUserServer) &&
                                    <span className={"link-margin"}>{log.user_name}</span>
                                }
                                {
                                    (!isUserServer) &&
                                    <Link to={"/core/users/" + log.user_id} style={linkStyle}>
                                        <span className={"link-margin"}><strong>{log.user_name}</strong></span>
                                    </Link>
                                }
                                at {MC_Constants.getReadableDateStr(log.timestamp, true)}
                            </React.Fragment>
                        }
                    </span>
                </div>

            </div>

        </Paper>
    );
}

export default ActivityLogItem;
