import React, {useContext} from "react";
import {IssuesContext} from "../../../App";
import {MC_Backend} from "../../../common/MC_Backend";
import {useNavigate, useParams} from "react-router-dom";
import GeneralHeader from "../../../common/ui/GeneralHeader";
import MCLoadingBar from "../../../common/ui/misc/MCLoadingBar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Tab} from "@mui/material";
import ViewActivityLog from "../../../common/ui/view-section/ViewActivityLog";
import {MC_Action_Log} from "../../../common/iot/MC_Action_Log";
import {MC_Issue} from "../../../common/iot/MC_Issue";
import IssueInfoTab from "./IssueInfoTab";

interface ViewIssueState {
    refreshing: boolean;
    errMsg: string | null;
}
export function ViewIssue(props: any) {

    // merge pre-loaded users code from support tab,

    const TAB_INFO: string = "info";
    const TAB_ACTIVITY_LOG: string = "activity_log";
    const nav = useNavigate();
    const initialCtx = useContext(IssuesContext);
    const {issueID} = useParams();
    const issueIDStr: string | null = (issueID != null) ? issueID as string : null;

    // State
    const [curState, setCurState] = React.useState<ViewIssueState>({refreshing: false, errMsg: null});
    const [tabValue, setTabValue] = React.useState<string>(TAB_INFO);

    // Helper fns
    const refreshIssueFn = () => {
        if (!curState.refreshing && issueIDStr != null) {
            setCurState({refreshing: true, errMsg: null});
            MC_Backend.getInstance().loadIssue(issueIDStr)
                .then(() => setCurState({refreshing: false, errMsg: null}))
                .catch((e) => setCurState({refreshing: false, errMsg: e as string}))
            ;
            // Also load overview (logs) if that is the tab
            if (tabValue === TAB_ACTIVITY_LOG) {
                MC_Backend.getInstance().loadOverview().finally();
            }
        }
    };
    const goBackFn = () => nav("/core/support");

    // Load issues if they are not loading already
    React.useEffect(() => {
        if (initialCtx.issues == null && !initialCtx.loading && initialCtx.errMsg == null) {
            MC_Backend.getInstance().loadIssues().finally();
        }
    }, [initialCtx.issues, initialCtx.loading, initialCtx.errMsg]);

    // Ensure valid issue ID
    if (issueIDStr == null) {
        return (<p className={"bad"}>Missing Issue ID</p>);
    }

    // UI
    return (
        <IssuesContext.Consumer>
            {(curCtx) => {
                const backend = MC_Backend.getInstance();
                // Parse issue by ID
                const expectingNonNullIssue: boolean = (curCtx.issues != null);
                const issue: MC_Issue | null = backend.findIssueByID(issueIDStr);

                // Helper vars
                const isLoading = curState.refreshing || curCtx.loading;
                const loadingError: string | null = (curState.errMsg != null) ? curState.errMsg : curCtx.errMsg;

                // Determine icon color
                let iconColor: "green" | "red" | "orange" = "red";
                if (issue != null) {
                    if (issue.open) {
                        if (issue.help_uids.length === 0) {
                            iconColor = "red";
                        } else {
                            iconColor = "orange";
                        }
                    } else {
                        iconColor = "green";
                    }
                }

                // Helper fns

                // UI
                return (
                    <div className={"view-issue-root"}>

                        {/* Dialogs */}
                        <React.Fragment>

                        </React.Fragment>

                        {/* Header */}
                        <GeneralHeader
                            title={"Support Request"}
                            icon={"issue"}
                            iconColor={iconColor}
                            backAction={goBackFn}
                            refreshAction={(issue != null) ? refreshIssueFn : () => {}}
                        />

                        {/* Refresh indicator */}
                        <MCLoadingBar loadingMessage={"Refreshing..."} loading={isLoading} errorMessage={loadingError}/>

                        {/* Show error if there is no device, but there should be */}
                        {
                            (expectingNonNullIssue && issue == null) &&
                            <p className={"error-note"}>Could not find issue with ID: {issueIDStr}</p>
                        }

                        {/* Show issue if loaded */}
                        {
                            (issue != null && curCtx.refreshTS != null) &&
                            <React.Fragment>

                                <div className={"content-div"}>

                                    {/* Tab content */}
                                    <TabContext value={tabValue}>

                                        {/* Tab header */}
                                        <Box className={"tablistbox"}>
                                            <TabList
                                                variant={"scrollable"} allowScrollButtonsMobile={true}
                                                value={tabValue} textColor={"primary"}
                                                onChange={(e, v: string) => setTabValue(v)}>
                                                <Tab value={TAB_INFO} label="Info" />
                                                <Tab value={TAB_ACTIVITY_LOG} label="Activity Log" />
                                            </TabList>
                                        </Box>

                                        {/* Info tab */}
                                        <TabPanel value={TAB_INFO} tabIndex={0}>
                                            <IssueInfoTab issue={issue}/>
                                        </TabPanel>

                                        {/* Activity log */}
                                        <TabPanel value={TAB_ACTIVITY_LOG} tabIndex={3}>
                                            <ViewActivityLog
                                                pageSize={10}
                                                title={"Activity Log"}
                                                desc={"Here is the recent activity relating to this issue."}
                                                logFilterFn={(x: MC_Action_Log) => x.issue_ids.includes(issue.id)}
                                            />
                                        </TabPanel>

                                    </TabContext>

                                </div>

                                <br/><br/><br/>

                            </React.Fragment>
                        }

                    </div>
                );
            }}
        </IssuesContext.Consumer>
    );
}

export default ViewIssue;