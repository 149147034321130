import {ConfirmAsyncOpDialogBaseProps} from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialogBaseProps";
import {MC_Device} from "../../../iot/MC_Device";
import {MC_Organization} from "../../../iot/MC_Organization";
import ConfirmAsyncOpDialog from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialog";
import {MC_Backend} from "../../../MC_Backend";

export interface RemoveDeviceFromOrgDialogProps extends ConfirmAsyncOpDialogBaseProps<void> {
    device: MC_Device | null;
    org: MC_Organization | null;
}
function RemoveDeviceFromOrgDialog(props: RemoveDeviceFromOrgDialogProps) {
    // Handle malformed case
    if (props.device == null || props.org == null) {
        return null;//<p className={"error-note"}>Remove device dialog error.</p>
    }
    // Data
    const desc: string = "You are about to remove '" + props.device.name + "' from organization '" + props.org.name + "'.";
    const successMsg: string = "Device '" + props.device.name + "' has been removed from organization '" + props.org.name + "'.";
    // Helper fn
    const performAction = () => {
        return MC_Backend.getInstance().removeDeviceFromOrg(props.device!, props.org!);
    };
    // UI
    return <ConfirmAsyncOpDialog<void>
        open={props.open} closeDialogFn={props.closeDialogFn}
        title={"Remove Device?"} desc={desc}
        confirmText={"Remove"} confirmTextRed={true}
        successMsg={successMsg}
        performAction={performAction}
        doneSuccessFn={props.doneSuccessFn}
        doneFailureFn={props.doneFailureFn}
    />;
}

export default RemoveDeviceFromOrgDialog;