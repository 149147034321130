import ConfirmAsyncOpDialog from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialog";
import {ConfirmAsyncOpDialogBaseProps} from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialogBaseProps";
import {MC_Organization} from "../../../iot/MC_Organization";
import {MC_Backend} from "../../../MC_Backend";

export interface DeleteOrgDialogProps extends ConfirmAsyncOpDialogBaseProps<void> {
    org: MC_Organization | null;
}
function DeleteOrgDialog(props: DeleteOrgDialogProps) {
    // Data
    const orgName: string = (props.org != null) ? props.org.name : "N/A";
    const desc: string = "You are about to permanently delete '" + orgName + "'.";
    const successMsg: string = "This organization has been deleted.";
    // Helper fn
    const performAction = () => {
        if (props.org == null) {
            throw "Cannot delete null organization.";
        }
        return MC_Backend.getInstance().deleteOrganization(props.org);
    };
    // UI
    return <ConfirmAsyncOpDialog
        open={props.open} closeDialogFn={props.closeDialogFn}
        title={"Delete Organization?"} desc={desc}
        confirmText={"Delete"} confirmTextRed={true}
        successMsg={successMsg}
        performAction={performAction}
        doneSuccessFn={props.doneSuccessFn}
        doneFailureFn={props.doneFailureFn}
    />;
}

export default DeleteOrgDialog;