import {MC_Recent_Alert} from "../../iot/MC_Recent_Alert";
import {Divider, Paper, TableContainer} from "@mui/material";
import React from "react";
import RecentAlertItem from "../item-list/RecentAlertItem";

export interface AlertsTableProps {
    alerts: MC_Recent_Alert[];
    tableCtl?: JSX.Element;
}
function AlertsTable({alerts, tableCtl}: AlertsTableProps) {
    return <div className={"alerts-table-div"}>
        <TableContainer component={Paper} elevation={6} className={"alerts-table-container"}>

            {/* Optional table control header */}
            {(tableCtl != null) && tableCtl}

            <Divider sx={{width: "100%"}}/>

            {/* List of alerts */}
            <div className={"alerts-list-div"}>
                {alerts.map((x: MC_Recent_Alert, idx) => <RecentAlertItem key={x.alertID + "" + idx} recentAlert={x}/>)}
            </div>

        </TableContainer>
    </div>;
}

export default AlertsTable;