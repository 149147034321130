import React, {ErrorInfo, ReactNode} from "react";

interface ErrorBoundaryProps {
    children?: ReactNode;
}
interface ErrorBoundaryState {
    hasError: boolean;
    errMsg: string | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    public state: ErrorBoundaryState = {
        hasError: false,
        errMsg: null
    };

    // Update state based on error
    static getDerivedStateFromError(err: Error): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            errMsg: "UI error occurred. Contact JAS if this happens again."
        };
    }

    // Log error
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("ErrorBoundary caught err: " + error.name + ":" + error.message);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <p className={"bad"}>Something went wrong: {this.state.errMsg!}</p>;
        }
        // All g
        return this.props.children;
    }

}

export default ErrorBoundary;
