import "./OrganizationsTable.css"
import React, {CSSProperties} from "react";
import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {MC_Organization} from "../../iot/MC_Organization";
import {Link} from "react-router-dom";
import {MC_Constants} from "../../MC_Constants";

const alignVal: "left" | "center" | "right" = "left";

const linkStyle: CSSProperties = {
    color: "var(--MC_MAIN)",
    textDecorationColor: "var(--MC_MAIN)"
};
interface OrgRowProps {
    org: MC_Organization;
}
function OrgToRow(props: OrgRowProps) {
    let org = props.org;
    let nameStr: string = (org.name.length > 25)
        ? org.name.substr(0, 25) + "..."
        : org.name
    ;

    // UI
    return (
        <TableRow hover={true}>
            <TableCell align={alignVal}>
                <div className={"id-div"}>
                    <span className={"id-span"}>{org.id}</span>
                </div>
            </TableCell>
            <TableCell align={alignVal}>
                <Link to={"/core/organizations/" + org.id} style={linkStyle}>
                    <div className={"name-div"}>
                        <strong>
                            <span className={"name-span"}>{nameStr}</span>
                        </strong>
                    </div>
                </Link>
            </TableCell>
            <TableCell align={alignVal}>{org.deviceIDs.length}</TableCell>
            <TableCell align={alignVal}>{org.userIDs.length}</TableCell>
            <TableCell align={alignVal}>{MC_Constants.getReadableDateStr(org.created, true)}</TableCell>
        </TableRow>
    );
}

export interface OrganizationTableProps {
    orgs: MC_Organization[];
    tableCtl?: JSX.Element;
}
function OrganizationsTable({orgs, tableCtl}: OrganizationTableProps) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // Handle pagination changes
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div className={"table-div"}>
            <TableContainer component={Paper} elevation={6}>

                {/* Optional table control header */}
                {(tableCtl != null) && tableCtl}

                <Divider sx={{width: "100%"}}/>

                <Table component={"table"}>
                    {/* Header with column names */}
                    <TableHead>
                        <TableRow component={"tr"}>
                            <TableCell component={"th"} scope={"row"} align={alignVal}><strong>ID</strong></TableCell>
                            <TableCell align={alignVal}><strong>Name</strong></TableCell>
                            <TableCell align={alignVal}><strong>Devices</strong></TableCell>
                            <TableCell align={alignVal}><strong>Users</strong></TableCell>
                            <TableCell align={alignVal}><strong>Created</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    {/* Table cell for each user */}
                    <TableBody component={"tbody"}>
                        {
                            orgs.map((o, idx) => {
                                let shouldDisplay = (idx >= (page * rowsPerPage)) && (idx < ((page + 1) * rowsPerPage));
                                if (shouldDisplay) {
                                    return <OrgToRow key={o.id} org={o}/>
                                }
                                return null;
                            })
                        }
                    </TableBody>
                    <TableFooter component={"tfoot"}>
                        <TableRow>
                            {/* Pagination control */}
                            <TablePagination
                                count={orgs.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[10, 25, 50]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}


export default OrganizationsTable;