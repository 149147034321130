import "./Item.css";
import {Avatar, Button, Paper, Skeleton} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import {Link} from "react-router-dom";
import React, {CSSProperties} from "react";
import {MC_Organization} from "../../iot/MC_Organization";

const linkStyle: CSSProperties = {
    color: "var(--MC_MAIN)",
    textDecorationColor: "var(--MC_MAIN)",
};
export interface OrganizationItemProps {
    nameLink: boolean; // True if the name should be a link to the item
    org: MC_Organization | null; // Null to show a blank item
    selectAction?: (org: MC_Organization) => void;
    removeAction?: (org: MC_Organization) => void;
}
function OrganizationItem(props: OrganizationItemProps) {
    const org: MC_Organization | null = props.org;
    return (
        <Paper className={"item-root item-row-root"} elevation={6}>

            {/* Icon column */}
            <div className={"item-icon-col"}>
                {/* Skeleton */}
                {(org == null) &&
                    <Skeleton animation={false} variant="circular" sx={{ fontSize: 'large' }}>
                        <Avatar/>
                    </Skeleton>
                }
                {/* Icon */}
                {(org != null) && <BusinessIcon fontSize={"large"} color={"primary"}/>}
            </div>

            {/* Name column */}
            <div className={"item-name-col"}>
                <span className={"item-title"}>
                    {/* Skeleton */}
                    {(org == null) && <Skeleton animation={false}  className={"skel-text"} variant="text"/>}
                    {
                        (org != null) &&
                        <React.Fragment>
                            {(props.nameLink) && <Link to={"/core/organizations/" + org.id} style={linkStyle}><strong>{org.name}</strong></Link>}
                            {(!props.nameLink) && <strong>{org.name}</strong>}
                        </React.Fragment>
                    }
                </span>
                <span className={"item-id"}>
                    {(org == null) && <Skeleton className={"skel-text"} animation={false} variant="text"/>}
                    {(org != null) && org.id}
                </span>
            </div>

            {/* Action column */}
            <div className={"item-action-col"}>
                {/* Show buttons if necessary */}
                {
                    (org != null && props.selectAction != null)
                    &&
                    <Button variant="text" color={"primary"}
                            onClick={() => props.selectAction!(org)}>
                        Select
                    </Button>
                }
                {
                    (org != null && props.removeAction != null)
                    &&
                    <Button variant="text" color={"warning"}
                            onClick={() => props.removeAction!(org)}>
                        Remove
                    </Button>
                }
            </div>

        </Paper>
    );
}

export default OrganizationItem;