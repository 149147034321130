import {MC_Create_Device_Interface} from "../../../iot/MC_Create_Interface";
import {Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import React from "react";
import {MC_Constants} from "../../../MC_Constants";
import {CustomDevice, CustomDeviceFactory} from "../../../../custom/CustomDeviceFactory";

export interface CreateDeviceComponentProps {
    existingConfig: MC_Create_Device_Interface | null;
    selectConfig: (config: MC_Create_Device_Interface) => void;
}
interface CreateDeviceComponentState {
    id: string;
    jas_id: string;
    name: string;
    type: string;
    time_zone: string;
}
function CreateDeviceComponent(props: CreateDeviceComponentProps) {
    // State
    const [state, setState] = React.useState<CreateDeviceComponentState>({id: "", jas_id: "", name: "", type: "", time_zone: ""});
    // Update state on first render
    React.useEffect(() => {
        if (props.existingConfig != null) {
            setState({
                id: props.existingConfig.id,
                jas_id: props.existingConfig.jas_id,
                name: props.existingConfig.name,
                type: props.existingConfig.type,
                time_zone: props.existingConfig.time_zone
            });
        }
    }, [props.existingConfig]);
    // Helper fns
    const handleTypeChange = (event: SelectChangeEvent) => setState({id: state.id, jas_id: state.jas_id, name: state.name, type: event.target.value, time_zone: state.time_zone});
    const handleTZChange = (event: SelectChangeEvent) => setState({id: state.id, jas_id: state.jas_id, name: state.name, type: state.type, time_zone: event.target.value});
    const continueFn = () => {
        const foundFullType = CustomDeviceFactory.getFullDeviceType(state.type);
        const newConfig: MC_Create_Device_Interface = {
            id: state.id,
            jas_id: state.jas_id,
            name: state.name,
            type: state.type,
            full_type: foundFullType,
            time_zone: state.time_zone
        };
        props.selectConfig(newConfig);
    };
    // UI
    const supportedDevices: CustomDevice[] = CustomDeviceFactory.SUPPORTED_DEVICES;
    const supportedTypes: string[] = supportedDevices.map((d) => d.rawType);
    let errMsg: string | null = null;
    if (state.name.length < 5) {
        errMsg = "Name must be at least 5 characters.";
    } else if (state.id.length < 5) {
        errMsg = "ID must be at least 5 characters";
    } else if (state.jas_id.length === 0) {
        errMsg = "JAS ID cannot be empty";
    } else if (!supportedTypes.includes(state.type)) {
        errMsg = "Invalid device type.";
    } else if (!MC_Constants.ALL_SUPPORTED_TIME_ZONES.includes(state.time_zone)) {
        errMsg = "Invalid time zone.";
    }
    return <div className={"create-config-root"}>

        {/* Usage note */}
        <p className={"note"}>JAS will typically create the devices for you, ahead of delivery.</p>

        {/* Name */}
        <TextField label="Name" variant="filled" value={state.name}
                   onChange={(event) => {
                       setState({id: state.id, jas_id: state.jas_id, name: event.target.value, type: state.type, time_zone: state.time_zone});
                   }}
        />

        {/* ID */}
        <TextField label="ID" variant="filled" value={state.id}
                   onChange={(event) => {
                       setState({id: event.target.value, jas_id: state.jas_id, name: state.name, type: state.type, time_zone: state.time_zone});
                   }}
        />

        {/* JAS ID */}
        <TextField label="JAS Serial #" variant="filled" value={state.jas_id}
                   onChange={(event) => {
                       setState({id: state.id,  jas_id: event.target.value, name: state.name, type: state.type, time_zone: state.time_zone});
                   }}
        />

        {/* Note about time zone */}
        <p className={"note"}>Select the type of device:</p>

        {/* Type */}
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
                id="select-type-id" labelId="type-label"
                value={state.type} onChange={handleTypeChange}
            >
                <MenuItem key={"0"} value={""} disabled={true}>-- Device Type --</MenuItem>
                {supportedDevices.map((d) => <MenuItem key={d.rawType} value={d.rawType}>{d.fullTypeName}</MenuItem>)}
            </Select>
        </FormControl>

        {/* Note about time zone */}
        <p className={"note"}>Select the time zone this device will be located in:</p>

        {/* Time Zone */}
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="tz-label">Time Zone</InputLabel>
            <Select
                labelId="tz-label" id="select-tz-id" value={state.time_zone}
                onChange={handleTZChange}
            >
                <MenuItem key={"0"} value={""} disabled={true}>-- Time Zone --</MenuItem>
                <MenuItem key={"1"} value={MC_Constants.TZ_AMERICA_CHICAGO}>America/Chicago</MenuItem>
                <MenuItem key={"2"} value={MC_Constants.TZ_AMERICA_LOS_ANGELES}>America/Los Angeles</MenuItem>
                <MenuItem key={"3"} value={MC_Constants.TZ_AMERICA_DENVER}>America/Denver</MenuItem>
                <MenuItem key={"4"} value={MC_Constants.TZ_AMERICA_NEW_YORK}>America/New York</MenuItem>
            </Select>
        </FormControl>

        <br/>

        {/* Optional error note */}
        {
            (errMsg != null) &&
            <span className={"error-note"}>{errMsg}</span>
        }

        {/* Confirm button */}
        <Button variant="text" color={"primary"} disabled={errMsg != null} onClick={continueFn}><b>Continue</b></Button>

    </div>;
}

export default CreateDeviceComponent;
