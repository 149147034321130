import "./ViewPaper.css";
import {MC_User} from "../../iot/MC_User";
import {Button, Paper} from "@mui/material";
import React from "react";
import UserItemList from "../item-list/list/UserItemList";

export interface ViewUsersProps {
    userIDs: string[];
    desc: string;
    addAction?: () => void;
    removeAction?: (u: MC_User) => void;
}

function ViewUsers(props: ViewUsersProps) {
    return (
        <div>

            <p className={"note"}>
                <strong>{props.desc}</strong>
            </p>

            {/* Users item list */}
            <UserItemList userIDs={props.userIDs} removeAction={props.removeAction}/>

            <br/>
            {/* [Optional] Add Action */}
            {
                (props.addAction != null)
                &&
                <Button variant="contained" color={"success"}
                        onClick={() => props.addAction!()}>
                    Add User
                </Button>
            }

        </div>
    );
}

export default ViewUsers;