import "./CompleteMFADialog.css";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import MFAFactorItem from "../../item-list/MFAFactorItem";
import {Message, Security} from "@mui/icons-material";
import {
    getAuth,
    MultiFactorInfo,
    MultiFactorResolver,
    RecaptchaVerifier,
    UserCredential,
    PhoneInfoOptions,
    PhoneAuthProvider, PhoneMultiFactorInfo, PhoneMultiFactorSignInInfoOptions, PhoneMultiFactorGenerator
} from "@firebase/auth";

export interface CompleteMFADialogConfig {
    resolver: MultiFactorResolver;
    successFn: (userCred: UserCredential) => void;
    failureFn: (errStr: string) => void;
}

export interface CompleteMFADialogProps {
    open: boolean;
    setOpenFn: (newOpen: boolean) => void;
    config: CompleteMFADialogConfig | null;
}
function CompleteMFADialog(props: CompleteMFADialogProps) {
    const RECAPTCHA_DIV_ID: string = "recaptcha-div-id";
    const STEP_CHOOSE_FACTOR: string = "choose_factor";
    const STEP_SEND_TEXT: string = "send_text";
    const STEP_INPUT_CODE: string = "input_code";
    const [errMsg, setErrMsg] = React.useState<string | null>(null);
    const [curStep, setCurStep] = React.useState<string>(STEP_CHOOSE_FACTOR);
    const [curFactor, setCurFactor] = React.useState<MultiFactorInfo | null>(null);
    const [loadingRecaptcha, setLoadingRecaptcha] = React.useState<boolean>(false);
    const [recapVerifier, setRecapVerifier] = React.useState<RecaptchaVerifier | null>(null);
    const [recaptchaKey, setRecaptchaKey] = React.useState<string | null>(null);
    const [verifyingPhone, setVerifyingPhone] = React.useState<boolean>(false);
    const [verifyID, setVerifyID] = React.useState<string | null>(null);
    const [verifyCode, setVerifyCode] = React.useState<string>("");
    const [resolving, setResolving] = React.useState<boolean>(false);

    // Vars
    const factors: MultiFactorInfo[] = (props.config != null) ? props.config.resolver.hints : [];
    const curFactorDisplayName: string = (curFactor != null)
        ? ((curFactor!.displayName != null) ? curFactor!.displayName : "Unnamed Phone")
        : "UNDEFINED"
    ;

    // Functions
    const closeAndClearCtx = () => {
        props.setOpenFn(false);
        setErrMsg(null);
        setCurStep(STEP_CHOOSE_FACTOR);
        setCurFactor(null);
        setLoadingRecaptcha(false);
        if (recapVerifier != null) {
            recapVerifier.clear();
        }
        setRecapVerifier(null);
        setRecaptchaKey(null);
        setVerifyingPhone(false);
        setVerifyID(null);
        setVerifyCode("");
        setResolving(false);
    };
    const cancelFn = () => {
        if (props.config != null) {
            props.config.failureFn("Log In Cancelled");
        }
        closeAndClearCtx();
    };
    const selectFn = (factor: MultiFactorInfo) => {
        setCurFactor(factor);
        setLoadingRecaptcha(true);
        setCurStep(STEP_SEND_TEXT);
        addRecaptcha();
    };
    const addRecaptcha = () => {
        const recapVerifier: RecaptchaVerifier = new RecaptchaVerifier(
            getAuth(),
            RECAPTCHA_DIV_ID,
            {
                size: "normal",
                callback: (resp: any) => {
                    const key: string = resp as string
                    setRecaptchaKey(key);
                    setErrMsg(null);
                },
                "expired-callback": () => {
                    setRecaptchaKey(null);
                }
            }
        );
        setRecapVerifier(recapVerifier);
        recapVerifier.render()
            .then((x) => {
                setLoadingRecaptcha(false);
            })
            .catch((err) => {
                setLoadingRecaptcha(false);
                setErrMsg("Error loading recaptcha: " + err);
            })
        ;
    };
    const verifyPhone = () => {
        setVerifyingPhone(true);
        const options: PhoneMultiFactorSignInInfoOptions = {
            session: props.config!.resolver.session,
            multiFactorUid: curFactor!.uid
        };
        const phoneAuthProvider = new PhoneAuthProvider(getAuth());
        phoneAuthProvider.verifyPhoneNumber(options, recapVerifier!)
            .then((val) => {
                //console.log("val: " + val);
                setVerifyingPhone(false);
                setVerifyID(val);
                setCurStep(STEP_INPUT_CODE);
            })
            .catch((err: any) => {
                setRecaptchaKey(null);
                setVerifyingPhone(false);
                setVerifyID(null);
                setErrMsg((err.code != null) ? err.code : "Error occurred");
            })
        ;
    };
    const resolveSignIn = () => {
        const phoneCred = PhoneAuthProvider.credential(verifyID!, verifyCode);
        const mfaAssertion = PhoneMultiFactorGenerator.assertion(phoneCred);
        setResolving(true);
        props.config!.resolver.resolveSignIn(mfaAssertion)
            .then((userCred) => {
                props.config!.successFn(userCred);
                closeAndClearCtx();
            })
            .catch((err) => {
                setResolving(false);
                setErrMsg((err.code != null) ? err.code : "Error occurred");
            })
        ;
    };

    // UI
    return <Dialog className={"cmfa-root"} open={props.open}>

        {/* Header */}
        <DialogTitle>
            <span className={"title"}>Authentication Required</span>
        </DialogTitle>

        {/* Content */}
        <DialogContent>
            <div className={"cmfa-content-div"}>
                {/* Choose factor */}
                {(curStep === STEP_CHOOSE_FACTOR) &&
                    <React.Fragment>
                        <p className={"note"}>Choose a secondary factor to verify your identity.</p>
                        {factors.map((x, idx) => <MFAFactorItem key={"mfaitem" + idx} factor={x} selectFn={() => selectFn(x)}/>)}
                    </React.Fragment>
                }

                {/* Always show recaptcha dialog div */}
                <div className={"recaptcha-container-div"}>
                    {/* Show loading only on the recaptcha step */}
                    {(curStep === STEP_SEND_TEXT && loadingRecaptcha) && <CircularProgress className={"mfa-spinner"}/>}
                    {/* Recaptcha */}
                    {(curStep === STEP_SEND_TEXT && !loadingRecaptcha) && <p className={"note"}>Prove you are not a robot.</p>}
                    {(verifyID == null) && <div id={RECAPTCHA_DIV_ID}></div>}
                </div>

                {/* Send text */}
                {(curStep === STEP_SEND_TEXT) &&
                    <React.Fragment>
                        <br/>
                        <span className={"text-note"}>A verification code will be sent to {curFactorDisplayName}</span>
                        <br/>
                        {/* Show button or spinner */}
                        {verifyingPhone && <CircularProgress className={"mfa-spinner"}/>}
                        {!verifyingPhone &&
                            <Button variant={"contained"} disabled={(recaptchaKey == null)}
                                    endIcon={<Message/>} onClick={verifyPhone}>
                                Send Text
                            </Button>
                        }
                    </React.Fragment>
                }

                {/* Input code */}
                {(curStep === STEP_INPUT_CODE) &&
                    <React.Fragment>
                        <p className={"note"}>Input the code you received via text. Do not share it with anyone.</p>
                        <TextField
                            className={"text-input"} label="Verification Code"
                            variant="outlined" type={"text"}
                            value={verifyCode}
                            onChange={(event) => {
                                setVerifyCode(event.target.value);
                            }}
                        />
                        <br/>
                        {/* Show button or spinner */}
                        {resolving && <CircularProgress className={"mfa-spinner"}/>}
                        {!resolving &&
                            <Button variant={"contained"} disabled={(verifyCode === "")}
                                    endIcon={<Security/>} onClick={resolveSignIn}>
                                Complete Sign In
                            </Button>
                        }
                    </React.Fragment>
                }

                {/* Error message */}
                {(errMsg != null) && <p className={"error-note"}>{errMsg}</p>}

            </div>
        </DialogContent>

        {/* Actions */}
        <DialogActions className={"cmfa-actions"}>
            <Button className={"action-btn"} color={"warning"} onClick={cancelFn}>
                <strong>Cancel</strong>
            </Button>
        </DialogActions>

    </Dialog>;
}

export default CompleteMFADialog;