import "./Item.css";
import {MC_User} from "../../iot/MC_User";
import {Avatar, Button, Paper, Skeleton} from "@mui/material";
import {Person} from "@mui/icons-material";
import {Link} from "react-router-dom";
import React, {CSSProperties} from "react";

const linkStyle: CSSProperties = {
    color: "var(--MC_MAIN)",
    textDecorationColor: "var(--MC_MAIN)",
};
export interface UserItemProps {
    nameLink: boolean; // True if the name should be a link to the item
    showPhone: boolean; // True if phone number should be displayed
    user: MC_User | null; // Null to show a blank item
    selectAction?: (u: MC_User) => void;
    removeAction?: (u: MC_User) => void;
}
function UserItem(props: UserItemProps) {
    let u: MC_User | null = props.user;
    return (
        <Paper className={"item-root item-row-root"} elevation={6}>

            {/* Icon column */}
            <div className={"log-item-icon-col"}>
                {/* Skeleton */}
                {(u == null) &&
                    <Skeleton variant="circular" sx={{ fontSize: 'large' }}>
                        <Avatar/>
                    </Skeleton>
                }
                {/* Icon */}
                {(u != null) && <Person fontSize={"large"} color={"primary"}/>}
            </div>

            {/* Name column */}
            <div className={"item-name-col"}>
                <span className={"item-title"}>
                    {(u == null) && <Skeleton className={"skel-text"} variant="text"/>}
                    {
                        (u != null) &&
                        <React.Fragment>
                            {(props.nameLink) &&
                                <Link to={"/core/users/" + u.uid} style={linkStyle}>
                                    <strong>{u.name}</strong>
                                </Link>
                            }
                            {(!props.nameLink) && <strong>{u.name}</strong>}
                        </React.Fragment>
                    }
                </span>
                <span className={"item-id"}>
                    {(u == null) && <Skeleton className={"skel-text"} variant="text"/>}
                    {(u != null) && u.email}
                </span>
                {(props.showPhone) &&
                    <span className={"item-id"}>
                        {(u == null) && <Skeleton className={"skel-text"} variant="text"/>}
                        {(u != null && u.phone.length > 0) && "Phone: " + u.phone}
                    </span>
                }
            </div>

            {/* Action column */}
            <div className={"item-action-col"}>
                {/* Show buttons if necessary */}
                {
                    (u != null && props.selectAction != null)
                    &&
                    <Button variant="text" color={"primary"}
                            onClick={() => props.selectAction!(u!)}>
                        Select
                    </Button>
                }
                {
                    (u != null && props.removeAction != null)
                    &&
                    <Button variant="text" color={"warning"}
                            onClick={() => props.removeAction!(u!)}>
                        Remove
                    </Button>
                }
            </div>

        </Paper>
    );
}

export default UserItem;