import {MC_Backend} from "../../../MC_Backend";
import ThreeStepAsyncOpDialog from "../ThreeStepAsyncOpDialog/ThreeStepAsyncOpDialog";
import {MC_User} from "../../../iot/MC_User";
import {MC_Organization} from "../../../iot/MC_Organization";
import {OrganizationsContext} from "../../../../App";
import PaginatedSelectItemList from "../../item-list/select-list/PaginatedSelectItemList";
import OrganizationItem from "../../item-list/OrganizationItem";
import {ThreeStepAddDialogBaseProps} from "./ThreeStepAddDialogBaseProps";

export interface AddOrgToUserDialogProps extends ThreeStepAddDialogBaseProps<MC_Organization> {
    user: MC_User;
}
function AddOrgToUserDialog(props: AddOrgToUserDialogProps): any {
    const user: MC_User = props.user;
    // Helper fns
    const getSelectionComponent = (selectFn: (o: MC_Organization) => void) => {
        return (
            <OrganizationsContext.Consumer>
                {(orgsCtx) => {
                    const loadFn = () => MC_Backend.getInstance().loadOrganizations().finally();
                    const renderItem = (idx: number, o: MC_Organization | null) => {
                        // org can only be selected if not already connected
                        const orgSelectFn = (o != null && !props.user.organizationIDs.includes(o.id)) ? selectFn : undefined;
                        const key = (o != null) ? o.id : "null" + idx;
                        return <OrganizationItem key={key} org={o} selectAction={orgSelectFn} nameLink={false}/>;
                    };
                    const sortByName = (a: MC_Organization, b: MC_Organization) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    const sortFn = (org1: MC_Organization, org2: MC_Organization) => {
                        const userHasOrg1: boolean = user.organizationIDs.includes(org1.id);
                        const userHasOrg2: boolean = user.organizationIDs.includes(org2.id);
                        // If these orgs do not have the same relationship with user, compare them
                        if (userHasOrg1 !== userHasOrg2) {
                            // Sort relevant orgs first (relevant meaning it is not already connected)
                            return (userHasOrg1) ? 1 : -1;
                        }
                        // Default to sort by name
                        return sortByName(org1, org2);
                    };
                    return <PaginatedSelectItemList
                        allowSearch={true}
                        pageSize={4}
                        fillPageWithNullItems={true}
                        loadDataFn={loadFn}
                        emptyMessage={"No organizations found."}
                        dataArray={orgsCtx.organizations}
                        loading={orgsCtx.loading}
                        errMsg={orgsCtx.errMsg}
                        sortFn={sortFn}
                        renderItem={renderItem}
                    />
                }}
            </OrganizationsContext.Consumer>
        );
    }
    const getConfirmDescFn = (o: MC_Organization) => {
        return "You are about to add '" + props.user.name + "' to organization: '" + o.name + "'.";
    }
    const addOrgFn = (o: MC_Organization): Promise<any> => {
        return MC_Backend.getInstance().addUserToOrg(props.user, o);
    }
    const getSuccessDesc = (o: MC_Organization) => {
        return "User '" + props.user.name + "' added to organization '" + o.name + "'.";
    };
    // UI
    return (
        <ThreeStepAsyncOpDialog<MC_Organization>
            open={props.open} closeDialogFn={props.closeDialogFn}
            title={"Add Organization"}
            getSelectionComponent={getSelectionComponent}
            getConfirmDesc={getConfirmDescFn}
            confirmText={"Add Org"}
            performActionFn={addOrgFn}
            getSuccessDesc={getSuccessDesc}
            doneSuccessFn={() => {}}
            doneFailureFn={() => {}}
        />
    );
}

export default AddOrgToUserDialog;
