import "./Organizations.css";
import OrganizationsTable from "../../common/ui/table/OrganizationsTable";
import {MC_Organization} from "../../common/iot/MC_Organization";
import TableControl, {TableControlSelectOption} from "../../common/ui/table/TableControl";
import React, {useContext} from "react";
import {MC_Backend} from "../../common/MC_Backend";
import {OrganizationsContext} from "../../App";
import GeneralHeader from "../../common/ui/GeneralHeader";
import MCLoadingBar from "../../common/ui/misc/MCLoadingBar";
import CreateOrganizationDialog from "../../common/ui/dialog/CreateDialog/CreateOrganizationDialog";
import useLocalStorageState from "../../common/ui/UseLocalStorageState";

const STORAGE_ORGS_SORT_FIELD: string = "orgs_sort_field";
const STORAGE_ORGS_SEARCH_KEY: string = "orgs_search_key";

function Organizations(props: any) {
    const ctx = useContext(OrganizationsContext);
    // State vars
    const [createOrgOpen, setCreateOrgOpen] = React.useState<boolean>(false);
    const [sortField, updateSortField] = useLocalStorageState(STORAGE_ORGS_SORT_FIELD, "name");
    const [searchKey, updateSearchKey] = useLocalStorageState(STORAGE_ORGS_SEARCH_KEY, "");

    // Select options
    const tableSortOptions: TableControlSelectOption[] = [
        {value: "name", name: "Name"},
        {value: "id", name: "ID"},
        {value: "created", name: "Created"}
    ];

    // Refresh orgs fn
    const refreshFn = () => {
        MC_Backend.getInstance().loadOrganizations().finally(); // Will trigger consumer fn
    };

    // Validate search key
    React.useEffect(() => {
        if (searchKey.length > 0) {
            if (searchKey.replace(/\s/g, '').length === 0) {
                // search key is all white space
                updateSearchKey(""); // Clear it
            }
        }
    }, [searchKey, updateSearchKey]);

    // Load the orgs if needed
    React.useEffect(() => {
        if (ctx.organizations == null && !ctx.loading && ctx.errMsg == null) {
            refreshFn();
        }
    }, [ctx.organizations, ctx.loading, ctx.errMsg]);

    // Filter/sort the orgs whenever necessary
    React.useEffect(
        () => {
            let backend = MC_Backend.getInstance();
            backend.updateOrganizationsContext({...backend.orgsCtx}); // Trigger consumer fn
        },
        [searchKey, sortField]
    );

    // Table control
    const tableCtl = <TableControl
        searchKey={searchKey} updateSearchKey={updateSearchKey} searchHint={"Search by Name or ID..."}
        sortOptions={tableSortOptions} sortField={sortField} updateSortField={updateSortField}
    />;

    // UI
    return (
        <OrganizationsContext.Consumer>
            {(orgsCtx) => {
                let curOrgs = parseCurrentOrgs(
                    (orgsCtx.organizations != null) ? orgsCtx.organizations : [],
                    sortField,
                    searchKey
                );
                return (
                    <div className={"orgs-root-div"}>

                        {/* Create org dialog */}
                        <CreateOrganizationDialog open={createOrgOpen} closeDialogFn={() => setCreateOrgOpen(false)}/>

                        {/* Title */}
                        <GeneralHeader title={"Organizations"} createAction={() => setCreateOrgOpen(true)}
                                       refreshAction={(!orgsCtx.loading) ? refreshFn : () => {}}
                        />

                        {/* Content */}
                        <div>

                            {/* Loading div */}
                            <MCLoadingBar loadingMessage={"Loading Organizations..."}
                                          loading={orgsCtx.loading} errorMessage={orgsCtx.errMsg}
                            />

                            <p className={"small-note"}>
                                An "organization" is a group of users & devices belonging to the same business customer.
                            </p>

                            {
                                (orgsCtx.organizations != null) &&
                                <div>
                                    {/* Table for organizations */}
                                    <OrganizationsTable orgs={curOrgs} tableCtl={tableCtl}/>
                                </div>
                            }

                            <br/><br/>
                        </div>
                    </div>
                );
            }}
        </OrganizationsContext.Consumer>
    );
}

function parseCurrentOrgs(
    allOrgs: MC_Organization[],
    sortField: string,
    searchKey: string
): MC_Organization[] {

    // 1) Filter the orgs
    let curOrgs: MC_Organization[] = allOrgs;
    if (searchKey.length > 0) {
        const filterOrgName = (a: MC_Organization): boolean => a.name.toLowerCase().includes(searchKey.toLowerCase());
        const filterOrgID = (a: MC_Organization): boolean => a.id.toLowerCase().includes(searchKey.toLowerCase());
        curOrgs = curOrgs.filter((o) => (filterOrgName(o) || filterOrgID(o)));
    }

    // 2) Sort the orgs (Default to name)
    let sortFn: (a: MC_Organization, b: MC_Organization) => number =
        (a: MC_Organization, b: MC_Organization): number => a.name.localeCompare(b.name)
    ;
    if (sortField === "id") {
        sortFn = (a: MC_Organization, b: MC_Organization): number => a.id.localeCompare(b.id);
    } else if (sortField === "created") {
        sortFn = (a: MC_Organization, b: MC_Organization): number => (b.created.getTime() - a.created.getTime());
    }
    curOrgs.sort(sortFn);

    // Done
    return curOrgs;
}

export default Organizations;