import "./TableControl.css";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    FormControl,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent, ToggleButton,
    ToggleButtonGroup,
    Toolbar
} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";
import React from "react";

// Table Control is a filter/control component for a table displaying custom objects

// Common interface for select props
export interface TableControlSelectOption {
    name: string;
    value: string;
}

// Common definition for table control props
export interface TableControlProps {

    // Select field to filter on
    //filterOptions: TableControlSelectOption[];
    //filterField: string;
    //updateFilterField: (newVal: string) => void;

    // Value to search/filter by
    searchKey: string;
    updateSearchKey: (newVal: string) => void;
    searchHint: string;

    // Select field to sort on
    sortOptions: TableControlSelectOption[];
    sortField: string;
    updateSortField: (newVal: string) => void;
    //sortDirection: string;
    //updateSortDirection: (newVal: string) => void;

    // (Devices only)
    useSoldFilter?: boolean;
    soldFilter?: string;
    updateSoldFilter?: (newVal: string) => void;
}

const searchColor: string = "#9b9b9b";
const Search = styled("div")(({theme}) => ({
    position: 'relative',
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(searchColor, 0.15),
    '&:hover': {
        backgroundColor: alpha(searchColor, 0.25),
    },
    // This overrides the width if it is above the 'sm' breakpoint cutoff
    marginLeft: 0,
    width: '100%',
    /*
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },*/
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    flexGrow: 1,
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        /*
        [theme.breakpoints.up('sm')]: {
            width: '32ch',
        },*/
    },
}));

// Function component
function TableControl(props: TableControlProps) {
    const handleSortFieldChange = (ev: SelectChangeEvent<string>) => props.updateSortField(ev.target.value);
    const handleSearchKeyChange = (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => props.updateSearchKey(ev.target.value);
    const handleSoldFilterChange = (
        event: React.MouseEvent<HTMLElement>,
        newVal: string | null,
    ) => {
        let newSF: string = (newVal != null) ? newVal : "";
        props.updateSoldFilter!(newSF);
    };
    return (
        <Toolbar className={"search-toolbar"}>
            {/* sort */}
            <div className={"search-sort"}>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl variant={"filled"} fullWidth>
                        <InputLabel id="sort_label">Sort</InputLabel>
                        <Select
                            labelId="sort_label"
                            id="sort_select"
                            label="Sort"
                            value={props.sortField}
                            onChange={handleSortFieldChange}
                        >
                            {props.sortOptions.map((op) => <MenuItem key={op.value} value={op.value}>{op.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
            </div>
            {/* Filter search field */}
            <div className={"search-filter"}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder={props.searchHint}
                        value={props.searchKey}
                        onChange={handleSearchKeyChange}
                    />
                </Search>
            </div>
            {/* Sold filter */}
            {props.useSoldFilter &&
                <div className={"sold-filter-div"}>
                    <ToggleButtonGroup
                        value={props.soldFilter}
                        exclusive
                        onChange={handleSoldFilterChange}
                    >
                        <ToggleButton value="">
                            <span className={(props.soldFilter === "") ? "sf-sel" : ""}>All</span>
                        </ToggleButton>
                        <ToggleButton value="promo">
                            <span className={(props.soldFilter === "promo") ? "sf-sel" : ""}>Promo</span>
                        </ToggleButton>
                        <ToggleButton value="sold">
                            <span className={(props.soldFilter === "sold") ? "sf-sel" : ""}>Sold</span>
                        </ToggleButton>
                        <ToggleButton value="unsold">
                            <span className={(props.soldFilter === "unsold") ? "sf-sel" : ""}>Unsold</span>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            }

        </Toolbar>
    );
}

/* Currently unused

//const handleFilterFieldChange = (ev: SelectChangeEvent<string>) => props.updateFilterField(ev.target.value);
    //const handleSortDirectionChange = (ev: SelectChangeEvent<string>) => props.updateSortDirection(ev.target.value);

<Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="sort_dir_label">Direction</InputLabel>
                        <Select
                            labelId="sort_dir_label"
                            id="sort_dir_select"
                            label="Direction"
                            value={props.sortDirection}
                            onChange={handleSortDirectionChange}
                        >
                            <MenuItem key={"asc"} value={"asc"}><ArrowDownward fontSize={"small"}/></MenuItem>
                            <MenuItem key={"desc"} value={"desc"}><ArrowUpward fontSize={"small"}/></MenuItem>
                        </Select>
                    </FormControl>
                </Box>

<Box sx={{ minWidth: 120 }}>
                    <FormControl variant={"filled"} fullWidth>
                        <InputLabel id="search_label">Filter</InputLabel>
                        <Select
                            labelId="search_label"
                            id="search_input"
                            value={props.filterField}
                            label="Filter"
                            onChange={handleFilterFieldChange}
                        >
                            {props.filterOptions.map(optionToMenuItem)}
                        </Select>
                    </FormControl>
                </Box>


 */

export default TableControl;