import "./ViewUser.css";
import {MC_User} from "../../../common/iot/MC_User";
import {Alert, Button, Paper, Snackbar, TextareaAutosize} from "@mui/material";
import DetailRow from "../../../common/ui/DetailRow";
import React from "react";
import {MC_Backend} from "../../../common/MC_Backend";
import {MC_Constants} from "../../../common/MC_Constants";

export interface UserInfoTabProps {
    user: MC_User;
    setDeleteUserOpen: (x: boolean) => void;
}
function UserInfoTab(props: UserInfoTabProps) {
    // Data
    const user: MC_User = props.user;

    // State
    const [progressSnackOpen, setProgressSnackOpen] = React.useState<boolean>(false);
    const [errorSnackOpen, setErrorSnackOpen] = React.useState<boolean>(false);
    const [successSnackOpen, setSuccessSnackOpen] = React.useState<boolean>(false);
    const [tempAdminNotes, setTempAdminNotes] = React.useState<string>(user.adminNotes);
    const [opInProgress, setOpInProgress] = React.useState<boolean>(false);
    // Help
    const updateFn = () => {
        if (!opInProgress) {
            setOpInProgress(true);
            setProgressSnackOpen(true);
            MC_Backend.getInstance().updateUser(user.uid, tempAdminNotes)
                .then(() => {
                    setSuccessSnackOpen(true);
                })
                .catch((e) => {
                    console.error("Update err: " + JSON.stringify(e));
                    setErrorSnackOpen(true);
                })
                .finally(() => {
                    setOpInProgress(false);
                })
            ;
        }
    };
    // UI
    return (
        <div>

            {/* Snack bars */}
            <Snackbar
                open={progressSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setProgressSnackOpen(false)}>
                <Alert severity="info" sx={{ width: '100%' }}>
                    Updating User...
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setErrorSnackOpen(false)}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Error Updating User.
                </Alert>
            </Snackbar>
            <Snackbar
                open={successSnackOpen}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setSuccessSnackOpen(false)}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Updated User.
                </Alert>
            </Snackbar>

            {/* Info paper */}
            <Paper className={"content-start"} elevation={3}>
                <span className={"card-title"}>Info</span>
                <DetailRow label={"Name"} value={user.name}/>
                <DetailRow label={"Email"} value={user.email}/>
                <DetailRow label={"Phone"} value={user.phone}/>
                <DetailRow label={"UID"} value={user.uid}/>
                <DetailRow label={"Created"} value={MC_Constants.getReadableDateStr(user.created)}/>
                <DetailRow label={"Modified"} value={MC_Constants.getReadableDateStr(user.modified)}/>
                <DetailRow label={"Last Seen"} value={MC_Constants.getReadableDateStr(user.lastActivity)}/>
                <DetailRow label={"Status"}
                           value={(user.enabled) ? "ENABLED" : "DISABLED"}
                           color={(user.enabled) ? "good" : "bad"}
                />
                <DetailRow label={"Admin Notes"} value={""}/>
                <TextareaAutosize
                    className={"admin-notes-area"}
                    minRows={4} maxRows={8} maxLength={140}
                    disabled={false}
                    placeholder={"Notes about this user are private to management, and are NOT visible to users."}
                    value={tempAdminNotes}
                    onChange={(ev) => setTempAdminNotes(ev.target.value)}
                />
                <div className={"center-row"}>
                    <Button variant="text" color={"primary"} disabled={opInProgress} onClick={updateFn}>Update</Button>
                </div>
            </Paper>

            <br/>

            {/* Enable/disable the user */}
            <div>
                <p className={"note btn-note"}>
                    {
                        (user.enabled)
                            ? "Disabling this user will prevent them from having any access."
                            : "Enabling this user will allow them access again."
                    }
                </p>
                <Button variant="contained" color={(user.enabled) ? "secondary" : "primary"}>
                    {
                        (user.enabled)
                            ? "Disable User"
                            : "Enable User"
                    }
                </Button>
            </div>
            <br/>
            {/* Delete the user */}
            <div>
                <p className={"note btn-note"}>This will permanently delete the user, and remove them from all organizations.</p>
                <Button variant="contained" color={"warning"} onClick={() => props.setDeleteUserOpen(true)}><strong>Delete User</strong></Button>
            </div>

        </div>
    );
}

export default UserInfoTab;