import {ThreeStepAddDialogBaseProps} from "./ThreeStepAddDialogBaseProps";
import {MC_User} from "../../../iot/MC_User";
import {MC_Issue} from "../../../iot/MC_Issue";
import ThreeStepAsyncOpDialog from "../ThreeStepAsyncOpDialog/ThreeStepAsyncOpDialog";
import {UsersContext} from "../../../../App";
import {MC_Backend} from "../../../MC_Backend";
import UserItem from "../../item-list/UserItem";
import PaginatedSelectItemList from "../../item-list/select-list/PaginatedSelectItemList";

export interface AddAdminToIssueDialogProps extends ThreeStepAddDialogBaseProps<MC_User> {
    issue: MC_Issue;
}
function AddAdminToIssueDialog(props: AddAdminToIssueDialogProps) {
    // Selection UI
    const getSelectionComponent = (selectFn: (user: MC_User) => void) => {
        return (
            <UsersContext.Consumer>
                {(usersCtx) => {
                    const helpUIDs: string[] = props.issue.help_uids;
                    let loadFn = () => MC_Backend.getInstance().loadUsers().finally();
                    let renderItem = (idx: number, u: MC_User | null) => {
                        // Only admin users should be shown
                        if (u == null || !u.admin) {
                            return null;
                        }
                        // Admin users cannot be added twice
                        const userSelectFn = (helpUIDs.includes(u.uid)) ? undefined : selectFn;
                        // Return item
                        return <UserItem key={u.uid} user={u} selectAction={userSelectFn} nameLink={false} showPhone={false}/>;
                    };
                    const sortByName = (a: MC_User, b: MC_User) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    const sortFn = (u1: MC_User, u2: MC_User) => {
                        const issueHasU1 = helpUIDs.includes(u1.uid);
                        const issueHasU2 = helpUIDs.includes(u2.uid);
                        // If these users are not both assigned or assigned to this issue, compare them
                        if (issueHasU1 !== issueHasU2) {
                            // Sort relevant users first (relevant meaning they are not already assigned)
                            return (issueHasU1) ? 1 : -1;
                        }
                        // Default to sort by name
                        return sortByName(u1, u2);
                    }
                    return <PaginatedSelectItemList
                        allowSearch={true}
                        pageSize={4}
                        fillPageWithNullItems={true}
                        loadDataFn={loadFn}
                        emptyMessage={"No users found."}
                        dataArray={usersCtx.users}
                        loading={usersCtx.loading}
                        errMsg={usersCtx.errMsg}
                        sortFn={sortFn}
                        renderItem={renderItem}
                    />
                }}
            </UsersContext.Consumer>
        );
    }
    // Helper
    const getConfirmDescFn = (user: MC_User) => {
        return "You are about to add '" + user.name + "' as a support member.";
    }
    const addUserFn = (user: MC_User): Promise<any> => {
        return MC_Backend.getInstance().addAdminToIssue(props.issue, user.uid);
    }
    const getSuccessDesc = (user: MC_User) => {
        return "Support member '" + user.name + "' added.";
    }
    // UI
    return <ThreeStepAsyncOpDialog
        open={props.open} closeDialogFn={props.closeDialogFn}
        title={"Add Support Member"}
        getSelectionComponent={getSelectionComponent}
        getConfirmDesc={getConfirmDescFn}
        confirmText={"Add"}
        performActionFn={addUserFn}
        getSuccessDesc={getSuccessDesc}
        doneSuccessFn={() => {}}
        doneFailureFn={() => {}}
    />;
}

export default AddAdminToIssueDialog;