import "./ViewPaper.css";
import {MC_Action_Log} from "../../iot/MC_Action_Log";
import {Paper} from "@mui/material";
import React from "react";
import PaginatedSelectItemList from "../item-list/select-list/PaginatedSelectItemList";
import {OverviewContext} from "../../../App";
import ActivityLogItem from "../item-list/ActivityLogItem";
import {MC_Backend} from "../../MC_Backend";

export interface ViewActivityLogProps {
    title: string;
    desc: string;
    logFilterFn: (log: MC_Action_Log) => boolean;
    pageSize: number;
    hideLoadingBar?: boolean; // True indicates the loading bar should never be displayed
}

function ViewActivityLog(props: ViewActivityLogProps) {
    // Load overview if needed
    let ctx = React.useContext(OverviewContext);
    React.useEffect(() => {
        if (ctx.overview == null && !ctx.loading && ctx.errMsg == null) {
            MC_Backend.getInstance().loadOverview().finally();
        }
    }, [ctx]);
    // UI
    return (
        <div>

            <p className={"note"}>
                {props.desc}
            </p>

            {/* Action Log list */}
            <OverviewContext.Consumer>
                {
                    (ctx) => {
                        // Helper fns
                        const loadFn = () => MC_Backend.getInstance().loadOverview();
                        const renderFn = (idx: number, x: MC_Action_Log | null) => {
                            const key: string = (x != null) ? x.id : "null" + idx;
                            return <ActivityLogItem key={key} log={x}/>;
                        };
                        const sortFn = (a: MC_Action_Log, b: MC_Action_Log) => (b.timestamp.getTime() - a.timestamp.getTime());
                        // UI
                        const allLogs: MC_Action_Log[] | null = (ctx.overview != null) ? ctx.overview.logs : null;
                        const filteredLogs = (allLogs != null) ? allLogs.filter(props.logFilterFn) : [];
                        return <PaginatedSelectItemList<MC_Action_Log>
                            allowSearch={false}
                            pageSize={props.pageSize}
                            fillPageWithNullItems={false}
                            loadDataFn={loadFn}
                            dataArray={filteredLogs}
                            loading={ctx.loading}
                            errMsg={ctx.errMsg}
                            emptyMessage={"No logs found."}
                            sortFn={sortFn}
                            renderItem={renderFn}
                            hideLoadingBar={props.hideLoadingBar}
                        />
                    }
                }
            </OverviewContext.Consumer>
        </div>
    );
}

export default ViewActivityLog;