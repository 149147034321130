import "./Item.css";
import {Button, Paper} from "@mui/material";
import {Phone} from "@mui/icons-material";
import React from "react";
import {MC_Constants} from "../../MC_Constants";
import {MultiFactorInfo} from "@firebase/auth";

export interface MFAFactorItemProps {
    factor: MultiFactorInfo;
    selectFn: () => void;
}

function MFAFactorItem(props: MFAFactorItemProps) {
    const factorName: string = (props.factor.displayName != null) ? props.factor.displayName : "Unnamed Phone";
    const addedDate: Date = new Date(Date.parse(props.factor.enrollmentTime));
    const addedDateStr: string = MC_Constants.getReadableDateStr(addedDate, true);
    // UI
    return (
        <Paper className={"item-root item-row-root"} elevation={6}>

            {/* Icon column */}
            <div className={"item-icon-col"}>
                <Phone fontSize={"large"} color={"primary"}/>
            </div>

            {/* Name column */}
            <div className={"item-name-col"}>
                <span className={"item-title"}>{factorName}</span>
                <span className={"item-id"}>Added: {addedDateStr}</span>
            </div>

            {/* Action column */}
            <div className={"item-action-col"}>
                <Button variant="text" color={"primary"}
                        onClick={() => props.selectFn()}>
                    Select
                </Button>
            </div>

        </Paper>
    );
}

export default MFAFactorItem;