import "./Item.css";
import {Avatar, Button, Paper, Skeleton} from "@mui/material";
import {PhoneAndroid} from "@mui/icons-material";
import {Link} from "react-router-dom";
import React, {CSSProperties} from "react";
import {MC_Device} from "../../iot/MC_Device";

const linkStyle: CSSProperties = {
    color: "var(--MC_MAIN)",
    textDecorationColor: "var(--MC_MAIN)",
};
export interface DeviceItemProps {
    nameLink: boolean; // True if the name should be a link to the item
    device: MC_Device | null; // Null will display a skeleton item
    selectAction?: (d: MC_Device) => void; // Add into a dialog
    removeAction?: (d: MC_Device) => void; // Remove from an org
}
function DeviceItem(props: DeviceItemProps) {
    const device: MC_Device | null = props.device;
    return (
        <Paper className={"item-root item-row-root"} elevation={6}>

            {/* Icon column */}
            <div className={"item-icon-col"}>
                {/* Skeleton */}
                {(device == null) &&
                    <Skeleton animation={false} variant="circular" sx={{ fontSize: 'large' }}>
                        <Avatar/>
                    </Skeleton>
                }
                {/* Icon */}
                {(device != null) && <PhoneAndroid color={"primary"} fontSize={"large"}/>}
            </div>

            {/* Name column */}
            <div className={"item-name-col"}>
                <span className={"item-title"}>
                    {/* Skeleton */}
                    {(device == null) && <Skeleton className={"skel-text"} variant="text"/>}
                    {/* Content */}
                    {
                        (device != null) &&
                        <React.Fragment>
                            {(props.nameLink) &&
                                <Link to={"/core/devices/" + device.id} color={"secondary"} style={linkStyle}>
                                    <strong>{device.readableJASID}</strong>
                                </Link>
                            }
                            {(!props.nameLink) && <strong>{device.readableJASID}</strong>}
                        </React.Fragment>
                    }
                </span>
                <span className={"item-id"}>
                    {/* Skeleton */}
                    {(device == null) && <Skeleton className={"skel-text"} variant="text"/>}
                    {/* Content */}
                    {(device != null) && device.fullType}
                </span>
                <span className={"item-id"}>
                    {/* Skeleton */}
                    {(device == null) && <Skeleton className={"skel-text"} variant="text"/>}
                    {/* Content */}
                    {(device != null) && "Name: " + device.name}
                </span>
            </div>

            {/* Action column */}
            <div className={"item-action-col"}>
                {/* Show buttons if necessary */}
                {
                    (device != null && props.selectAction != null)
                    &&
                    <Button variant="text" color={"primary"}
                            onClick={() => props.selectAction!(device!)}>
                        Select
                    </Button>
                }
                {
                    (device != null && props.removeAction != null)
                    &&
                    <Button variant="text" color={"warning"}
                            onClick={() => props.removeAction!(device!)}>
                        Remove
                    </Button>
                }
            </div>

        </Paper>
    );
}

export default DeviceItem;
