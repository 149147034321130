import {
    Divider,
    Paper,
    TableContainer
} from "@mui/material";
import React from "react";
import {MC_Issue} from "../../iot/MC_Issue";
import IssueTile from "./IssueTile";
import {MC_User} from "../../iot/MC_User";

export interface IssuesTablePropsInterface {
    issues: MC_Issue[];
    tableCtl?: JSX.Element;
    users: MC_User[]; // For getting user names/emails
}
export function IssuesTable({issues, tableCtl, users}: IssuesTablePropsInterface) {

    // Helper fn
    const getUserFn: (uid: string) => MC_User | null = (uid: string) => {
        for (let i = 0; i < users.length; i++) {
            if (users[i].uid === uid) {
                return users[i];
            }
        }
        return null;
    };

    return <div className={"issues-table-div"}>
        <TableContainer component={Paper} elevation={6} className={"issues-table-container"}>

            {/* Optional table control header */}
            {(tableCtl != null) && tableCtl}

            <Divider sx={{width: "100%"}}/>

            {/* List of issues */}
            <div className={"issue-list-div"}>
                {issues.map((x) => <IssueTile key={x.id} issue={x} getUserFn={getUserFn}/>)}
            </div>

        </TableContainer>
    </div>;
}
