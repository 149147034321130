import CreateOpDialog from "./CreateOpDialog";
import {CreateDialogBaseProps} from "./CreateDialogBaseProps";
import {MC_Organization} from "../../../iot/MC_Organization";
import {useNavigate} from "react-router-dom";
import {MC_Create_Org_Interface} from "../../../iot/MC_Create_Interface";
import {MC_Backend} from "../../../MC_Backend";
import CreateOrganizationComponent from "./CreateOrganizationComponent";

export interface CreateOrganizationDialogProps extends CreateDialogBaseProps {}
function CreateOrganizationDialog(props: CreateOrganizationDialogProps) {
    const nav = useNavigate();
    // Config component
    const getConfigComponent = (
        existingConfig: MC_Create_Org_Interface | null,
        selectConfig: (config: MC_Create_Org_Interface) => void
    ) => {
        return <CreateOrganizationComponent existingConfig={existingConfig} selectConfig={selectConfig}/>;
    };
    // Helper fns
    const getConfirmDesc = (config: MC_Create_Org_Interface) => {
        return "You are about to create an organization named '" + config.name + "'.";
    };
    const performAction = async (config: MC_Create_Org_Interface): Promise<MC_Organization> => {
            return await MC_Backend.getInstance().createOrganization(config);
    };
    const getSuccessDesc = (o: MC_Organization) => {
        return "Successfully created organization '" + o.name + "'.";
    };
    const navToNewOrg = (newOrg: MC_Organization) => {
        nav("/core/organizations/" + newOrg.id);
    }
    return <CreateOpDialog<MC_Create_Org_Interface, MC_Organization>
        open={props.open} closeDialogFn={props.closeDialogFn}
        title={"Create Organization"}
        getConfigComponent={getConfigComponent}
        getConfirmDesc={getConfirmDesc}
        performActionFn={performAction}
        getSuccessDesc={getSuccessDesc}
        doneSuccessFn={navToNewOrg}
        doneFailureFn={() => {}}
    />;
}

export default CreateOrganizationDialog;