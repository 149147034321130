import {MC_Issue} from "../../../iot/MC_Issue";
import {ConfirmAsyncOpDialogBaseProps} from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialogBaseProps";
import ConfirmAsyncOpDialog from "../ConfirmAsyncOpDialog/ConfirmAsyncOpDialog";
import {MC_User} from "../../../iot/MC_User";
import {MC_Backend} from "../../../MC_Backend";

export interface RemoveAdminFromIssueDialogProps extends ConfirmAsyncOpDialogBaseProps<MC_Issue> {
    issue: MC_Issue;
    adminUser: MC_User | null;
}
function RemoveAdminFromIssueDialog(props: RemoveAdminFromIssueDialogProps) {
    // Handle malformed case
    if (props.issue == null || props.adminUser == null) {
        return null;
    }
    // Data
    const desc: string = "You are about to remove '" + props.adminUser.name + "' from this support request.";
    const successMsg: string = "'" + props.adminUser.name + "' has been removed from this support request.";
    // Helper fn
    const performAction = () => {
        return MC_Backend.getInstance().removeAdminFromIssue(props.issue, props.adminUser!.uid);
    };
    // UI
    return <ConfirmAsyncOpDialog<MC_Issue>
        open={props.open}
        closeDialogFn={props.closeDialogFn}
        title={"Remove Support Member?"}
        desc={desc}
        confirmText={"Remove"}
        confirmTextRed={true}
        successMsg={successMsg}
        performAction={performAction}
        doneSuccessFn={props.doneSuccessFn}
        doneFailureFn={props.doneFailureFn}
    />;
}

export default RemoveAdminFromIssueDialog;